import { gql } from '../../gql';

const succeedAtChallengeMutation = gql(/* GraphQL */ `
  mutation SucceedAtChallengeMutation($key: String!) {
    succeedAtChallenge(key: $key) {
      key
      isCompleted
    }
  }
`);

export default succeedAtChallengeMutation;
