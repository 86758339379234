import { gql } from '../../gql';

const batchDetailsPageQuery = gql(/* GraphQL */ `
  query BatchDetailsPageQuery($batchId: ID!) {
    batch(id: $batchId) {
      id
      title
      status
      statusText
      labelFileFormat
      labelSize
      canPrint
      canRefund
      canInstantRefundBatch
      canCreateScanForm
      scanFormUrl
      warehouse {
        id
        title
        shy
        originAddress {
          id
          ...AddressFragment
        }
        returnAddress {
          id
          ...AddressFragment
        }
      }
      numShipments
      shipDate
      dataSource
      spreadsheetMapping {
        id
        shipmentKey
        valueOrMappingKey
      }
      packageSummary {
        maxWeight
        minWeight
        maxInsured
        minInsured
        dimensionsSummary
        packageTypeSummary
        deliveryConfirmationSummary
        packagePreset {
          ...PackageFragment
        }
      }
      customsFormSummary {
        numCustomsForms
        signer
        title
        contentType
        itemSummary
        exporterTaxIdSummary
      }
      shipmentStatusSummary {
        ...ShipmentStatusSummaryFragment
      }
      rubberStamps {
        shipmentKey
        valueOrMappingKey
        userFriendlyShipmentKey
      }
      createdAt
      cleanupComplete

      notifyRecipientsDate
      emailNotificationTemplate {
        id
      }
      emailNotificationPossible
      emailNotificationSent
      priceSummary {
        title
        shipmentCount
        averagePrice
        totalPrice
        carrierKeys
        surcharge
        pirateShipService
        samePriceForAll
        usedReturnPrice
        isReturnLabel
        returnsUsed
        isRefunded
      }
      shipments {
        id
        order {
          id
          platform {
            title
            additionalTrackingEmailCopy
          }
        }
        carrierKey
        status
        printed
        canPrint
        canInstantRefundShipment
        additionalRefundNotice
      }
      trackingDataGrid {
        id
        rowActions {
          id
        }
      }
    }
    company {
      id
      accountBalance
    }
  }
`);

export default batchDetailsPageQuery;
