import styled from '@emotion/styled';
import { HTMLAttributes } from 'react';
import { LANDLUBBER_LAYOUT } from '../../styles/layout';
import { SPACING } from '../../styles/spacing';

const Styled = {
  AuthenticationContent: styled.div<{ maxWidth: string }>`
    margin-left: auto;
    margin-right: auto;
    padding-left: ${SPACING.md};
    padding-right: ${SPACING.md};
    max-width: ${({ maxWidth }) => maxWidth};
  `,
};

export default function LandlubberContent({
  full,
  ...props
}: HTMLAttributes<HTMLDivElement> & {
  full?: boolean;
}) {
  const maxWidth = full ? LANDLUBBER_LAYOUT.maxWidth : '400px';
  return <Styled.AuthenticationContent maxWidth={maxWidth} {...props} />;
}
