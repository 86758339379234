import { useLazyQuery } from '@apollo/client';
import { gql } from '../../gql';

export const shipmentPresetQuery = gql(/* GraphQL */ `
  query ShipmentPresetQuery($id: ID!) {
    company {
      id
      shipmentPresets(id: $id) {
        ...ShipmentPresetFragment
      }
      settings {
        defaultShipmentPresetId
      }
    }
  }
`);

export const useShipmentPresetLazyQuery = () => useLazyQuery(shipmentPresetQuery);
