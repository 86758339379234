import { FieldArray, getIn, useFormikContext } from 'formik';
import IntercomArticleLink from '../../../components/IntercomArticleLink';
import Checkbox from '../../../components/form/Checkbox';
import DropdownSelect from '../../../components/form/DropdownSelect';
import FormControl from '../../../components/form/FormControl';
import InputSubText from '../../../components/form/InputSubText';
import Label from '../../../components/form/Label';
import TextField from '../../../components/form/TextField';
import { Col, Row } from '../../../components/layout/Grid';
import { NamespacedSubform } from '../../../components/subforms/types';
import convertBbcode from '../../../utils/convertBbcode';
import CustomsLineItemSubform, { CustomsLineItemSubformValues } from './CustomsLineItemSubform';
import { CONTENT_TYPE_OPTIONS } from './customsFormSubformUtils';
import { INITIAL_CUSTOMS_LINE_ITEM_SUBFORM_VALUES } from './customsLineItemSubformUtils';

export type CustomsFormSubformProps<NS extends string> = NamespacedSubform<NS> & {
  required?: boolean;
  is2x7LabelSize?: boolean;
};

export type CustomsFormSubformValues = {
  customsFormEnabled: boolean; // preset might have customs forms enabled
  customsSigner: string;
  customsContentType: string;
  customsItems: Array<CustomsLineItemSubformValues>;
  exporterTaxId: string;
  recipientTaxId: string;
};

export default function CustomsFormSubform<NS extends string>({
  namespace,
  required,
  is2x7LabelSize,
}: CustomsFormSubformProps<NS>) {
  const { values } = useFormikContext();
  const { customsFormEnabled, customsItems } = getIn(values, namespace) as CustomsFormSubformValues;

  return (
    <Row>
      <Col md={12}>
        <FormControl
          name={`${namespace}.customsFormEnabled`}
          disabled={required}
          as={Checkbox}
          label="Customs Form"
          type="checkbox"
          secondaryText="Required for International, Military APO/FPO, and U.S. Territories"
          // Disable customs form if the label size is 2x7"
          {...(is2x7LabelSize && {
            disabled: true,
            faded: true,
            secondaryText: convertBbcode(
              'This feature is not available for 2x7" labels. You can change your label size in [link=/settings/general]Settings / General Settings[/link].',
            ),
          })}
        />
      </Col>
      <Col md={12} spaceBelow>
        {customsFormEnabled && (
          <Row>
            <Col md={12} spaceBelow>
              <FormControl
                name={`${namespace}.customsSigner`}
                as={TextField}
                label="Sign Customs Form As"
              />
            </Col>
            <Col md={12}>
              <Label>Package Content Type</Label>
            </Col>
            <Col md={12} spaceBelow>
              <FormControl
                name={`${namespace}.customsContentType`}
                as={DropdownSelect}
                options={CONTENT_TYPE_OPTIONS}
              />
            </Col>
            <Col md={12}>
              <FieldArray
                name={`${namespace}.customsItems`}
                render={(arrayHelpers) =>
                  customsItems.map((_item, index) => (
                    <CustomsLineItemSubform<`${NS}.${keyof CustomsFormSubformValues}.${number}`>
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      namespace={`${namespace}.customsItems.${index}`}
                      position={index + 1}
                      onAdd={() => {
                        arrayHelpers.push({
                          ...INITIAL_CUSTOMS_LINE_ITEM_SUBFORM_VALUES,
                        });
                      }}
                      onRemove={() => {
                        arrayHelpers.remove(index);
                      }}
                      removeEnabled={index > 0}
                    />
                  ))
                }
              />
            </Col>
            <Col md={12}>
              <Label>International Tax IDs</Label>
            </Col>
            <Col sm={12} md={6} spaceBelow>
              <FormControl
                as={TextField}
                name={`${namespace}.exporterTaxId`}
                label="Sender Tax ID"
              />
              <InputSubText>
                Optional: IOSS/HMRC/VOEC –{' '}
                <IntercomArticleLink href="https://support.pirateship.com/en/articles/5313602-what-is-an-exporter-international-tax-id">
                  Learn more
                </IntercomArticleLink>
              </InputSubText>
            </Col>
            <Col sm={12} md={6} spaceBelow>
              <FormControl
                as={TextField}
                name={`${namespace}.recipientTaxId`}
                label="Recipient Tax ID"
              />
              <InputSubText>
                Mexico/Brazil/EU (EORI) –{' '}
                <IntercomArticleLink href="https://support.pirateship.com/en/articles/4457315-what-is-a-recipient-tax-id-for-international-shipments-and-is-it-required">
                  Learn more
                </IntercomArticleLink>
              </InputSubText>
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
}
