import { useMutation } from '@apollo/client';
import { gql } from '../../gql';

export const setDefaultWarehouseMutation = gql(/* GraphQL */ `
  mutation SetDefaultWarehouseMutation($id: ID!) {
    setDefaultWarehouse(warehouseId: $id) {
      id
    }
  }
`);

export const useSetDefaultWarehouseMutation = () =>
  useMutation(setDefaultWarehouseMutation, {
    update: (cache, { data }) => {
      if (!data) {
        return;
      }

      cache.updateQuery(
        {
          query: gql(/* GraphQL */ `
            query UpdateSettingsAfterDefaultWarehouseQuery {
              company {
                id
                settings {
                  defaultWarehouseId
                }
              }
            }
          `),
        },
        (cacheResult) => {
          if (!cacheResult) {
            return undefined;
          }

          return {
            company: {
              ...cacheResult.company,
              settings: {
                ...cacheResult.company.settings,
                defaultWarehouseId: data.setDefaultWarehouse.id,
              },
            },
          };
        },
      );
    },
  });
