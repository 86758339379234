import { gql } from '../../gql';

const parsedAddressQuery = gql(/* GraphQL */ `
  query ParsedAddressQuery($address: String!) {
    parsedAddress(address: $address) {
      ...AddressFragment
    }
  }
`);

export default parsedAddressQuery;
