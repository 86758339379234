import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { forwardRef, InputHTMLAttributes, ReactNode, Ref, useEffect, useId } from 'react';
import useForwardedRef from '../../hooks/useForwardedRef';
import { BORDER_RADIUS, BORDER_WIDTH } from '../../styles/borders';
import { COLOR, GREYSCALE } from '../../styles/colors';
import { SPACING } from '../../styles/spacing';
import { TYPOGRAPHY } from '../../styles/typography';
import Label from './Label';

export type CheckboxSize = 'medium' | 'large' | 'xLarge';

type LabelSize = JSX.IntrinsicElements['div'] & { checkboxSize: CheckboxSize };

export type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  indeterminate?: boolean;
  checkboxSize: CheckboxSize;
};

export const StyledCheckboxWrapper = styled.div<{ faded?: boolean }>`
  display: inline-flex;
  gap: ${SPACING.sm};
  position: relative;

  /* Faded styling */
  ${({ faded }) =>
    faded &&
    css`
      opacity: 0.75;
    `}
`;

const Styled = {
  Label: styled(Label)<LabelSize>`
    cursor: pointer;
    padding: ${SPACING.none};

    /* Label sizes */
    ${({ checkboxSize }) => {
      switch (checkboxSize) {
        case 'medium':
          return css`
            padding-top: ${SPACING.xxs};
            font-size: ${TYPOGRAPHY.fontSize.sm};
            font-weight: ${TYPOGRAPHY.fontWeight.regular};
            line-height: ${TYPOGRAPHY.fontSize.md};
          `;
        case 'large':
          return css`
            padding-top: ${SPACING.xxs};
            font-size: ${TYPOGRAPHY.fontSize.md};
            font-weight: ${TYPOGRAPHY.fontWeight.medium};
            line-height: ${TYPOGRAPHY.fontSize.lg};
          `;
        case 'xLarge':
          return css`
            padding-top: ${SPACING.sm};
            font-size: ${TYPOGRAPHY.fontSize.lg};
            font-weight: ${TYPOGRAPHY.fontWeight.medium};
            line-height: ${TYPOGRAPHY.fontSize.xl};
          `;
        default:
          // If the exhaustiveCheck line shows an error, the switch is missing a case
          // eslint-disable-next-line no-case-declarations
          const exhaustiveCheck: string = checkboxSize;

          // This should never happen
          throw new Error(`Unhandled checkboxSize case ${exhaustiveCheck}`);
      }
    }}
  `,
  Input: styled.input<InputProps>`
    -webkit-appearance: none;
    appearance: none;

    background-color: ${GREYSCALE.white};
    border-style: solid;
    border-color: ${GREYSCALE.grey60};
    border-width: ${BORDER_WIDTH.xs};
    border-radius: ${BORDER_RADIUS.xxs};
    margin: ${SPACING.xxs};

    /* Sizes */
    ${({ checkboxSize }) => {
      switch (checkboxSize) {
        case 'medium':
          return css`
            width: calc(${SPACING.md} + 2 * ${BORDER_WIDTH.xs});
            height: calc(${SPACING.md} + 2 * ${BORDER_WIDTH.xs});
          `;
        case 'large':
          return css`
            width: calc(${SPACING.lg} + 2 * ${BORDER_WIDTH.xs});
            height: calc(${SPACING.lg} + 2 * ${BORDER_WIDTH.xs});
          `;
        case 'xLarge':
          return css`
            width: calc(${SPACING.xl} + 2 * ${BORDER_WIDTH.sm});
            height: calc(${SPACING.xl} + 2 * ${BORDER_WIDTH.sm});
            border-width: ${BORDER_WIDTH.sm};
            border-radius: ${BORDER_RADIUS.xs};
          `;
        default:
          // If the exhaustiveCheck line shows an error, the switch is missing a case
          // eslint-disable-next-line no-case-declarations
          const exhaustiveCheck: string = checkboxSize;

          // This should never happen
          throw new Error(`Unhandled checkboxSize case ${exhaustiveCheck}`);
      }
    }}

    &:hover {
      border-color: ${GREYSCALE.grey80};
    }
    &:active {
      border-color: ${GREYSCALE.grey50};
    }
    &:focus {
      border-color: ${COLOR.blue};
    }
    &:disabled {
      background-color: ${GREYSCALE.grey20};
      border-color: ${GREYSCALE.grey30};
      :checked {
        background-color: ${GREYSCALE.grey30};
        border-color: ${GREYSCALE.grey30};
      }
    }
    &:checked {
      background-color: ${COLOR.blue};
      border-color: ${COLOR.blue};
      ::before {
        scale: 1;
      }
    }
    &:focus-visible {
      outline: ${COLOR.blue} solid ${BORDER_WIDTH.sm};
      outline-offset: 2px;
    }

    /* checkmark positioning */
    display: grid;
    place-content: center;

    /* checkmark */
    ::before {
      content: '';
      width: inherit;
      height: inherit;
      ${({ indeterminate }) => css`
          background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 26 26' preserveAspectRatio='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolyline stroke-linecap='round' fill='none' stroke-width='4' ${
            !indeterminate
              ? "points='21 7 10 18 5 13' stroke='white' /%3E%3C/svg%3E\"); scale: 0;"
              : "points='7 13 19 13'  stroke='grey' /%3E%3C/svg%3E\"); scale: 1;"
          }
        `}
      background-size: contain;
      background-repeat: no-repeat;

      /* animation of checkmark scale */
      // transition: 200ms scale ease-in-out;
    }

    /* animation of background-color fade */
    // transition: 200ms background-color ease-in-out;
  `,
};

export type RawCheckboxProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> & {
  type: 'checkbox'; // this is needed for Formik to know what is being controlled
  indeterminate?: boolean;
  checkboxSize?: CheckboxSize;
  name?: string;
  checked?: boolean;
  disabled?: boolean;
};

/* A "raw" checkbox with no paraphernalia  */
/* Note: Use the standard Checkbox component unless you need to use this! */
export const RawCheckbox = forwardRef(function RawCheckbox(
  {
    indeterminate,
    onChange,
    checked = false,
    checkboxSize = 'large',
    disabled = false,
    name,
    type, // this is needed for Formik to know what is being controlled
    ...others
  }: RawCheckboxProps,
  ref: Ref<HTMLInputElement>,
) {
  const forwardedRef = useForwardedRef(ref);

  // for changes coming in from the outside //
  useEffect(() => {
    if (forwardedRef.current) {
      forwardedRef.current.indeterminate = !!indeterminate;
    }
  }, [forwardedRef, indeterminate]);

  return (
    <Styled.Input
      ref={forwardedRef}
      type={type} // this is needed for Formik to know what is being controlled
      checkboxSize={checkboxSize}
      className={checkboxSize}
      name={name} // this is needed for Formik to know what is being controlled
      onChange={(e) => {
        if (onChange) onChange(e);
        if (forwardedRef.current) {
          forwardedRef.current.checked = e.target.checked;
          forwardedRef.current.indeterminate = !!indeterminate;
        }
      }}
      checked={checked}
      disabled={disabled}
      indeterminate={indeterminate}
      {...others}
    />
  );
});

/* A checkbox with label, secondary text and tooltip. */
/* Note: this is the standard checkbox component and should be used in most cases */
export type CheckboxProps = RawCheckboxProps & {
  secondaryText?: ReactNode;
  label?: ReactNode;
  tooltip?: string;
  faded?: boolean;
};

function CheckboxComponent(
  {
    label,
    secondaryText,
    tooltip = undefined,
    faded,
    indeterminate,
    checkboxSize = 'large',
    ...others
  }: CheckboxProps,
  ref: React.Ref<HTMLInputElement>,
) {
  const checkboxId = useId();

  return (
    <StyledCheckboxWrapper faded={faded}>
      <RawCheckbox
        id={checkboxId}
        checkboxSize={checkboxSize}
        indeterminate={indeterminate}
        ref={ref}
        {...others}
      />
      <Styled.Label
        checkboxSize={checkboxSize}
        secondary={secondaryText}
        tooltip={tooltip}
        htmlFor={checkboxId}
      >
        {label}
      </Styled.Label>
    </StyledCheckboxWrapper>
  );
}

const Checkbox = forwardRef(CheckboxComponent);
export default Checkbox;
