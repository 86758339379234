import { css } from '@emotion/react';
import styled from '@emotion/styled';
import type { CellContext } from '@tanstack/react-table';
import { COLOR } from '../../../styles/colors';
import { formatCurrency } from '../../../utils/currency';

const Styled = {
  CurrencyCell: styled.div<{ positive?: boolean; colorPositive?: boolean }>`
    text-align: right;

    ${({ positive }) =>
      !positive &&
      css`
        color: ${COLOR.red};
      `}

    ${({ positive, colorPositive }) =>
      positive &&
      colorPositive &&
      css`
        color: ${COLOR.green};
      `}
  `,
};

export default CurrencyCell;

export type CurrencyCellSettings = {
  colorPositive?: boolean;
};

function CurrencyCell<TData, TValue>({ getValue, column }: CellContext<TData, TValue>) {
  const val = parseFloat(getValue() as string);
  const cellSettings = (column.columnDef.meta?.cellSettings ?? {}) as CurrencyCellSettings;
  return (
    <Styled.CurrencyCell positive={val >= 0} colorPositive={cellSettings.colorPositive}>
      {!Number.isNaN(val) ? formatCurrency(val) : ''}
    </Styled.CurrencyCell>
  );
}
