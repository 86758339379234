type PlaceId = string;

export type ErrorResponseStub = {
  error: {
    code: number;
    message: string;
    status: string;
    // Ignoring other fields we don't use
  };
};

export type PlacePrediction = {
  placeId: PlaceId;
  text: {
    text: string;
    matches: Array<{
      startOffset?: number; // missing if match starts at the beginning
      endOffset: number;
    }>;
  };
  // Ignoring other fields we don't use
};

export type PlacesAutocompleteResponseStub = {
  suggestions?: Array<{
    placePrediction: PlacePrediction;
  }>;
};

export type PlacesDetailsResponseStub = {
  id: PlaceId;
  addressComponents: Array<{
    longText: string;
    shortText: string;
    types: string[];
    languageCode: string;
  }>;
  // Ignoring other fields we don't use
};

const autocompleteCache: Record<string, PlacesAutocompleteResponseStub> = {};
async function getPlacePredictions(
  apiKey: string,
  input: string,
): Promise<PlacesAutocompleteResponseStub> {
  if (autocompleteCache[input]) {
    return autocompleteCache[input];
  }

  const response = await fetch('https://places.googleapis.com/v1/places:autocomplete', {
    method: 'POST',
    credentials: 'omit',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'X-Goog-Api-Key': apiKey,
      'X-Goog-FieldMask': 'suggestions.placePrediction.placeId,suggestions.placePrediction.text',
    },
    body: JSON.stringify({
      input,
      languageCode: 'en',
    }),
  });
  const data = (await response.json()) as PlacesAutocompleteResponseStub | ErrorResponseStub;

  if ('error' in data) {
    throw new Error(data.error.message, { cause: data.error.status });
  }

  autocompleteCache[input] = data;

  return data;
}

const detailsCache: Record<PlaceId, PlacesDetailsResponseStub> = {};
async function getPlaceDetails(
  apiKey: string,
  placeId: PlaceId,
): Promise<PlacesDetailsResponseStub> {
  if (detailsCache[placeId]) {
    return detailsCache[placeId];
  }

  const response = await fetch(
    `https://places.googleapis.com/v1/places/${placeId}?languageCode=en`,
    {
      method: 'GET',
      credentials: 'omit',
      mode: 'cors',
      headers: {
        'X-Goog-Api-Key': apiKey,
        'X-Goog-FieldMask': 'id,addressComponents',
      },
    },
  );
  const data = (await response.json()) as PlacesDetailsResponseStub | ErrorResponseStub;

  if ('error' in data) {
    throw new Error(data.error.message, { cause: data.error.status });
  }

  detailsCache[placeId] = data;

  return data;
}

export default class GooglePlacesClient {
  constructor(private apiKey: string) {}

  public getPlacePredictions(input: string) {
    return getPlacePredictions(this.apiKey, input);
  }

  public getPlaceDetails(placeId: PlaceId) {
    return getPlaceDetails(this.apiKey, placeId);
  }
}
