import styled from '@emotion/styled';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { PropsWithChildren } from 'react';
import { BORDER_RADIUS, BORDER_WIDTH } from '../../styles/borders';
import { COLOR, GREYSCALE } from '../../styles/colors';
import { SPACING } from '../../styles/spacing';
import Icon from '../Icon';
import { TextFieldWrapper } from './TextField';

type AffixVariant = 'primary' | 'secondary';
export type IconSize = 'small' | 'large';

const Styled = {
  InputGroup: styled.div`
    display: flex;
  `,
  Affix: styled.div<{
    type: 'prefix' | 'suffix';
    onClick: unknown;
    affixVariant?: AffixVariant;
    iconSize?: IconSize;
  }>`
    box-sizing: border-box;
    height: ${({ iconSize }) => (iconSize === 'small' ? '30px' : '50px')};
    padding: 10px ${SPACING.lg};
    border: ${({ iconSize }) => (iconSize === 'small' ? BORDER_WIDTH.xs : BORDER_WIDTH.sm)} solid;
    border-color: ${({ affixVariant }) =>
      affixVariant === 'primary' ? COLOR.blueButtonAccent : GREYSCALE.grey30};
    border-left-width: ${({ type }) => (type === 'prefix' ? BORDER_WIDTH.sm : BORDER_WIDTH.none)};
    border-right-width: ${({ type }) => (type === 'suffix' ? BORDER_WIDTH.sm : BORDER_WIDTH.none)};
    border-radius: ${BORDER_RADIUS.sm};
    border-top-right-radius: ${({ type }) =>
      type === 'suffix' ? BORDER_RADIUS.sm : BORDER_RADIUS.none};
    border-bottom-right-radius: ${({ type }) =>
      type === 'suffix' ? BORDER_RADIUS.sm : BORDER_RADIUS.none};
    border-top-left-radius: ${({ type }) =>
      type === 'prefix' ? BORDER_RADIUS.sm : BORDER_RADIUS.none};
    border-bottom-left-radius: ${({ type }) =>
      type === 'prefix' ? BORDER_RADIUS.sm : BORDER_RADIUS.none};
    background: ${({ affixVariant }) =>
      affixVariant === 'primary' ? COLOR.blue : GREYSCALE.grey20};
    color: ${({ affixVariant }) =>
      affixVariant === 'primary' ? GREYSCALE.white : GREYSCALE.grey50};
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: ${({ type }) => (type === 'suffix' ? 'pointer' : 'inherit')};

    &:hover {
      background: ${({ onClick, affixVariant }) => {
        if (!onClick) {
          return 'auto';
        }
        if (affixVariant === 'primary') {
          return COLOR.blueButtonAccent;
        }
        return GREYSCALE.grey30;
      }};
    }
  `,
  InputWrapper: styled('div')<InputProps>`
    flex: 1;
    ${TextFieldWrapper} {
      border-top-right-radius: ${(props) =>
        props.hasSuffix ? `${BORDER_RADIUS.none} !important` : `${BORDER_RADIUS.sm}`};
      border-bottom-right-radius: ${(props) =>
        props.hasSuffix ? `${BORDER_RADIUS.none} !important` : `${BORDER_RADIUS.sm}`};
      border-top-left-radius: ${(props) =>
        props.hasPrefix ? `${BORDER_RADIUS.none} !important` : `${BORDER_RADIUS.sm}`};
      border-bottom-left-radius: ${(props) =>
        props.hasPrefix ? `${BORDER_RADIUS.none} !important` : `${BORDER_RADIUS.sm}`};
    }
  `,
};

type InputProps = {
  hasSuffix: boolean;
  hasPrefix: boolean;
};

export type InputGroupType = PropsWithChildren<{
  prefixIcon?: IconProp;
  suffixIcon?: IconProp;
  prefixIconColor?: string;
  suffixIconColor?: string;
  suffixText?: string;
  onPrefixClick?(this: void): void;
  onSuffixClick?(this: void): void;
  affixVariant?: AffixVariant;
  iconSize?: IconSize;
  fixedIconWith?: boolean;
}>;

export default function InputGroup({
  prefixIcon,
  suffixIcon,
  prefixIconColor,
  suffixIconColor,
  suffixText,
  onPrefixClick,
  onSuffixClick,
  children,
  affixVariant,
  iconSize = 'large',
  fixedIconWith = false,
}: InputGroupType) {
  return (
    <Styled.InputGroup>
      {prefixIcon && (
        <Styled.Affix type="prefix" iconSize={iconSize} onClick={onPrefixClick}>
          <Icon
            icon={prefixIcon}
            color={prefixIconColor}
            size={iconSize === 'small' ? 'sm' : 'lg'}
            fixedWidth={fixedIconWith}
          />
        </Styled.Affix>
      )}

      <Styled.InputWrapper hasSuffix={!!suffixIcon || !!suffixText} hasPrefix={!!prefixIcon}>
        {children}
      </Styled.InputWrapper>

      {suffixIcon && (
        <Styled.Affix type="suffix" iconSize={iconSize} onClick={onSuffixClick}>
          <Icon
            icon={suffixIcon}
            color={suffixIconColor}
            size={iconSize === 'small' ? 'sm' : 'lg'}
          />
        </Styled.Affix>
      )}

      {suffixText && (
        <Styled.Affix type="suffix" onClick={onSuffixClick} affixVariant={affixVariant}>
          {suffixText}
        </Styled.Affix>
      )}
    </Styled.InputGroup>
  );
}
