import { LazyQueryHookOptions, QueryHookOptions, useLazyQuery, useQuery } from '@apollo/client';
import { gql } from '../../gql';
import { ZoneChartQuery, ZoneChartQueryVariables } from '../../gql/graphql';

export const zoneChartQuery = gql(/* GraphQL */ `
  query ZoneChartQuery($datetimeRange: DatetimeRangeInput!) {
    zoneChart(datetimeRange: $datetimeRange) {
      labels
      charts
      colors
    }
  }
`);

export const useZoneChartQuery = (
  options: QueryHookOptions<ZoneChartQuery, ZoneChartQueryVariables>,
) => useQuery(zoneChartQuery, options);

export const useZoneChartLazyQuery = (
  options: LazyQueryHookOptions<ZoneChartQuery, ZoneChartQueryVariables>,
) => useLazyQuery(zoneChartQuery, options);
