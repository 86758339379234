import styled from '@emotion/styled';
import { add } from 'date-fns';
import { getIn, useFormikContext } from 'formik';
import Checkbox from '../../components/form/Checkbox';
import FormControl from '../../components/form/FormControl';
import { StyledLabel } from '../../components/form/Label';
import Select from '../../components/form/Select';
import { Row } from '../../components/layout/Grid';
import {
  StyledPurchaseAlignRightCol,
  StyledPurchaseCol,
  StyledPurchaseRow,
  StyledPurchaseSubCol,
} from '../../components/layout/PurchaseGrid';
import { NamespacedSubform } from '../../components/subforms/types';
import { DATE_FORMAT, EMAIL_DELAY_TITLE, EMAIL_TEMPLATE_TITLE } from '../../constants';
import useDateInUserTimezone from '../../hooks/useDateInUserTimezone';

const Styled = {
  NotifyRecipientsWrapper: styled.div`
    display: flex;
    align-items: center;
    // overwrite for smaller input with 14px font size
    ${StyledLabel} {
      padding-top: 1px;
    }
  `,
};

export type NotifyRecipientsSettingRowValues = {
  enabled: boolean;
  emailTemplate?: string;
  defaultTrackingEmailsDelay?: string; // New Dropdown
};

export type NotifyRecipientsSettingRowProps<NS extends string> = NamespacedSubform<NS> & {
  defaultEmailTemplateId: string;
  mailTemplates: ReadonlyArray<{
    id: string;
    name: string;
  }>;
};

export default function NotifyRecipientsSettingRow<NS extends string>({
  namespace,
  defaultEmailTemplateId,
  mailTemplates,
}: NotifyRecipientsSettingRowProps<NS>) {
  const { getUserTimezoneCode, formatDate, createDate } = useDateInUserTimezone();

  function createFormattedDay(days: number) {
    // Create current user timestamp in GMT Time
    const now = createDate('now');

    // Add days to timestamp
    const addedDate = add(now, { days });

    // format the date. It already considers the user timezone, so we don't need to apply the offset
    const dayOfWeek = formatDate('UTC', addedDate, DATE_FORMAT.dayOfWeek);
    return `${dayOfWeek}, 9:00 AM`;
  }

  const { values } = useFormikContext();
  const { enabled } = getIn(values, namespace) as NotifyRecipientsSettingRowValues;

  function getEmailNotificationDelayOptions() {
    const emailNotificationDelayOptions = [
      {
        title: 'Immediate',
        value: '+1 second',
      },
      {
        title: '1 hour',
        value: '+1 hour',
      },
      {
        title: '2 hours',
        value: '+2 hour',
      },
      {
        title: '3 hours',
        value: '+3 hours',
      },
      {
        title: '6 hours',
        value: '+6 hours',
      },
      {
        title: '12 hours',
        value: '+12 hours',
      },
      {
        title: createFormattedDay(1),
        value: '+24 hours',
      },
      {
        title: createFormattedDay(2),
        value: '+2 days',
      },
      {
        title: createFormattedDay(3),
        value: '+3 days',
      },
      {
        title: createFormattedDay(4),
        value: '+4 days',
      },
      {
        title: createFormattedDay(5),
        value: '+5 days',
      },
      {
        title: createFormattedDay(6),
        value: '+6 days',
      },
      {
        title: createFormattedDay(7),
        value: '+7 days',
      },
    ];
    return emailNotificationDelayOptions;
  }

  const createMailTemplateOptions = () => [
    { title: EMAIL_TEMPLATE_TITLE, value: '', disabled: true },
    ...mailTemplates.map(({ name, id }) => ({
      title: `${name} ${id === defaultEmailTemplateId ? '(Default)' : ''}`,
      value: id,
    })),
  ];

  return (
    <StyledPurchaseRow align="center" data-testid="notify-recipients-setting-row">
      <StyledPurchaseCol xs={5}>
        <Styled.NotifyRecipientsWrapper>
          <FormControl
            name={`${namespace}.enabled`}
            as={Checkbox}
            label={`Email recipients (${getUserTimezoneCode()})?`}
            data-dd-action-name="Email Recipients (Checkbox)"
            type="checkbox"
          />
        </Styled.NotifyRecipientsWrapper>
      </StyledPurchaseCol>
      <StyledPurchaseAlignRightCol xs={7}>
        <Row align="center" justify="end">
          <StyledPurchaseSubCol sm={12} md={5}>
            {mailTemplates.length > 1 && (
              <FormControl
                name={`${namespace}.emailTemplate`}
                as={Select}
                disabled={!enabled}
                options={createMailTemplateOptions()}
                aria-label="Email Template"
                data-dd-action-name="Email Template (Dropdown)"
              />
            )}
          </StyledPurchaseSubCol>
          <StyledPurchaseSubCol sm={12} md="content">
            <FormControl
              name={`${namespace}.defaultTrackingEmailsDelay`}
              as={Select}
              options={[
                {
                  title: EMAIL_DELAY_TITLE,
                  value: '',
                  disabled: true,
                },
                ...getEmailNotificationDelayOptions(),
              ]}
              disabled={!enabled}
              aria-label="Email Delay"
              data-dd-action-name="Email Delay (Dropdown)"
            />
          </StyledPurchaseSubCol>
        </Row>
      </StyledPurchaseAlignRightCol>
    </StyledPurchaseRow>
  );
}
