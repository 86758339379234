import { Col, Row } from '../../components/layout/Grid';
import ConfirmationModal from '../../components/modals/ConfirmationModal';
import convertBbcode from '../../utils/convertBbcode';
import { SHIPMENT_REFUND_COPY } from '../constants/refundSuccessMessages';
import getShipmentRefundModalTitle from '../utils/getShipmentRefundModalTitle';

type ShipmentRefundModalProps = {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  confirmationButtonProgress: boolean;
  canInstantRefundShipment: boolean;
  isUpsShipment: boolean;
};

export default function ShipmentRefundModal({
  open,
  onCancel,
  onConfirm,
  confirmationButtonProgress,
  canInstantRefundShipment,
  isUpsShipment,
}: ShipmentRefundModalProps) {
  const getRefundContent = () => {
    if (canInstantRefundShipment) {
      return (
        <Col md={12} spaceBelow>
          {convertBbcode(SHIPMENT_REFUND_COPY.instant.description)}
        </Col>
      );
    }
    return isUpsShipment ? (
      <Col md={12} spaceBelow>
        {convertBbcode(SHIPMENT_REFUND_COPY.ups.description)}
      </Col>
    ) : (
      <Col md={12} spaceBelow>
        {convertBbcode(SHIPMENT_REFUND_COPY.usps.description)}
      </Col>
    );
  };

  return (
    <ConfirmationModal
      open={open}
      onCancel={onCancel}
      onConfirm={onConfirm}
      confirmationButtonLabel="Continue"
      confirmationButtonProgress={confirmationButtonProgress}
      title={getShipmentRefundModalTitle(canInstantRefundShipment, isUpsShipment)}
    >
      <Row>{getRefundContent()}</Row>
      <Row>
        <Col md={12}>Do you want to continue?</Col>
      </Row>
    </ConfirmationModal>
  );
}
