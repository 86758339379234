import { useCurrentUser } from '@ps/authentication';
import formatDateFnsTz from 'date-fns-tz/format';
import formatInTimeZone from 'date-fns-tz/formatInTimeZone';
import getTimezoneOffset from 'date-fns-tz/getTimezoneOffset';
import add from 'date-fns/add';
import enUs from 'date-fns/locale/en-US';
import sub from 'date-fns/sub';
import { useCallback, useMemo } from 'react';
import { LocalOrUTC } from '../constants';
import newDate, { DateConstructorInput } from '../utils/newDate';

type OneDateTypeFromTheOther = 'localFromUTC' | 'UTCFromLocal';

export type DateInUserTimezoneType = {
  createDate: (
    dateConstructorInput: DateConstructorInput,
    createOneDateTypeFromTheOther?: OneDateTypeFromTheOther,
  ) => Date;
  formatDate: (
    formatLocalOrUTCDate: LocalOrUTC,
    date: DateConstructorInput,
    format: string,
  ) => string;
  userTimezoneMinutesOffset: number; // gives a minute offset to UTC. e.g. UTC+2 is +120, PDT is -720
  getUserTimezoneCode: (date?: Date) => string; // gives a 3 letter representation of the user's timezone, e.g. PDT
  loading: boolean;
};

export default function useDateInUserTimezone(): DateInUserTimezoneType {
  const [currentUser] = useCurrentUser();

  const userTimezoneMinutesOffset = useMemo(
    () => getTimezoneOffset(currentUser?.timezone ?? 'UTC') / 1000 / 60,
    [currentUser?.timezone],
  ); // the getTimezoneOffset function gives milliseconds, so ÷ 60000 gives minutes

  /**
   * given a verbose timezone, return the 3-letter code version
   */
  const getUserTimezoneCode = useCallback(
    (date?: Date) =>
      formatDateFnsTz(date ?? new Date(), 'zzz', {
        timeZone: currentUser?.timezone,
        locale: enUs,
      }),
    [currentUser?.timezone],
  );

  // this is the date creation matrix:
  // this function creates a 'local' date, i.e. one with the timezone offset baked into the timestamp, or a UTC date.
  // this can be done on the basis of a UTC date or a local date. The browser will be ignored 100% here.
  // note: when wanting to display/send a date made with this method, be sure to use the formatDate() function below.
  // ╔═══════════════════╦═══════════════╦═════════════════╗
  // ║                   ║ from UTC date ║ from local date ║
  // ║ create UTC date   ║ Z             ║ sub offset      ║
  // ║ create local date ║ add offset    ║ Z               ║
  // ╚═══════════════════╩═══════════════╩═════════════════╝
  const createDate = useCallback(
    (
      dateConstructorInput: DateConstructorInput,
      createOneDateTypeFromTheOther?: OneDateTypeFromTheOther,
    ): Date => {
      // create a local date from UTC: bake in the timezone offset by adding it to the timestamp
      if (createOneDateTypeFromTheOther === 'localFromUTC') {
        return add(newDate(dateConstructorInput), {
          minutes: userTimezoneMinutesOffset,
        });
      }
      // create a UTC date from a local string: extract the timezone offset by subtracting it from the timestamp
      if (createOneDateTypeFromTheOther === 'UTCFromLocal') {
        return sub(newDate(dateConstructorInput), {
          minutes: userTimezoneMinutesOffset,
        });
      }
      // create a UTC date with a UTC input, or create a local date with a local input: in either case, we don't need to offset anything
      return newDate(dateConstructorInput);
    },
    [userTimezoneMinutesOffset],
  );

  // format a date IN THE USER TIMEZONE, i.e. "as local". We have no current use to show dates in UTC in our app.
  const formatDate = useCallback(
    (formatLocalOrUTCDate: LocalOrUTC, dateInput: DateConstructorInput, format: string): string => {
      if (!dateInput) return '';
      const date = typeof dateInput === 'string' ? newDate(dateInput) : dateInput;
      if (Number.isNaN(date.getTime())) return '';

      // local->local: local dates have user timezone baked in. Therefore, showing the timestamp "purely", i.e. without broswer meddling === showing time in user's timezone.
      if (formatLocalOrUTCDate === 'local') {
        const withNoTimezoneFormatting = 'UTC'; // we "abuse" the fact that formatting in UTC give us a local date in local time, as we have already applied the user timezone offset when we created the local date.
        return formatInTimeZone(date, withNoTimezoneFormatting, format);
      }
      // UTC->local: we format with the timezone adjustment. note: this is just for show! no underlying value is changed here.
      const withTimezone = format.match(/zzz$/);
      const formatWithoutTimezoneZzzs = withTimezone ? format.slice(0, -3) : format;
      const formatted = formatInTimeZone(
        date,
        currentUser?.timezone ?? 'UTC',
        formatWithoutTimezoneZzzs,
      );
      return withTimezone ? `${formatted} ${getUserTimezoneCode(date)}` : formatted;
    },
    [currentUser?.timezone, getUserTimezoneCode],
  );

  return {
    createDate,
    formatDate,
    userTimezoneMinutesOffset,
    getUserTimezoneCode,
    loading: currentUser === null,
  };
}
