import add from 'date-fns/add';
import sub from 'date-fns/sub';
import { useCallback } from 'react';
import { LocalOrUTC } from '../constants';
import useDateInUserTimezone from './useDateInUserTimezone';

type OffsetDatesType = {
  applyOffset: (d: Date) => Date;
  revertOffset: (d: Date) => Date;
};

// ONLY USE THESE FUNCTIONS TO PASS ALTERED DATES TO UI COMPONENTS //
export default function useOffsetDates(localOrUTC: LocalOrUTC): OffsetDatesType {
  const { userTimezoneMinutesOffset } = useDateInUserTimezone();

  const userTimezoneMinutesOffsetRespectingDst = useCallback(
    (d: Date) => {
      // proudly found elsewhere: https://stackoverflow.com/questions/11887934/how-to-check-if-dst-daylight-saving-time-is-in-effect-and-if-so-the-offset/30280636#30280636
      function isDST(date: Date) {
        const jan = new Date(date.getFullYear(), 0, 1).getTimezoneOffset();
        const jul = new Date(date.getFullYear(), 6, 1).getTimezoneOffset();
        return Math.max(jan, jul) !== date.getTimezoneOffset();
      }

      if (isDST(d)) {
        return userTimezoneMinutesOffset + 60;
      }
      return userTimezoneMinutesOffset;
    },
    [userTimezoneMinutesOffset],
  );

  // JS is weird: offset is negative where it should be positive, so multiply by -1
  // const browserTimezoneMinutesOffset = useMemo(() => new Date().getTimezoneOffset() * -1, []);
  const browserTimezoneMinutesOffset = useCallback(
    (date: Date) => new Date(date).getTimezoneOffset() * -1,
    [],
  );

  // given a UTC date, apply an offset (user timezone - browser timezone) such that it appears as local time to the user
  // OR: given a local date, apply an offset ( - browser timezone) such that it appears local to the user
  const applyOffset = useCallback(
    (d: Date): Date =>
      add(sub(d, { minutes: browserTimezoneMinutesOffset(d) }), {
        minutes: localOrUTC === 'UTC' ? userTimezoneMinutesOffsetRespectingDst(d) : 0,
      }),
    [browserTimezoneMinutesOffset, userTimezoneMinutesOffsetRespectingDst, localOrUTC],
  );

  // this reverts the operation applied above.
  const revertOffset = useCallback(
    (d: Date): Date =>
      sub(add(d, { minutes: browserTimezoneMinutesOffset(d) }), {
        minutes: localOrUTC === 'UTC' ? userTimezoneMinutesOffsetRespectingDst(d) : 0,
      }),
    [browserTimezoneMinutesOffset, userTimezoneMinutesOffsetRespectingDst, localOrUTC],
  );

  return { applyOffset, revertOffset };
}
