import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import batchAndShipmentIdsFromPathname from '../../utils/batchAndShipmentIdsFromPathname';
import ShipmentWorkflow from './ship/ShipmentWorkflow';

export type ShipRoutesProps = {
  forwardedMailClassKey?: string;
};

export default function ShipRoutes({ forwardedMailClassKey }: ShipRoutesProps) {
  const location = useLocation();
  const { batchId, shipmentId } = batchAndShipmentIdsFromPathname(location.pathname);

  if (!batchId) {
    return <Navigate to="/ship" />;
  }

  return (
    <Routes>
      <Route
        path=":batchId"
        element={
          <ShipmentWorkflow batchId={batchId} forwardedMailClassKey={forwardedMailClassKey} />
        }
      />
      <Route
        path=":batchId/shipment/:shipmentId"
        element={
          <ShipmentWorkflow
            batchId={batchId}
            shipmentId={shipmentId}
            forwardedMailClassKey={forwardedMailClassKey}
          />
        }
      />
    </Routes>
  );
}
