import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LinkedIconTooltip from '../../components/LinkedIconTooltip';
import ServiceSummaryTable from '../../components/serviceSummaryTable/StyledServiceSummaryTable';
import { DATE_FORMAT } from '../../constants';
import useDateInUserTimezone from '../../hooks/useDateInUserTimezone';
import { TYPOGRAPHY } from '../../styles/typography';

type ShipDateRowProps = { shipDate: string; isBatch: boolean };

const Styled = {
  TooltipWrapper: styled.span`
    font-weight: ${TYPOGRAPHY.fontWeight.regular};
    text-align: center;
  `,
};

export default function ShipDateRow({ shipDate, isBatch }: ShipDateRowProps) {
  const { formatDate } = useDateInUserTimezone();
  return (
    <ServiceSummaryTable.Row>
      <ServiceSummaryTable.Col>
        <ServiceSummaryTable.Header>
          Ship Date
          <Styled.TooltipWrapper>
            <LinkedIconTooltip href="https://support.pirateship.com/en/articles/1068463-my-label-has-the-wrong-ship-date-do-i-need-to-change-it">
              This is the date the carrier is expecting your {isBatch ? 'packages' : 'package'}. If
              you’re shipping after this date, click the <FontAwesomeIcon icon="question-circle" />{' '}
              to understand your options 👍
            </LinkedIconTooltip>
          </Styled.TooltipWrapper>
        </ServiceSummaryTable.Header>
      </ServiceSummaryTable.Col>
      <ServiceSummaryTable.Col alignRight>
        {formatDate('local', shipDate, `${DATE_FORMAT.dayOfWeek}, ${DATE_FORMAT.usDate}`)}
      </ServiceSummaryTable.Col>
    </ServiceSummaryTable.Row>
  );
}
