import { SingleShipmentFormValues } from '@ps/shipmentLead';
import { LocalFiltersStorageType } from '../components/dataGrid/DataGrid';
import {
  CollapsibleCollapsedStorageKeyTemplate,
  DataGridFilterStorageKeyTemplate,
  STORAGE_KEYS,
} from '../constants';
import { PickupRequestPackageLocation } from '../gql/graphql';
import { StaticRange } from '../hooks/useStaticRangesInUserTimezone';

export type StorageSharedOptions = {
  session?: boolean;
};
export type StorageRetrieveOptions = StorageSharedOptions;
export type StoragePersistOptions = StorageSharedOptions & {
  lifetime?: number;
};
export type StorageRemoveOptions = StorageSharedOptions;
export type StorageClearOptions = StorageSharedOptions;

type StorageItem<V> = {
  created: number;
  updated: number;
  lifetime?: number;
  value: V;
};

export type InMemoryStorageValue = {
  [STORAGE_KEYS.loggedInStorageKey]: string;
  [STORAGE_KEYS.clientTrackedStorageKey]: boolean;
  [STORAGE_KEYS.sidebarCollapsedStorageKey]: boolean;
  [STORAGE_KEYS.showShipmentExtraServiceStorageKey]: boolean;
  [STORAGE_KEYS.showRubberStampContainerStorageKey]: boolean;
  [STORAGE_KEYS.showShipmentInfoStorageKey]: boolean;
  [STORAGE_KEYS.gridFilterStateStorageKey]: Record<string, string>;
  [STORAGE_KEYS.adminBarVisibleStorageKey]: boolean;
  [STORAGE_KEYS.reportOverviewDatetimeRangeFilterStorageKey]: StaticRange;
  [STORAGE_KEYS.showPaymentSettingsStorageKey]: boolean;
  [STORAGE_KEYS.packageLocationStorageKey]: PickupRequestPackageLocation;
  [STORAGE_KEYS.specialInstructionsStorageKey]: string;
  [STORAGE_KEYS.preselectedSummaryIdsStorageKey]: Record<string, string>;
  [STORAGE_KEYS.singleShipmentFormValuesStorageKey]: DeepPartial<SingleShipmentFormValues>;
  [x: DataGridFilterStorageKeyTemplate]: LocalFiltersStorageType;
  [y: CollapsibleCollapsedStorageKeyTemplate]: boolean;
};

export type InMemoryStorageKey = keyof InMemoryStorageValue;

// map each of the keys above to a storage item with that value type
type InMemoryStorage = {
  [Property in InMemoryStorageKey]: StorageItem<InMemoryStorageValue[Property]>;
};

let inMemoryStorage: Partial<InMemoryStorage> = {};

function getStorageInterface({ session }: StorageSharedOptions) {
  return session ? sessionStorage : localStorage;
}

function retrieveItem<K extends InMemoryStorageKey>(
  key: K,
  options: StorageRetrieveOptions,
): StorageItem<InMemoryStorageValue[K]> | null {
  if (Object.prototype.hasOwnProperty.call(inMemoryStorage, key)) {
    return inMemoryStorage[key] ?? null;
  }

  try {
    const storage = getStorageInterface(options);
    const serializedItem = storage.getItem(key);
    // we only give ourselves permission to make the type assertion here because we have type checked above.
    // the JSON.parse function will always return type unknown, so we have to do this here
    return serializedItem
      ? (JSON.parse(serializedItem) as StorageItem<InMemoryStorageValue[K]>)
      : null;
  } catch (e) {
    return null;
  }
}

function persistItem<K extends InMemoryStorageKey>(
  key: K,
  item: StorageItem<InMemoryStorageValue[K]>,
  options: StoragePersistOptions,
): void {
  Object.assign(inMemoryStorage, { [key]: item });

  try {
    const storage = getStorageInterface(options);
    const serializedItem = JSON.stringify(item);
    storage.setItem(key, serializedItem);
  } catch (e) {
    // noop
  }
}

export function setItem<K extends InMemoryStorageKey>(
  key: K,
  value: InMemoryStorageValue[K],
  options: StoragePersistOptions = {},
): void {
  const now = Date.now();
  const item = {
    created: retrieveItem(key, options)?.created ?? now,
    updated: now,
    lifetime: options.lifetime,
    value,
  };

  persistItem(key, item, options);
}

export function getItem<K extends InMemoryStorageKey>(
  key: K,
  options: StorageRetrieveOptions = {},
): InMemoryStorageValue[K] | null {
  const item = retrieveItem(key, options);

  if (!item) {
    return null;
  }

  if (item.lifetime && item.updated + item.lifetime < Date.now()) {
    removeItem(key, options);
    return null;
  }

  return item.value;
}

export function removeItem<K extends InMemoryStorageKey>(
  key: K,
  options: StorageRemoveOptions = {},
): void {
  delete inMemoryStorage[key];

  const storage = getStorageInterface(options);
  storage.removeItem(key);
}

export function clear(options: StorageRemoveOptions = {}): void {
  inMemoryStorage = {};

  const storage = getStorageInterface(options);
  storage.clear();
}

const storageService = {
  setItem,
  getItem,
  removeItem,
  clear,
};

export default storageService;
