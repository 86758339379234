import { useEffect, useState } from 'react';

export default function useWindowSize() {
  const [size, setSize] = useState<[number, number]>([window.innerWidth, window.innerHeight]);

  useEffect(() => {
    const listener = () => setSize([window.innerWidth, window.innerHeight]);
    window.addEventListener('resize', listener);
    return () => window.removeEventListener('resize', listener);
  }, []);

  return size;
}
