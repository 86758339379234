import { useEffect, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

export default function useElementResizeObserver(element: HTMLElement | null) {
  const [size, setSize] = useState<[number, number]>([0, 0]);

  useEffect(() => {
    if (element) {
      const resizeObserver = new ResizeObserver((e: ResizeObserverEntry[]) => {
        requestAnimationFrame(() => {
          setSize([e[0].contentRect.width, e[0].contentRect.height]);
        });
      });
      resizeObserver.observe(element);
      return () => resizeObserver.unobserve(element);
    }
    return () => null;
  }, [element]);

  return size;
}
