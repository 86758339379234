import FormControl from '../../../components/form/FormControl';
import TextField from '../../../components/form/TextField';

type SignupPasswordProps = {
  onPaste: () => void;
};

export default function SignupPassword({ onPaste }: SignupPasswordProps) {
  return (
    <FormControl
      name="password"
      type="password"
      as={TextField}
      label="Create a password (at least 12 characters)"
      onPaste={onPaste}
    />
  );
}
