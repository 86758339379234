import { getIn, useFormikContext } from 'formik';
import { Suspense } from 'react';
import { Col, Row } from '../../../components/layout/Grid';
import { NEW_WAREHOUSE_VALUE } from '../../../constants';
import useWarehouseOptions from '../../../hooks/useWarehouseOptions';
import { Address } from '../../../utils/formatAddress';
import ExpandableDropdownSelect from '../../form/ExpandableDropdownSelect';
import FormControl from '../../form/FormControl';
import Label from '../../form/Label';
import ContentLoading from '../../loading/ContentLoading';
import WarehouseSubform, { type WarehouseSubformValues } from '../WarehouseSubform';
import { NamespacedSubform } from '../types';

export type ShipFromAddressSubformProps<NS extends string> = NamespacedSubform<NS> & {
  countries: ReadonlyArray<{ id: string; countryCode: string; name: string }>;
  warehouses: ReadonlyArray<{
    useOriginAsReturnAddress: boolean;
    originAddress: Address;
    returnAddress: Address;
    id: string;
    title: string;
  }>;
  loading?: boolean;
};

export type ShipFromAddressSubformValues = {
  warehouseId: string;
  warehouse: WarehouseSubformValues;
};

type ValidNamespaces<NS extends string> = `${NS}.${keyof ShipFromAddressSubformValues}`;
type FormValues = Record<string, unknown> & { [namespace: string]: ShipFromAddressSubformValues };

export default function ShipFromAddressSubform<NS extends string>({
  namespace,
  countries,
  warehouses,
  loading,
}: ShipFromAddressSubformProps<NS>) {
  const { values } = useFormikContext<FormValues>();
  const { warehouseId } = getIn(values, namespace) as ShipFromAddressSubformValues;

  const warehouseOptions = useWarehouseOptions({
    data: { countries, company: { warehouses } },
    lastOptionAllowsCreation: true,
  });

  return (
    <Row spaceBelow>
      <Col md={12}>
        <Label>Ship From</Label>
      </Col>
      <Col md={12}>
        <FormControl
          as={ExpandableDropdownSelect}
          name={`${namespace}.warehouseId`}
          options={warehouseOptions}
          expandedContent={
            warehouseId === NEW_WAREHOUSE_VALUE ? (
              <Suspense
                fallback={
                  <>
                    <ContentLoading />
                    <br />
                  </>
                }
              >
                <WarehouseSubform<ValidNamespaces<NS>> namespace={`${namespace}.warehouse`} />
              </Suspense>
            ) : undefined
          }
          loading={loading || warehouseId === ''}
          loadingHeight={64}
        />
      </Col>
    </Row>
  );
}
