import { gql } from '../../gql';

const createScanFormByBatchMutation = gql(/* GraphQL */ `
  mutation CreateScanFormByBatchMutation($batchId: ID!) {
    createScanFormByBatch(batchId: $batchId) {
      batchId
      canCreateScanForm
      scanFormUrl
    }
  }
`);

export default createScanFormByBatchMutation;
