import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { HeaderContext } from '@tanstack/react-table';
import { useRef } from 'react';
import { BORDER_RADIUS, BORDER_WIDTH } from '../../../styles/borders';
import { COLOR, GREYSCALE } from '../../../styles/colors';
import { SPACING } from '../../../styles/spacing';
import Icon from '../../Icon';
import isColumnTypeNumeric from '../utils/isColumnTypeNumeric';

type StyledTableHeaderCellProps = {
  isDragging?: boolean;
};

const Styled = {
  TableHeaderCell: styled.th<StyledTableHeaderCellProps>`
    position: relative;
    margin: 0;
    padding: 0;
    color: ${GREYSCALE.grey60};
    background: linear-gradient(to bottom, ${GREYSCALE.white} 24%, ${GREYSCALE.grey20} 100%);
    text-align: left;
    min-height: 46px;
    line-height: 18px;
    border-width: ${BORDER_WIDTH.none} ${BORDER_WIDTH.none} ${BORDER_WIDTH.sm} ${BORDER_WIDTH.sm};
    border-style: solid;
    border-color: ${GREYSCALE.grey30};
    box-shadow: ${BORDER_WIDTH.sm} 0 0 ${GREYSCALE.grey30};
    font-weight: bold;

    :first-of-type {
      border-left-width: ${BORDER_WIDTH.none};
      border-top-left-radius: ${BORDER_RADIUS.sm};
    }

    :last-of-type {
      border-top-right-radius: ${BORDER_RADIUS.sm};
    }

    ${({ isDragging }) =>
      isDragging &&
      css`
        box-shadow: ${BORDER_WIDTH.sm} 0 0 ${GREYSCALE.grey30},
          0 -${BORDER_WIDTH.sm} 0 ${GREYSCALE.grey30},
          ${BORDER_WIDTH.sm} -${BORDER_WIDTH.sm} 0 ${GREYSCALE.grey30};
      `}
  `,
  ColumnMenuHeader: styled.div<{ isSelected: boolean; isSorted?: boolean }>`
    position: relative;
    padding: 1em 2em 1em 1em;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer;

    ${({ isSelected }) =>
      isSelected &&
      css`
        background: ${GREYSCALE.grey30};
      `}

    ${({ isSorted }) =>
      isSorted &&
      css`
        padding: 1em 3em 1em 1em;
      `}
  `,
  IconContainer: styled.div`
    position: absolute;
    top: 1em;
    right: 0.8em;
  `,
  SortIcon: styled(Icon)`
    margin-right: ${SPACING.xs};
  `,
};

type ColumnHeaderProps<TData, TValue> = {
  headerContext: HeaderContext<TData, TValue>;
  isSelected: boolean;
  onClick: (headerId: string) => void;
};

export default function ColumnHeader<TData, TValue>({
  headerContext,
  isSelected,
  onClick,
}: ColumnHeaderProps<TData, TValue>) {
  const headerRef = useRef<HTMLDivElement>(null);
  const { header, column } = headerContext;
  const isFiltered = column.getIsFiltered();
  const isSorted = !!column.getIsSorted();
  const sortIconAsc = isColumnTypeNumeric(column.columnDef.meta?.dataType || 'default')
    ? 'sort-numeric-up'
    : 'sort-alpha-up';
  const sortIconDesc = isColumnTypeNumeric(column.columnDef.meta?.dataType || 'default')
    ? 'sort-numeric-down-alt'
    : 'sort-alpha-down-alt';
  const sortIcon = column.getIsSorted() === 'desc' ? sortIconDesc : sortIconAsc;

  return (
    <Styled.ColumnMenuHeader
      ref={headerRef}
      onClick={() => onClick(header.id)}
      isSelected={isSelected}
      isSorted={isSorted}
    >
      {`${column.columnDef.header as string}`}
      <Styled.IconContainer>
        {isSorted && <Styled.SortIcon icon={sortIcon} color={isSorted ? COLOR.blue : 'auto'} />}
        {column.getCanFilter() && (
          <Icon icon="filter" color={isFiltered ? COLOR.blue : '#c8c8c8'} />
        )}
        <Icon icon="caret-down" visibility={isSelected ? 'hidden' : 'auto'} />
      </Styled.IconContainer>
    </Styled.ColumnMenuHeader>
  );
}
