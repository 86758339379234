import { useCurrentUser } from '@ps/authentication';
import { useMemo } from 'react';
import loggingService, { Logger } from '../services/logging';

export default function useLogger(): Logger {
  const [currentUser] = useCurrentUser();
  return useUserUnawareLogger(currentUser?.company.debuggingEnabled);
}

export function useUserUnawareLogger(enableDebugging = false): Logger {
  const logger = useMemo(() => loggingService.getLogger(enableDebugging), [enableDebugging]);

  return logger;
}
