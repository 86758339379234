import { useMemo } from 'react';
import Alert from '../../components/Alert';
import DropdownSelect from '../../components/form/DropdownSelect';
import { Col, Row } from '../../components/layout/Grid';
import { FileFormat, PageLayout } from '../../gql/graphql';
import {
  LabelSelectOption,
  labelOptionsPdf2x7,
  labelOptionsPdf4x6,
} from '../constants/printLabelsConstants';

type PageLayoutSelectProps = {
  pageLayout: PageLayout;
  fileFormat: FileFormat;
  onPageLayoutChange: (layout: PageLayout) => void;
};

export default function PageLayoutSelect({
  pageLayout,
  fileFormat,
  onPageLayoutChange,
}: PageLayoutSelectProps) {
  const showDropdown = fileFormat === 'PDF';

  const options: LabelSelectOption[] = useMemo(() => {
    if (pageLayout === 'LAYOUT_2x7') {
      return labelOptionsPdf2x7;
    }
    return labelOptionsPdf4x6;
  }, [pageLayout]);

  return (
    <Row>
      {showDropdown && (
        <Col spaceBelow>
          <DropdownSelect
            data-testid="page-layout-select"
            name="page-layout-select"
            value={pageLayout}
            options={options}
            onChange={(layout) => {
              onPageLayoutChange(layout as PageLayout);
            }}
          />
        </Col>
      )}
      {!showDropdown && (
        <Col md={12}>
          <Alert data-testid="page-layout-info-messages" variant="info">
            {`Sorry, print preview isn't available for ZPL. Click the Download button below.`}
          </Alert>
        </Col>
      )}
    </Row>
  );
}
