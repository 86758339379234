import type { Row as TableRow } from '@tanstack/react-table';
import { useRef, useState } from 'react';
import ConnectedDataGrid from '../../dataGrid/ConnectedDataGrid';
import { DataGridImperativeHandleRef } from '../../dataGrid/DataGrid';
import ProgressButton from '../../form/ProgressButton';
import { Col, PageContainer, Row } from '../../layout/Grid';
import PageHeader from '../../layout/PageHeader';

type OriginalRow = {
  id: string;
  payment_status: string;
};

function PaymentReceiptsPage() {
  const [isExporting, setIsExporting] = useState(false);
  const imperativeHandleRef = useRef<DataGridImperativeHandleRef>(null);

  const makeLinkToReceipt = (row: TableRow<OriginalRow>): string => {
    const receipt = row.original;
    if (row.original.payment_status === 'Pending Payment') return '';
    return `/reports/receipt/${receipt.id}`;
  };

  return (
    <PageContainer>
      <PageHeader title="Payment Receipts" />
      <Row>
        <Col md={12}>
          <ConnectedDataGrid
            imperativeHandleRef={imperativeHandleRef}
            queryName="receiptsReport"
            onRowClick={makeLinkToReceipt}
            rightSideSlot={
              <Col xs={12} md={3} spaceBelow>
                <ProgressButton
                  progress={isExporting}
                  disabled={isExporting}
                  fullWidth
                  size="medium"
                  variant="info"
                  onClick={async () => {
                    setIsExporting(true);
                    await imperativeHandleRef.current?.exportData();
                    setIsExporting(false);
                  }}
                >
                  Export
                </ProgressButton>
              </Col>
            }
          />
        </Col>
      </Row>
    </PageContainer>
  );
}

export default PaymentReceiptsPage;
