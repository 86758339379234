import * as yup from 'yup';
import {
  getInitialPackageTypeKey,
  type PackageType,
} from '../../../components/subforms/PackageSubform';
import { NEW_SHIPMENT_PRESET_VALUE } from '../../../constants';
import {
  type DeliveryConfirmation,
  type ReturnLabel,
  type ShipmentBoundaries,
} from '../../../gql/graphql';
import { toWeightObject } from '../../../utils/formatWeight';
import { INITIAL_CUSTOMS_FORM_SUBFORM_VALUES } from '../CustomsFormSubform/customsFormSubformUtils';
import { INITIAL_EXTRA_SERVICE_SUBFORM_VALUES } from '../ExtraServicesSubform';
import {
  initialPackageDetailsSubformValues,
  packageDetailsValidationSchema,
  type PackageDetailsSubformValues,
} from '../PackageDetailsSubform';
import { type PackagePresetSubformValues } from './PackagePresetSubform';

export type ShipmentPreset = {
  readonly id: string;
  readonly shy?: boolean;
  readonly title: string;
  readonly packageTypeKey: string;
  readonly dimensionX: number; // BE sends Number, which we convert later to string to allow fractions
  readonly dimensionY: number;
  readonly dimensionZ: number;
  readonly weight: number;
  readonly deliveryConfirmationFlag: boolean;
  readonly deliveryConfirmation: DeliveryConfirmation;
  readonly returnLabelFlag: boolean;
  readonly returnLabel: ReturnLabel;
  readonly insuredValueFlag: boolean;
  readonly insuredValue: number;
  readonly qualifiesAsMediaMail: boolean;
  readonly irregularPackage: boolean;
  readonly hazardousMaterialsEnabled: boolean;
  readonly customsFormEnabled: boolean;
  readonly customsSigner: string;
  readonly customsContentType: string;
  readonly customsItems: readonly {
    readonly title: string;
    readonly quantity: number;
    readonly itemValue: number;
    readonly weight: number;
    readonly hsTariffNumber: string;
    readonly countryCodeOfOrigin: string;
  }[];
  readonly exporterTaxId: string;
  readonly recipientTaxId: string;
};

export function getDefaultPreset(
  defaultShipmentPresetId: string,
  shipmentPresets: readonly ShipmentPreset[],
  packageTypes: readonly PackageType[],
) {
  const defaultShipmentPreset = shipmentPresets.find(({ id }) => id === defaultShipmentPresetId);

  if (!defaultShipmentPreset) {
    return undefined;
  }

  const packageTypeKey = getInitialPackageTypeKey(
    packageTypes,
    defaultShipmentPreset.packageTypeKey,
  );

  return { ...defaultShipmentPreset, packageTypeKey };
}

export function mapPresetToFormValues(
  shipmentPreset: ShipmentPreset,
  isCustomsFormRequired: boolean,
  is2x7LabelSize: boolean,
): PackageDetailsSubformValues {
  //  There is no preset for new users or on "Do not use saved package" option

  return {
    package: {
      packageTypeKey: shipmentPreset.packageTypeKey,
      dimensionX: shipmentPreset.dimensionX.toString(),
      dimensionY: shipmentPreset.dimensionY.toString(),
      dimensionZ: shipmentPreset.dimensionZ.toString(),
      weightPounds: toWeightObject(shipmentPreset.weight).pounds,
      weightOunces: toWeightObject(shipmentPreset.weight).ounces,
    },
    extraServices: {
      deliveryConfirmationSelectEnabled: shipmentPreset.deliveryConfirmationFlag,
      // the backend can return 'none', but this value does not exist in the dropdown
      // therefore, when the checkbox gets checked, we have to set the value to our default value
      deliveryConfirmation:
        shipmentPreset.deliveryConfirmation === 'none'
          ? INITIAL_EXTRA_SERVICE_SUBFORM_VALUES.deliveryConfirmation
          : shipmentPreset.deliveryConfirmation,
      returnLabelSelectEnabled: shipmentPreset.returnLabelFlag && !is2x7LabelSize,
      returnLabel: shipmentPreset.returnLabel,
      insuranceInputEnabled: shipmentPreset.insuredValueFlag,
      insuredValue: shipmentPreset.insuredValue,
      isMediaMail: shipmentPreset.qualifiesAsMediaMail,
      isIrregularPackage: shipmentPreset.irregularPackage,
    },
    hazardousMaterials: {
      hazardousMaterialsEnabled: shipmentPreset.hazardousMaterialsEnabled,
    },
    customsForm: {
      customsFormEnabled:
        (shipmentPreset.customsFormEnabled || isCustomsFormRequired) && !is2x7LabelSize,
      customsSigner: shipmentPreset.customsSigner,
      customsContentType:
        shipmentPreset.customsContentType || INITIAL_CUSTOMS_FORM_SUBFORM_VALUES.customsContentType,
      customsItems:
        shipmentPreset.customsItems.length < 1
          ? INITIAL_CUSTOMS_FORM_SUBFORM_VALUES.customsItems
          : shipmentPreset.customsItems.map((item) => ({
              title: item.title,
              quantity: item.quantity,
              itemValue: item.itemValue,
              weightPounds: toWeightObject(item.weight).pounds,
              weightOunces: toWeightObject(item.weight).ounces,
              hsTariffNumber: item.hsTariffNumber,
              countryCodeOfOrigin: item.countryCodeOfOrigin,
            })),
      exporterTaxId: shipmentPreset.exporterTaxId,
      recipientTaxId: shipmentPreset.recipientTaxId,
    },
  };
}

export const initialPackagePresetSubformValues = ({
  preset,
  userName,
  isCustomsFormRequired,
  is2x7LabelSize,
}: {
  preset?: ShipmentPreset;
  userName: string;
  isCustomsFormRequired: boolean;
  is2x7LabelSize: boolean;
}): PackagePresetSubformValues => ({
  // If the preset exists and is not shy/scoped, use it. Otherwise choose "Don't use Saved Package".
  shipmentPresetId: preset?.id !== undefined && !preset.shy ? preset.id : NEW_SHIPMENT_PRESET_VALUE,
  // Open up the preset details if no preset exists or the preset is shy/scoped.
  modifyPackage: !preset || preset.shy === true,
  packageDetails: preset
    ? mapPresetToFormValues(preset, isCustomsFormRequired, is2x7LabelSize)
    : initialPackageDetailsSubformValues(userName, isCustomsFormRequired, is2x7LabelSize),
  savePreset: false,
  presetTitle: preset?.title ?? '',
});

export const packagePresetValidationSchema = ({
  isCustomsFormRequired,
  isHsCodeRequired,
  is2x7LabelSize,
  shipmentBoundaries,
  packageTypes,
}: {
  isCustomsFormRequired: boolean;
  isHsCodeRequired: boolean;
  is2x7LabelSize: boolean;
  shipmentBoundaries: ShipmentBoundaries;
  packageTypes: readonly PackageType[];
}) =>
  yup.object<PackagePresetSubformValues>({
    shipmentPresetId: yup.string().required(),
    modifyPackage: yup.boolean(),
    packageDetails: packageDetailsValidationSchema(
      isCustomsFormRequired,
      isHsCodeRequired,
      is2x7LabelSize,
      shipmentBoundaries,
      packageTypes,
    ).required(),
    savePreset: yup.boolean().defined(),
    presetTitle: yup
      .string()
      .when('savePreset', {
        is: true,
        then: yup.string().required('Title is required').max(34),
        otherwise: yup.string(),
      })
      .default(''),
  });
