import bigcartelIconUrl from './bigcartel.svg';
import bigcommerceIconUrl from './bigcommerce.svg';
import chargifyIconUrl from './chargify.svg';
import cratejoyIconUrl from './cratejoy.svg';
import discogsIconUrl from './discogs.svg';
import ebayIconUrl from './ebay.svg';
import ecwidIconUrl from './ecwid.svg';
import etsyIconUrl from './etsy.svg';
import fakeshopIconUrl from './fakeshop.svg';
import moonclerkIconUrl from './moonclerk.svg';
import paypalIconUrl from './paypal.svg';
import shopifyIconUrl from './shopify.svg';
import squareIconUrl from './square.svg';
import squarespaceIconUrl from './squarespace.svg';
import subblyIconUrl from './subbly.svg';
import wixIconUrl from './wix.svg';
import woocommerceIconUrl from './woocommerce.svg';

export type PlatformKey =
  | 'bigcartel'
  | 'bigcommerce'
  | 'chargify'
  | 'cratejoy'
  | 'discogs'
  | 'ebay'
  | 'ecwid'
  | 'etsy'
  | 'fakeshop'
  | 'moonclerk'
  | 'paypal'
  | 'shopify'
  | 'square'
  | 'squarespace'
  | 'subbly'
  | 'wix'
  | 'woocommerce';

export const platformKeyToIconUrl = new Map<PlatformKey, string>([
  ['bigcartel', bigcartelIconUrl],
  ['bigcommerce', bigcommerceIconUrl],
  ['chargify', chargifyIconUrl],
  ['cratejoy', cratejoyIconUrl],
  ['discogs', discogsIconUrl],
  ['ebay', ebayIconUrl],
  ['ecwid', ecwidIconUrl],
  ['etsy', etsyIconUrl],
  ['fakeshop', fakeshopIconUrl],
  ['moonclerk', moonclerkIconUrl],
  ['paypal', paypalIconUrl],
  ['shopify', shopifyIconUrl],
  ['square', squareIconUrl],
  ['squarespace', squarespaceIconUrl],
  ['subbly', subblyIconUrl],
  ['wix', wixIconUrl],
  ['woocommerce', woocommerceIconUrl],
]);
