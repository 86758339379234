import { gql as gqlFuncAliasNotCodegenParsed, useLazyQuery } from '@apollo/client';
import { useMemo } from 'react';
import { DataGridFilterInput, DataGridFilterOption } from '../../../../gql/graphql';
import constructGridQueryBody from '../../utils/constructGridQueryBody';
import constructGridQueryDefinition from '../../utils/constructGridQueryDefinition';
import { GridQueryVariables } from './connectedGridRows';

export interface ConnectedGridMulticheckOptionsQueryResult {
  readonly __typename: 'DataGridResult';
  readonly id: string;
  readonly multiCheckOptions: ReadonlyArray<DataGridFilterOption>;
}

export interface ConnectedGridMulticheckOptionsQuery {
  [key: string]: ConnectedGridMulticheckOptionsQueryResult;
}

export interface ConnectedGridMulticheckOptionsVariables {
  readonly searchTerm?: string | null;
  readonly filters?: ReadonlyArray<DataGridFilterInput> | null;
  readonly groupByField: string;
}

// the set of variables with type used in the generic connected data grid query (value not needed here as the values are not parsed)
const genericGridQueryDefinitionVariables = [
  { variableName: 'searchTerm', type: 'String' },
  { variableName: 'filters', type: '[DataGridFilterInput!]' },
  { variableName: 'groupByField', type: 'String!' },
];

const multiCheckInnerQuery = `multiCheckOptions(
  searchTerm: $searchTerm,
  filters: $filters,
  groupByField: $groupByField
) {
  value
  count
  label
  disabled
}
metaData {
  key
  value
}`;

export const makeConnectedGridMultiCheckOptionsQuery = (
  queryName: string,
  gridQueryVariables?: GridQueryVariables,
) => {
  const queryDefinition = constructGridQueryDefinition([
    ...(gridQueryVariables?.length ? gridQueryVariables : []),
    ...genericGridQueryDefinitionVariables,
  ]);
  return gqlFuncAliasNotCodegenParsed(`
    query ConnectedGridMultiCheckOptionsQuery${queryDefinition}
    {
      ${constructGridQueryBody(queryName, gridQueryVariables, multiCheckInnerQuery)}
    }
  `);
};

// utility map type: take properties in an object and map them as readonly (the result being a similar type to the generic variables above)
type ReadonlyPropertyMap<T> = {
  readonly [Property in keyof T]: unknown;
};

type ConnectedGridMulticheckOptionsQueryVariables = ReadonlyPropertyMap<Record<string, unknown>> & {
  readonly searchTerm?: string | null;
  readonly filters?: ReadonlyArray<DataGridFilterInput> | null;
  readonly groupByField: string;
};

export default function useConnectedGridMultiCheckOptionsLazyQuery(
  queryName: string,
  gridQueryVariables?: GridQueryVariables,
) {
  const query = useMemo(
    () => makeConnectedGridMultiCheckOptionsQuery(queryName, gridQueryVariables),
    [queryName, gridQueryVariables],
  );
  return useLazyQuery<
    ConnectedGridMulticheckOptionsQuery,
    ConnectedGridMulticheckOptionsQueryVariables
  >(query);
}
