import * as yup from 'yup';
import { CustomsFormSubformValues } from './CustomsFormSubform';
import { CustomsLineItemSubformValues } from './CustomsLineItemSubform';
import {
  INITIAL_CUSTOMS_LINE_ITEM_SUBFORM_VALUES,
  customsLineItemValidationSchema,
} from './customsLineItemSubformUtils';

export const CONTENT_TYPE_OPTIONS = [
  {
    value: 'Merchandise',
    title: 'Merchandise',
    description:
      'Products that were purchased by your recipient; they will probably have to show a receipt proving the declared value is correct to receive the package.',
  },
  {
    value: 'Documents',
    title: 'Documents',
    description: 'For contracts and other printed documents only.',
  },
  {
    value: 'Gift',
    title: 'Gift',
    description:
      'Only choose this option if it’s actually a gift… it will not reduce the chance of your recipient having to pay import duties!',
  },
];

export const INITIAL_CUSTOMS_FORM_SUBFORM_VALUES: CustomsFormSubformValues = {
  customsFormEnabled: false,
  customsSigner: '',
  customsContentType: 'Merchandise',
  customsItems: [{ ...INITIAL_CUSTOMS_LINE_ITEM_SUBFORM_VALUES }],
  exporterTaxId: '',
  recipientTaxId: '',
};

export const customsFormValidationSchema = (
  customsFormRequired: boolean,
  hsTariffNumberRequired: boolean,
  maxWeight: number,
) =>
  yup.object<CustomsFormSubformValues>({
    customsFormEnabled: yup.boolean().defined(),
    customsSigner: yup
      .string()
      .defined()
      .when(['customsFormEnabled'], (customsFormEnabled: boolean, schema: yup.StringSchema) =>
        customsFormEnabled || customsFormRequired
          ? schema.required('Customs signer is required').max(34)
          : schema,
      ),
    customsContentType: yup
      .string()
      .defined()
      .when(['customsFormEnabled'], (customsFormEnabled: boolean, schema: yup.StringSchema) =>
        customsFormEnabled || customsFormRequired
          ? schema.oneOf(CONTENT_TYPE_OPTIONS.map((opt) => opt.value))
          : schema.default(''),
      ),
    customsItems: yup
      .array<CustomsLineItemSubformValues>(
        customsLineItemValidationSchema(hsTariffNumberRequired, maxWeight).required(),
      )
      .defined()
      .when(
        ['customsFormEnabled'],
        (customsFormEnabled: boolean, schema: yup.ArraySchema<CustomsLineItemSubformValues>) =>
          customsFormEnabled || customsFormRequired ? schema.required() : yup.array(),
      ),
    exporterTaxId: yup.string().defined().default('').exporterTaxId().max(20),
    recipientTaxId: yup.string().defined().default('').recipientTaxId().max(25),
  });
