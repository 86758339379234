import styled from '@emotion/styled';
import { HTMLAttributes, MouseEvent, PropsWithChildren } from 'react';
import NumberFormat from 'react-number-format';
import { REPORT_TILE_SIZE } from '../../../../constants';
import { BORDER_WIDTH } from '../../../../styles/borders';
import { GREYSCALE } from '../../../../styles/colors';
import { SPACING } from '../../../../styles/spacing';
import { TYPOGRAPHY } from '../../../../styles/typography';
import { RouterLink } from '../../../Link';
import Shimmer from '../../../loading/Shimmer';

type ReportTileProps = {
  title: string;
  loading: boolean;
  labelCount?: number;
  amount?: string | number;
  shipments?: string | number;
  link?: string;
  linkTo?: string;
  onLinkClick?: (e: MouseEvent) => void;
  link2?: string;
  link2To?: string;
  onLink2Click?: (e: MouseEvent) => void;
  caption?: string;
  fullHeight?: boolean;
  valueId?: string;
  labelId?: string;
  secondary?: string | number;
} & PropsWithChildren<HTMLAttributes<HTMLDivElement>>;

type ReportTileWrapperProps = {
  fullHeight: boolean;
};

// eslint-disable-next-line react-refresh/only-export-components
export const ReportTileStyles = {
  ReportTileWrapper: styled('div')<ReportTileWrapperProps>`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    gap: ${SPACING.lg};
    width: 100%;
    margin-bottom: ${SPACING.xl};
    background-color: ${GREYSCALE.grey00};
    height: ${({ fullHeight }) =>
      fullHeight ? `calc(100% - ${SPACING.xl})` : `${REPORT_TILE_SIZE.Screen}`};

    @media print {
      height: ${REPORT_TILE_SIZE.Print};
      border: 1px solid silver;
      break-inside: avoid;
      justify-content: space-around;
      padding-block: 2rem;
      h3 {
        font-size: ${TYPOGRAPHY.fontSize.md};
      }
    }
  `,

  ReportSubtitle: styled.div`
    text-align: center;
    font-size: ${TYPOGRAPHY.fontSize.xl};
  `,
  SecondaryText: styled.small`
    font-size: ${TYPOGRAPHY.fontSize.sm};
    font-weight: ${TYPOGRAPHY.fontWeight.regular};
    color: ${GREYSCALE.grey50};
    position: relative;
    top: -${SPACING.xs};
    left: ${SPACING.sm};
  `,
  ReportContent: styled.div`
    justify-items: center;
    align-items: center;
    padding: 0 ${SPACING.lg} ${SPACING.sm};
    height: calc(100% - ${SPACING.xxl});
  `,
  ReportTileFooter: styled.div`
    border-top: ${BORDER_WIDTH.sm} solid ${GREYSCALE.grey30};
    background-color: ${GREYSCALE.grey10};
    padding: ${SPACING.md};
    text-align: center;

    @media print {
      display: none;
    }
  `,
  ReportLink: styled(RouterLink)`
    text-decoration: none;
    :not(:first-of-type) {
      margin-left: 1em;
    }
  `,
  Caption: styled.p`
    margin: 0;
    font-weight: ${TYPOGRAPHY.fontWeight.medium};
  `,
};

export const ReportsTitle = styled.h2`
  margin-block: ${SPACING.xl} ${SPACING.none};
  text-align: center;
  @media print {
    margin-top: ${SPACING.none};
  }
`;

function ReportsTile({
  title,
  loading,
  labelCount,
  amount,
  shipments,
  link,
  linkTo,
  onLinkClick,
  link2,
  link2To,
  onLink2Click,
  caption,
  children,
  fullHeight = false,
  labelId,
  valueId,
  secondary,
  ...others
}: ReportTileProps) {
  const showLabelCount = labelCount !== undefined;
  const showAmount = amount !== undefined;
  const showShipments = shipments !== undefined;

  return (
    <ReportTileStyles.ReportTileWrapper fullHeight={fullHeight} {...others}>
      <ReportsTitle data-testid={labelId}>{title}</ReportsTitle>
      {loading ? (
        <Shimmer.Rectangle height={188} />
      ) : (
        <>
          {(showLabelCount || showAmount || showShipments) && (
            <ReportTileStyles.ReportSubtitle>
              {showLabelCount && (
                <>
                  <NumberFormat
                    value={labelCount}
                    displayType="text"
                    thousandsGroupStyle="thousand"
                    thousandSeparator
                  />
                  <span> labels</span>
                </>
              )}
              {showLabelCount && showAmount && ' - '}
              {showAmount && (
                <NumberFormat
                  data-testid={valueId}
                  value={amount}
                  displayType="text"
                  prefix="$"
                  thousandsGroupStyle="thousand"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                />
              )}
              {showShipments && (
                <NumberFormat
                  value={shipments}
                  displayType="text"
                  thousandsGroupStyle="thousand"
                  thousandSeparator
                />
              )}
              {secondary && (
                <ReportTileStyles.SecondaryText>{secondary}</ReportTileStyles.SecondaryText>
              )}
            </ReportTileStyles.ReportSubtitle>
          )}
          {children && <ReportTileStyles.ReportContent>{children}</ReportTileStyles.ReportContent>}
        </>
      )}
      {(!!link || !!link2 || !!caption) && (
        <ReportTileStyles.ReportTileFooter>
          {!!link && (
            <ReportTileStyles.ReportLink to={linkTo || ''} onClick={onLinkClick}>
              {link}
            </ReportTileStyles.ReportLink>
          )}
          {!!link2 && (
            <ReportTileStyles.ReportLink to={link2To || ''}>{link2}</ReportTileStyles.ReportLink>
          )}
          {!!caption && <ReportTileStyles.Caption>{caption}</ReportTileStyles.Caption>}
        </ReportTileStyles.ReportTileFooter>
      )}
    </ReportTileStyles.ReportTileWrapper>
  );
}

export default ReportsTile;
