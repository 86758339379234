import { useMutation } from '@apollo/client';
import { gql } from '../../gql';

export const updateWarehouseMutation = gql(/* GraphQL */ `
  mutation UpdateWarehouseMutation(
    $id: ID!
    $title: String!
    $useOriginAsReturnAddress: Boolean!
    $originAddress: AddressInput!
    $returnAddress: AddressInput
  ) {
    updateWarehouse(
      id: $id
      title: $title
      useOriginAsReturnAddress: $useOriginAsReturnAddress
      originAddress: $originAddress
      returnAddress: $returnAddress
    ) {
      originalId
      ...WarehouseFragment
    }
  }
`);

export const useUpdateWarehouseMutation = () =>
  useMutation(updateWarehouseMutation, {
    // Replace previous record as updates lead to a new record being created
    update: (cache, { data }) => {
      if (!data) {
        return;
      }

      cache.updateQuery(
        {
          query: gql(/* GraphQL */ `
            query UpdateWarehousesAfterUpdateQuery {
              company {
                id
                warehouses {
                  ...WarehouseFragment
                }
              }
            }
          `),
        },
        (cacheResult) => {
          if (!cacheResult) {
            return undefined;
          }

          const existingEntries = cacheResult.company.warehouses;
          const entryIndex = existingEntries.findIndex(
            (entry) => entry.id === data.updateWarehouse.originalId,
          );

          if (entryIndex >= 0) {
            return {
              company: {
                ...cacheResult.company,
                warehouses: [
                  ...existingEntries.slice(0, entryIndex),
                  data.updateWarehouse,
                  ...existingEntries.slice(entryIndex + 1),
                ],
              },
            };
          }

          return undefined;
        },
      );
    },
  });
