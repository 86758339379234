import { US_COUNTRY_CODE } from '../constants';
import { CountryMap } from './createCountryMap';

export type Address = {
  fullName: string;
  company: string;
  address1: string;
  address2: string;
  address3?: string;
  city: string;
  regionCode: string;
  postcode: string;
  zip4: string;
  countryCode: string;
};

/**
 * Convert an Address object to an array of address lines in the following order (empty lines are left out):
 * - Full Name
 * - Company
 * - Address 1
 * - Address 2
 * - Address 3
 * - City, Region Code and Postcode
 * - Country Name (if not USA)
 */
export const addressToLines = (
  {
    fullName,
    company,
    address1,
    address2,
    address3 = '',
    city,
    regionCode,
    postcode,
    zip4,
    countryCode,
  }: Address,
  countryMap: CountryMap,
): string[] => {
  const nonEmpty = (line: string) => !!line?.trim();

  const formattedPostcode =
    zip4 && !postcode.match(/\d{5}-\d{4}/) ? `${postcode}-${zip4}` : postcode;
  const cityAndZip = [city, regionCode, formattedPostcode].filter(nonEmpty).join(' ');
  const countryName =
    countryCode !== US_COUNTRY_CODE ? countryMap[countryCode]?.name ?? countryCode : '';

  return [fullName, company, address1, address2, address3, cityAndZip, countryName].filter(
    nonEmpty,
  );
};

/**
 * Convert an Address object to a single string, delimited by newlines (or any given parameter)
 */
export default function formatAddress(
  address: Address,
  countryMap: CountryMap,
  delimiter = '\n',
): string {
  return addressToLines(address, countryMap).join(delimiter);
}
