import styled from '@emotion/styled';
import { useCurrentUser } from '@ps/authentication';
import { getIn, useFormikContext } from 'formik';
import { useEffect } from 'react';
import Checkbox from '../../../components/form/Checkbox';
import DropdownSelect from '../../../components/form/DropdownSelect';
import FormControl from '../../../components/form/FormControl';
import Label from '../../../components/form/Label';
import TextField from '../../../components/form/TextField';
import { Col, Row } from '../../../components/layout/Grid';
import { type PackageType } from '../../../components/subforms/PackageSubform';
import { NamespacedSubform } from '../../../components/subforms/types';
import { NEW_SHIPMENT_PRESET_VALUE } from '../../../constants';
import { MEDIA_QUERY } from '../../../styles/breakpoints';
import useShipmentPresetOptions from '../../hooks/useShipmentPresetOptions';
import { CustomsFormSubformValues } from '../CustomsFormSubform/CustomsFormSubform';
import PackageDetailsSubform, { type PackageDetailsSubformValues } from '../PackageDetailsSubform';
import {
  initialPackagePresetSubformValues,
  type ShipmentPreset,
} from './packagePresetSubformUtils';

const ModifyCheckboxWrapper = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  height: 100%;
  margin-top: 22px;

  @media (max-width: ${MEDIA_QUERY.xsMax}) {
    justify-content: flex-start;
  }
`;

export type PackagePresetSubformProps<NS extends string> = NamespacedSubform<NS> & {
  shipmentPresets: readonly ShipmentPreset[];
  packageTypes: readonly PackageType[];
  is2x7LabelSize: boolean;
  isCustomsFormRequired: boolean;
};

export type PackagePresetSubformValues = {
  shipmentPresetId: string;
  modifyPackage: boolean;
  packageDetails: PackageDetailsSubformValues;
  savePreset: boolean;
  presetTitle: string;
};

type ValidNamespaces<NS extends string> = `${NS}.${keyof PackagePresetSubformValues}`;
type FormValues = Record<string, unknown> & { [namespace: string]: PackagePresetSubformValues };

export default function PackagePresetSubform<NS extends string>({
  namespace,
  shipmentPresets,
  packageTypes,
  is2x7LabelSize,
  isCustomsFormRequired,
}: PackagePresetSubformProps<NS>) {
  const { values, setValues, setFieldValue, errors, isSubmitting } = useFormikContext<FormValues>();
  const { shipmentPresetId, modifyPackage, savePreset } = getIn(
    values,
    namespace,
  ) as PackagePresetSubformValues;

  const hasShipmentPresets = shipmentPresets.length > 0;
  const isNewPresetOptionSelected = shipmentPresetId === NEW_SHIPMENT_PRESET_VALUE;
  const forceModifyPackage = !hasShipmentPresets || isNewPresetOptionSelected;
  const showPackageSubform = modifyPackage || forceModifyPackage;

  const shipmentPresetOptions = useShipmentPresetOptions({ shipmentPresets, packageTypes });

  const [currentUser] = useCurrentUser();
  const userName = currentUser ? `${currentUser.firstName} ${currentUser.lastName}` : '';

  // If there are errors in the customs form, open up the package subform and scroll to the customs form section
  useEffect(() => {
    if (isSubmitting && errors[namespace]?.packageDetails?.customsForm) {
      const inputName: ValidNamespaces<NS> = `${namespace}.modifyPackage`;
      setFieldValue(inputName, true).then(() => {
        setTimeout(() => {
          type CustomsItemsPath =
            `${ValidNamespaces<NS>}.${keyof PackageDetailsSubformValues}.${keyof CustomsFormSubformValues}`;
          const inputName: CustomsItemsPath = `${namespace}.packageDetails.customsForm.customsItems`;
          const firstCustomsFormInput = document.querySelector<HTMLInputElement>(
            `input[name^="${inputName}"]`,
          );
          if (firstCustomsFormInput !== null) {
            firstCustomsFormInput.scrollIntoView();
            firstCustomsFormInput.focus();
          }
        }, 0);
      });
    }
  }, [errors, isSubmitting, namespace, setFieldValue]);

  return (
    <>
      {hasShipmentPresets && (
        <>
          <Row>
            <Col>
              <Label>Package Details</Label>
            </Col>
          </Row>
          <Row spaceBelow>
            <Col md={9} sm={6}>
              <FormControl
                name={`${namespace}.shipmentPresetId`}
                as={DropdownSelect}
                options={shipmentPresetOptions}
                onChange={async (newShipmentPresetId: string | number) => {
                  const preset = shipmentPresets.find(
                    ({ id: presetId }) => presetId === newShipmentPresetId,
                  );
                  await setValues({
                    ...values,
                    [namespace]: {
                      ...initialPackagePresetSubformValues({
                        preset,
                        userName,
                        isCustomsFormRequired,
                        is2x7LabelSize,
                      }),
                      // Keep the current modifyPackage value
                      modifyPackage,
                    },
                  });
                }}
              />
            </Col>
            <Col md={3} sm={6}>
              <ModifyCheckboxWrapper>
                <FormControl
                  as={Checkbox}
                  type="checkbox"
                  name={`${namespace}.modifyPackage`}
                  label="Modify package"
                  checked={showPackageSubform}
                  disabled={forceModifyPackage}
                />
              </ModifyCheckboxWrapper>
            </Col>
          </Row>
        </>
      )}
      {showPackageSubform && (
        <>
          <PackageDetailsSubform<ValidNamespaces<NS>>
            namespace={`${namespace}.packageDetails`}
            packageTypes={packageTypes}
            isCustomsFormRequired={isCustomsFormRequired}
            is2x7LabelSize={is2x7LabelSize}
          />
          <Row spaceBelow>
            <Col md={12}>
              {isNewPresetOptionSelected ? (
                <>
                  <Row>
                    <Col md={12}>
                      <FormControl
                        name={`${namespace}.savePreset`}
                        label="Save Package"
                        type="checkbox"
                        as={Checkbox}
                        secondaryText="Save your settings for repeated use."
                      />
                    </Col>
                  </Row>
                  {savePreset && (
                    <Row>
                      <Col>
                        <FormControl
                          as={TextField}
                          name={`${namespace}.presetTitle`}
                          placeholder="Enter a nickname for your Saved Package"
                        />
                      </Col>
                    </Row>
                  )}
                </>
              ) : (
                <Row>
                  <Col md={12}>
                    <FormControl
                      name={`${namespace}.savePreset`}
                      label="Update Saved Package"
                      type="checkbox"
                      as={Checkbox}
                      secondaryText="Update your saved settings"
                    />
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </>
      )}
    </>
  );
}
