import { PackagePresetSubformValues } from '.';
import { PackageType } from '../../../components/subforms/PackageSubform';
import { NEW_SHIPMENT_PRESET_VALUE } from '../../../constants';
import { ShipmentPresetInput } from '../../../gql/graphql';
import { useCreateShipmentPresetMutation } from '../../../operations/mutations/createShipmentPreset';
import { useUpdateShipmentPresetMutation } from '../../../operations/mutations/updateShipmentPreset';
import { toOunces } from '../../../utils/formatWeight';
import stringToNumber from '../../../utils/stringToNumber';
import { useCreateScopedShipmentPresetMutation } from '../../operation/createScopedShipmentPreset.mutation';

export default function usePackagePreset() {
  const [createShipmentPreset, { loading: isCreatingPreset }] = useCreateShipmentPresetMutation();
  const [updateShipmentPreset, { loading: isUpdatingPreset }] = useUpdateShipmentPresetMutation();
  const [createScopedShipmentPreset, { loading: isCreatingScopedPreset }] =
    useCreateScopedShipmentPresetMutation();
  const loading = isCreatingPreset || isUpdatingPreset || isCreatingScopedPreset;

  return [
    async (
      packagePresetValues: PackagePresetSubformValues,
      packageTypes: readonly PackageType[],
      defaultShipmentPresetId: string,
    ) => {
      const { shipmentPresetId, modifyPackage, savePreset } = packagePresetValues;
      const isNewPresetOptionSelected = shipmentPresetId === NEW_SHIPMENT_PRESET_VALUE;

      // Create a new preset
      if (isNewPresetOptionSelected && savePreset) {
        const { data, errors } = await createShipmentPreset({
          variables: {
            shipmentPreset: {
              ...mapPackagePresetToShipmentPresetInput(packagePresetValues, packageTypes),
              id: undefined,
            },
            default: shipmentPresetId === defaultShipmentPresetId,
          },
        });

        if (data) {
          return data.createShipmentPreset.shipmentPreset.id;
        }

        throw new Error(errors?.[0].message ?? 'Failed to create preset');
      }

      // Update an existing preset
      if (!isNewPresetOptionSelected && savePreset) {
        const { data, errors } = await updateShipmentPreset({
          variables: {
            shipmentPreset: {
              ...mapPackagePresetToShipmentPresetInput(packagePresetValues, packageTypes),
            },
            default: shipmentPresetId === defaultShipmentPresetId,
          },
        });

        if (data) {
          return data.updateShipmentPreset.shipmentPreset.id;
        }

        throw new Error(errors?.[0].message ?? 'Failed to update preset');
      }

      // If saving is not requested, create a scoped (single-use) shipment preset
      if (isNewPresetOptionSelected || modifyPackage) {
        const { data, errors } = await createScopedShipmentPreset({
          variables: {
            originalPresetId: shipmentPresetId,
            shipmentPreset: {
              ...mapPackagePresetToShipmentPresetInput(packagePresetValues, packageTypes),
              id: undefined,
              title: 'Scoped Preset',
            },
          },
        });

        if (data) {
          return data.createScopedShipmentPreset.id;
        }

        throw new Error(errors?.[0].message ?? 'Failed to create scoped preset');
      }

      // If no action is required, return the existing preset ID
      return shipmentPresetId;
    },
    { loading },
  ] as const;
}

function mapPackagePresetToShipmentPresetInput(
  values: PackagePresetSubformValues,
  packageTypes: readonly PackageType[],
): ShipmentPresetInput {
  const {
    shipmentPresetId,
    presetTitle,
    packageDetails: { extraServices, customsForm, hazardousMaterials, package: pkg },
  } = values;

  const { dimensionsRequired, heightRequired } = packageTypes.find(
    ({ packageTypeKey }) => packageTypeKey === pkg.packageTypeKey,
  )!;

  return {
    id: shipmentPresetId,
    title: presetTitle,
    packageTypeKey: pkg.packageTypeKey,
    deliveryConfirmationFlag: extraServices.deliveryConfirmationSelectEnabled,
    deliveryConfirmation: extraServices.deliveryConfirmation,
    returnLabelFlag: extraServices.returnLabelSelectEnabled,
    returnLabel: extraServices.returnLabel,
    insuredValueFlag: extraServices.insuranceInputEnabled,
    insuredValue: Number(extraServices.insuredValue),
    qualifiesAsMediaMail: extraServices.isMediaMail,
    irregularPackage: extraServices.isIrregularPackage,
    hazardousMaterialsEnabled: hazardousMaterials.hazardousMaterialsEnabled,
    // strings, because we allow fractions, e.g. 1 1/2. But BE expects type Number. Empty strings (e.g. no dimensionZ) becomes 0.
    // the check for dimensionsRequired/heightRequired is not necessary, but it improves the data quality
    dimensionX: dimensionsRequired ? stringToNumber(pkg.dimensionX || '') : 0,
    dimensionY: dimensionsRequired ? stringToNumber(pkg.dimensionY || '') : 0,
    dimensionZ: heightRequired ? stringToNumber(pkg.dimensionZ || '') : 0,
    weight: toOunces({
      pounds: Number(pkg.weightPounds),
      ounces: Number(pkg.weightOunces),
    }),
    customsFormEnabled: customsForm.customsFormEnabled,
    customsContentType: customsForm.customsContentType,
    customsSigner: customsForm.customsSigner,
    customsItems: customsForm.customsFormEnabled
      ? customsForm.customsItems.map((item) => ({
          title: item.title,
          quantity: Number(item.quantity),
          itemValue: Number(item.itemValue),
          weight: toOunces({
            pounds: Number(item.weightPounds),
            ounces: Number(item.weightOunces),
          }),
          hsTariffNumber: item.hsTariffNumber ?? '',
          countryCodeOfOrigin: item.countryCodeOfOrigin,
        }))
      : [
          // fix: backend validation should be yup validation
          // these are empty values to satisfy the backend validation
          {
            title: 'empty',
            quantity: 1,
            itemValue: 1,
            weight: 1,
            hsTariffNumber: '',
            countryCodeOfOrigin: '',
          },
        ],
    exporterTaxId: customsForm.exporterTaxId,
    recipientTaxId: customsForm.recipientTaxId,
  };
}
