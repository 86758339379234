import IntercomArticleLink from '../../components/IntercomArticleLink';
import { INTERCOM_ARTICLE_LINKS } from '../../constants';
import { formatCurrencyNoFractionDigits } from '../../utils/currency';
import type { InsuranceDetails } from '../types/insuranceTypes';
import ClaimInsureShieldInsuranceLink from './ClaimInsureShieldInsuranceLink';

type CarrierLiabilityHintProps = {
  shipmentId: string;
  insuranceDetails: InsuranceDetails;
};

export default function CarrierLiabilityHint({
  shipmentId,
  insuranceDetails,
}: CarrierLiabilityHintProps) {
  if (insuranceDetails.hasOnlyCarrierLiabilityInsurance) {
    return (
      <span>
        {insuranceDetails.insuredValue ? (
          <span>
            {formatCurrencyNoFractionDigits(insuranceDetails.insuredValue)} carrier liability
          </span>
        ) : (
          <span>Includes carrier liability</span>
        )}{' '}
        (
        {insuranceDetails.insuredValueType === 'UPS' ? (
          <ClaimInsureShieldInsuranceLink
            shipmentId={shipmentId}
            data-dd-action-name="insureshield-carrier-liability-clickout"
          >
            File claim
          </ClaimInsureShieldInsuranceLink>
        ) : (
          <IntercomArticleLink
            href={INTERCOM_ARTICLE_LINKS.fileInsurance}
            data-dd-action-name="open-intercom-carrier-liability-modal"
          >
            File claim
          </IntercomArticleLink>
        )}
        )
      </span>
    );
  }

  return null;
}
