import { useMutation } from '@apollo/client';
import { gql } from '../../gql';

export const createScanFormByShareToken = gql(/* GraphQL */ `
  mutation CreateScanFormByShareToken($shareToken: String!) {
    createScanFormByShareToken(shareToken: $shareToken) {
      id
      canCreateScanForm
      isScanFormCreated
      scanFormUrl
    }
  }
`);

export const useCreateScanFormByShareToken = () => useMutation(createScanFormByShareToken);
