import { useSuspenseQuery } from '@apollo/client';
import isEuQuery from '../operations/queries/isEu.query';

export default function useIsEu() {
  const {
    data: { isEu },
  } = useSuspenseQuery(isEuQuery, {
    fetchPolicy: 'cache-first',
  });

  return isEu;
}
