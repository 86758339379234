import * as Highcharts from 'highcharts';
import Chart from 'highcharts-react-official';
import HighchartsA11y from 'highcharts/modules/accessibility';
import { standardChartOptions } from '../../styles/charts';
import { DEFAULT_CHART_COLORS } from '../../styles/colors';
import { ChartProps } from './types';

HighchartsA11y(Highcharts);

export default function BarChart({
  labels,
  colors = DEFAULT_CHART_COLORS,
  data,
  customOptions,
}: ChartProps) {
  const options: Highcharts.Options = Highcharts.merge(
    {
      accessibility: {
        screenReaderSection: {
          beforeChartFormat:
            '<div>{typeDescription}</div><div>{xAxisDescription}</div><div>{yAxisDescription}</div>',
        },
      },
      chart: {
        height: 320,
      },
      xAxis: {
        categories: [...labels],
      },
      plotOptions: {
        column: {
          colors: [...colors],
          colorByPoint: true,
        },
      },
      series: [
        {
          type: 'column',
          data: [...data],
          showInLegend: false,
          pointPadding: 0.02,
          groupPadding: 0.02,
        },
      ],
      tooltip: {
        formatter() {
          // eslint-disable-next-line react/no-this-in-sfc
          return `${this.x}: ${this.y}`;
        },
      },
    },
    standardChartOptions,
    customOptions,
  );
  return <Chart highcharts={Highcharts} options={options} />;
}
