import { STORAGE_KEYS } from '../../../constants';
import { StorageSharedOptions, getItem, removeItem, setItem } from '../../../services/storage';
import { SingleShipmentFormValues } from './SingleShipmentFormPage';

export type StoredValues = DeepPartial<SingleShipmentFormValues>;
export const STORAGE_KEY = STORAGE_KEYS.singleShipmentFormValuesStorageKey;
const options: StorageSharedOptions = { session: true };

export default {
  clearValues: () => removeItem(STORAGE_KEY, options),
  writeValues: (values: StoredValues) => setItem(STORAGE_KEY, values, options),
  readValues: (): StoredValues | null => getItem(STORAGE_KEY, options),
};
