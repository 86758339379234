import { ReactNode } from 'react';
import { AlertVariant } from '../../components/Alert';
import { flashMessagesVar } from '../cache';

export type FlashMessage = {
  id: number;
  text: string | ReactNode;
  variant: AlertVariant;
  persistent: boolean;
};

let currentId = 0;
let timeouts: number[] = [];

// remove one message
export const removeFlashMessage = (flashMessage: FlashMessage) => {
  flashMessagesVar(flashMessagesVar().filter((m) => m.text !== flashMessage.text));
};

// remove non-persistent messages and remove persistent flag from all remaining ones
export const ageFlashMessages = () => {
  const resetMessages = flashMessagesVar()
    .filter((m) => m.persistent)
    .map((m: FlashMessage) => ({
      ...m,
      persistent: false,
    }));

  flashMessagesVar(resetMessages);
};

// dismiss all messages
export const clearFlashMessages = () => {
  flashMessagesVar([]);
};

const addTimeout = (message: FlashMessage, removeAfterMs: number) => {
  const timeoutId = window.setTimeout(() => {
    removeFlashMessage(message);
    timeouts = timeouts.filter((id) => id !== timeoutId);
  }, removeAfterMs);

  timeouts.push(timeoutId);
};

// add one message, keep the others
export const addFlashMessage = (
  text: FlashMessage['text'],
  variant: FlashMessage['variant'],
  removeAfterMs = -1,
  persistent = false,
) => {
  currentId += 1;

  const message = {
    id: currentId,
    text,
    variant,
    persistent,
  };

  flashMessagesVar([...flashMessagesVar(), message]);

  if (removeAfterMs >= 0) {
    addTimeout(message, removeAfterMs);
  }
};

// set one message and remove all others
export const setFlashMessage = (
  text: FlashMessage['text'],
  variant: FlashMessage['variant'],
  removeAfterMs = -1,
  persistent = false,
) => {
  currentId += 1;

  const message = {
    id: currentId,
    text,
    variant,
    persistent,
  };

  flashMessagesVar([message]);

  if (removeAfterMs >= 0) {
    addTimeout(message, removeAfterMs);
  }
};
