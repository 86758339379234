import { LazyQueryHookOptions, QueryHookOptions, useLazyQuery, useQuery } from '@apollo/client';
import { gql } from '../../gql';
import { ShipmentPieChartQuery, ShipmentPieChartQueryVariables } from '../../gql/graphql';

export const shipmentPieChartQuery = gql(/* GraphQL */ `
  query ShipmentPieChartQuery($datetimeRange: DatetimeRangeInput!) {
    shipmentPieChart(datetimeRange: $datetimeRange) {
      charts
      colors
      labels
    }
  }
`);

export const useShipmentPieChartQuery = (
  options: QueryHookOptions<ShipmentPieChartQuery, ShipmentPieChartQueryVariables>,
) => useQuery(shipmentPieChartQuery, options);

export const useShipmentPieChartLazyQuery = (
  options: LazyQueryHookOptions<ShipmentPieChartQuery, ShipmentPieChartQueryVariables>,
) => useLazyQuery(shipmentPieChartQuery, options);
