import styled from '@emotion/styled';
import { HeaderContext } from '@tanstack/react-table';
import { KeyboardEvent, useEffect, useState } from 'react';
import Button from '../../form/Button';
import Label from '../../form/Label';
import ButtonBar from '../controls/ButtonBar';

const Styled = {
  FilterContent: styled.div`
    display: flex;
    flex-direction: column;
  `,
};

// rendered via flexRenderer with the headerContext as single argument
export default function BooleanFilterInterface<TData, TValue>(
  headerContext: HeaderContext<TData, TValue>,
) {
  const { column, table } = headerContext;
  const loading = table.options.meta?.loading;
  const filterValue = column.getFilterValue(); // filter value as defined by table object
  const [value, setValue] = useState(filterValue); // internal value
  const submit = () => column.setFilterValue(value); // synchronise value on submit
  const clear = () => column.setFilterValue(undefined); // synchronise value on clear
  const submitOnEnter = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      submit();
    }
  };

  useEffect(() => setValue(filterValue), [filterValue]);

  return (
    <>
      <Styled.FilterContent>
        <Label>
          <input
            type="radio"
            checked={value === true}
            onChange={() => setValue(true)}
            disabled={loading}
            onKeyDown={submitOnEnter}
          />
          {' Yes'}
        </Label>
        <Label>
          <input
            type="radio"
            checked={value === false}
            onChange={() => setValue(false)}
            disabled={loading}
            onKeyDown={submitOnEnter}
          />
          {' No'}
        </Label>
      </Styled.FilterContent>
      <ButtonBar>
        <Button variant="primary" size="small" onClick={submit}>
          Filter
        </Button>
        <Button size="small" onClick={clear}>
          Clear
        </Button>
      </ButtonBar>
    </>
  );
}
