import { useMutation } from '@apollo/client';
import { gql } from '../../gql';

export const createBatchFromSingleShipmentMutation = gql(/* GraphQL */ `
  mutation CreateBatchFromSingleShipmentMutation(
    $warehouseId: ID!
    $shipmentPresetId: ID!
    $shipToAddress: RecipientAddressInput!
    $rubberStamps: RubberStampsInput!
  ) {
    createBatchFromSingleShipment(
      warehouseId: $warehouseId
      shipmentPresetId: $shipmentPresetId
      shipToAddress: $shipToAddress
      rubberStamps: $rubberStamps
    ) {
      id
    }
  }
`);

export const useCreateBatchFromSingleShipmentMutation = () =>
  useMutation(createBatchFromSingleShipmentMutation);
