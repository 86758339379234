import styled from '@emotion/styled';
import { HTMLAttributes, PropsWithChildren } from 'react';
import { SIDEBAR_MENU_ITEM_HEIGHT } from '../../../constants';
import useClickUpload from '../../../hooks/useClickUpload';
import drawerIconImportUrl from '../../../images/drawerIconImport.png';
import drawerIconSingleUrl from '../../../images/drawerIconSingle.png';
import drawerIconUploadUrl from '../../../images/drawerIconUpload.png';
import { GREYSCALE } from '../../../styles/colors';
import { SPACING } from '../../../styles/spacing';
import { TYPOGRAPHY } from '../../../styles/typography';
import environment from '../../../utils/environment';
import Link, { LinkButton } from '../../Link';
import HiddenUploadInput from '../../form/HiddenUploadInput';

const drawerWidth = '300px';
const iconSize = '45px';

type DrawerIconName = 'single' | 'upload' | 'import';

// also used in the SidebarDrawer
const drawerIconMap = new Map<DrawerIconName, string>([
  ['single', drawerIconSingleUrl],
  ['upload', drawerIconUploadUrl],
  ['import', drawerIconImportUrl],
]);

const Styled = {
  DrawerWrapper: styled.div<{ top: number; sidebarWidth: string; show: boolean }>`
    position: absolute;
    height: ${SIDEBAR_MENU_ITEM_HEIGHT};
    width: ${drawerWidth};
    z-index: -1; /* layer behind sidebar item */
    color: black;
    left: -${drawerWidth};
    top: ${({ top }) => `${top}px`};
    transform: ${({ show, sidebarWidth }) =>
      show ? `translate(calc(${drawerWidth} + ${sidebarWidth}))` : `translate(0px)`};
    transition: transform 0.15s ease-in-out;
    display: flex;
    box-sizing: border-box;
    > * {
      flex: 1;
    }
    &:focus-within {
      z-index: 0; /* reset layering on focus */
      transform: ${({ sidebarWidth }) => `translate(calc(${drawerWidth} + ${sidebarWidth}))`};
    }
  `,
  DrawerItem: styled.div`
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: ${TYPOGRAPHY.fontWeight.medium};
    font-size: ${TYPOGRAPHY.fontSize.sm};
    background-color: ${GREYSCALE.grey10};
    border: inherit;
    box-sizing: border-box;
    padding: 0;
    color: ${GREYSCALE.grey50};
    cursor: pointer;
    &:hover {
      color: ${GREYSCALE.black};
      background-color: ${GREYSCALE.white};
    }
    &:focus-within {
      z-index: 1; /* fix focus ring visibility */
    }
  `,
  DrawerItemIcon: styled.img`
    height: ${iconSize};
    width: ${iconSize};
    padding-bottom: ${SPACING.none};
  `,
};

const DrawerItemLink = Styled.DrawerItem.withComponent(Link);
const DrawerItemButton = Styled.DrawerItem.withComponent(LinkButton);

type SidebarDrawerProps = PropsWithChildren<HTMLAttributes<HTMLDivElement>> & {
  show: boolean;
  anchorTop: number; // the anchor point is the top of the menu element
  sidebarWidth: string;
  platformLink: string;
  platformIconUrl?: string;
  onChange?: () => void;
};

function SidebarDrawer({
  children,
  sidebarWidth,
  platformIconUrl,
  platformLink,
  anchorTop,
  show,
  onChange,
  ...others
}: SidebarDrawerProps) {
  const clickUpload = useClickUpload();

  return (
    <Styled.DrawerWrapper show={show} top={anchorTop} sidebarWidth={sidebarWidth} {...others}>
      <DrawerItemLink data-testid="shipSingle" to="/ship/single" bridgeHref="/ship/single">
        <Styled.DrawerItemIcon alt="" src={drawerIconMap.get('single')} />
        Single
      </DrawerItemLink>
      <DrawerItemButton data-testid="upload" onClick={clickUpload}>
        <Styled.DrawerItemIcon alt="" src={drawerIconMap.get('upload')} />
        Upload
      </DrawerItemButton>
      {!environment.isBridge() && <HiddenUploadInput />}
      <DrawerItemLink data-testid="settingsIntegration" to={platformLink} bridgeHref={platformLink}>
        <Styled.DrawerItemIcon alt="" src={platformIconUrl || drawerIconMap.get('import')} />
        Import
      </DrawerItemLink>
    </Styled.DrawerWrapper>
  );
}

export default SidebarDrawer;
