import styled from '@emotion/styled';
import { useState } from 'react';
import { BORDER_RADIUS, BORDER_WIDTH } from '../../styles/borders';
import { GREYSCALE } from '../../styles/colors';
import TextField, { TextFieldWrapper } from './TextField';

type CopyGroupProps = {
  value: string;
  label: string;
};

const CopyGroupWrapper = styled.div`
  display: flex;

  & > ${TextFieldWrapper} {
    border-top-right-radius: ${BORDER_RADIUS.none} !important;
    border-bottom-right-radius: ${BORDER_RADIUS.none} !important;
    flex: 1;
  }
`;

const ReadonlyTextField = styled(TextField)`
  cursor: not-allowed;
`;

const CopyButton = styled.button`
  cursor: pointer;
  box-sizing: border-box;
  height: 50px;
  padding: 10px 16px;
  border: ${BORDER_WIDTH.sm} solid ${GREYSCALE.grey30};
  border-left: ${BORDER_WIDTH.none};
  border-radius: ${BORDER_RADIUS.sm};
  border-top-left-radius: ${BORDER_RADIUS.none};
  border-bottom-left-radius: ${BORDER_RADIUS.none};
  background: ${GREYSCALE.grey20};
  color: ${GREYSCALE.grey50};
  text-align: center;
`;

function CopyGroup({ value, label }: CopyGroupProps) {
  const [isCopied, setIsCopied] = useState(false);
  const buttonText = isCopied ? 'Copied!' : 'Copy Link';

  return (
    <CopyGroupWrapper>
      <ReadonlyTextField
        data-testid="copy-test-field"
        readOnly
        disabled
        value={value}
        label={label}
      />
      <CopyButton
        data-testid="copy-button"
        onClick={() => {
          navigator.clipboard.writeText(value);
          setIsCopied(true);
          setTimeout(() => {
            setIsCopied(false);
          }, 3000);
        }}
      >
        {buttonText}
      </CopyButton>
    </CopyGroupWrapper>
  );
}

export default CopyGroup;
