import type { IconDefinition, IconName } from '@fortawesome/fontawesome-svg-core';
import { library } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
  faArrowCircleDown,
  faArrowLeft,
  faArrowRight,
  faAsterisk,
  faBan,
  faCalendar,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretUp,
  faCheck,
  faCircle,
  faClock,
  faCog,
  faColumns,
  faCreditCard,
  faDollarSign,
  faEnvelope,
  faFile,
  faFilter,
  faGlobe,
  faHandPointer,
  faHome,
  faLandmark,
  faLaptop,
  faLock,
  faMoneyCheck,
  faPaste,
  faPiggyBank,
  faPlay,
  faQuestionCircle,
  faRandom,
  faReceipt,
  faRedo,
  faRuler,
  faSearch,
  faSort,
  faSortAlphaDownAlt,
  faSortAlphaUp,
  faSortDown,
  faSortNumericDownAlt,
  faSortNumericUp,
  faSortUp,
  faTimes,
  faTrashAlt,
  faUniversity,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';

// add FontAwesome library icons here
const faIcons = [
  faSort,
  faSortUp,
  faSortDown,
  faCaretDown,
  faCaretUp,
  faCaretRight,
  faCaretLeft,
  faSortAlphaUp,
  faSortAlphaDownAlt,
  faFilter,
  faColumns,
  faAngleLeft,
  faAngleDoubleLeft,
  faAngleRight,
  faAngleDoubleRight,
  faTimes,
  faCog,
  faArrowCircleDown,
  faMoneyCheck,
  faRandom,
  faHome,
  faEnvelope,
  faFile,
  faLock,
  faTrashAlt,
  faAsterisk,
  faCalendar,
  faSearch,
  faBan,
  faGlobe,
  faPaste,
  faCheck,
  faSortNumericUp,
  faSortNumericDownAlt,
  faQuestionCircle,
  faRedo,
  faDollarSign,
  faClock,
  faArrowLeft,
  faArrowRight,
  faPlay,
  faCircle,
  faPiggyBank,
  faCreditCard,
  faLandmark,
  faReceipt,
  faRuler,
  faLaptop,
  faUniversity,
  faHandPointer,
  faXmark,
];

export type CustomIconProps = {
  iconName: string;
  width: number;
  height: number;
  unicode?: string; // fallback hex unicode character (e.g 'f641')
  path: string | string[];
};

// add custom icon data here
export const CUSTOM_ICON_DATA = [
  {
    iconName: 'custom-ad',
    width: 512,
    height: 512,
    path: 'M157.52 272h36.96L176 218.78 157.52 272zM352 256c-13.23 0-24 10.77-24 24s10.77 24 24 24 24-10.77 24-24-10.77-24-24-24zM464 64H48C21.5 64 0 85.5 0 112v288c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zM250.58 352h-16.94c-6.81 0-12.88-4.32-15.12-10.75L211.15 320h-70.29l-7.38 21.25A16 16 0 0 1 118.36 352h-16.94c-11.01 0-18.73-10.85-15.12-21.25L140 176.12A23.995 23.995 0 0 1 162.67 160h26.66A23.99 23.99 0 0 1 212 176.13l53.69 154.62c3.61 10.4-4.11 21.25-15.11 21.25zM424 336c0 8.84-7.16 16-16 16h-16c-4.85 0-9.04-2.27-11.98-5.68-8.62 3.66-18.09 5.68-28.02 5.68-39.7 0-72-32.3-72-72s32.3-72 72-72c8.46 0 16.46 1.73 24 4.42V176c0-8.84 7.16-16 16-16h16c8.84 0 16 7.16 16 16v160z',
  },
  {
    iconName: 'custom-address-book',
    width: 448,
    height: 512,
    path: 'M436 160c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-20V48c0-26.5-21.5-48-48-48H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h320c26.5 0 48-21.5 48-48v-48h20c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-20v-64h20c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-20v-64h20zm-228-32c35.3 0 64 28.7 64 64s-28.7 64-64 64-64-28.7-64-64 28.7-64 64-64zm112 236.8c0 10.6-10 19.2-22.4 19.2H118.4C106 384 96 375.4 96 364.8v-19.2c0-31.8 30.1-57.6 67.2-57.6h5c12.3 5.1 25.7 8 39.8 8s27.6-2.9 39.8-8h5c37.1 0 67.2 25.8 67.2 57.6v19.2z',
  },
  {
    iconName: 'custom-adjust',
    width: 512,
    height: 512,
    path: 'M8 256c0 136.966 111.033 248 248 248s248-111.034 248-248S392.966 8 256 8 8 119.033 8 256zm248 184V72c101.705 0 184 82.311 184 184 0 101.705-82.311 184-184 184z',
  },
] as const;

// transforms CustomIconProps into an IconDefinition
const iconify = (props: CustomIconProps): IconDefinition => ({
  prefix: 'fas',
  iconName: props.iconName as IconName,
  icon: [props.width, props.height, [], props.unicode ?? 'f641', props.path],
});

// map customIconData into correct shape for the FontAwesomeIcon library
const customIcons = CUSTOM_ICON_DATA.map(iconify);
export const ICONS = [...faIcons, ...customIcons];

export default function initIcons() {
  library.add(...ICONS);
}
