import styled from '@emotion/styled';
import { getIn, useFormikContext } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import Alert from '../../components/Alert';
import IntercomArticleLink from '../../components/IntercomArticleLink';
import { DropdownSelectOption } from '../../components/form/DropdownSelect';
import FormControl from '../../components/form/FormControl';
import Label from '../../components/form/Label';
import RateSummarySelect, {
  RateGroup as InnerRateGroup,
  RateSummary,
} from '../../components/form/RateSummarySelect';
import UpsAccountModals from '../../components/modals/UpsAccountModals';
import RateGroupUpsell from '../../components/rates/RateGroupUpsell';
import RateGroupsSortOrder from '../../components/rates/RateGroupsSortOrder';
import { NamespacedSubform } from '../../components/subforms/types';
import { CarrierInfoOption } from '../../constants';
import { RateGroupSortOrder } from '../../gql/graphql';
import { useUpdateRateGroupSortOrderMutation } from '../../operations/mutations/updateRateGroupSortOrder';
import { MEDIA_QUERY } from '../../styles/breakpoints';
import { SPACING } from '../../styles/spacing';
import { setRumGlobalContextProperty } from '../../utils/addRumCustomAttributes';
import convertBbcode from '../../utils/convertBbcode';
import determineUpsellRateSummary from '../../utils/determineUpsellRateSummary';
import filterRateSummaries, { getMissingCarrierKeys } from '../../utils/filterRateSummaries';
import getNearMaxWarning, { PackageDimensionsAndWeight } from '../../utils/getNearMaxWarning';
import getSelectedRateSummaries from '../../utils/getSelectedRateSummaries';
import roundFloat from '../../utils/roundFloat';
import sortRateSummaries from '../../utils/sortRateSummaries';

const Styled = {
  TopBar: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: ${MEDIA_QUERY.xsMax}) {
      flex-direction: column;
      align-items: inherit;
    }
  `,
  TopBarElementRight: styled.div`
    display: flex;
    align-items: center;
    @media (max-width: ${MEDIA_QUERY.xsMax}) {
      align-items: flex-start;
    }
  `,
  RateGroup: styled.div`
    margin-bottom: ${SPACING.xxl};
  `,
  NearMaxSizeWarningWrapper: styled.div`
    margin-top: ${SPACING.xl};
  `,
};

export type Company = {
  readonly id: string;
  readonly activeCarriers: ReadonlyArray<string>;
};

export type User = {
  readonly id: string;
  readonly rateGroupSortOrder: RateGroupSortOrder;
};

export type RateGroup = InnerRateGroup & {
  readonly title: string;
  readonly intercomLink: string | null;
};

export type RateGroupsSubformProps<NS extends string> = {
  rateGroups: ReadonlyArray<RateGroup>;
  company: Company;
  user: User;
  shipmentCount?: number;
  packageDimensionsAndWeight: PackageDimensionsAndWeight;
  onMerchantCreated?: () => void;
} & NamespacedSubform<NS>;

export type RateGroupsSubformValues = Record<string, string>;

export default function RateGroupsSubform<NS extends string>({
  namespace,
  rateGroups,
  user,
  company,
  shipmentCount,
  packageDimensionsAndWeight,
  onMerchantCreated,
}: RateGroupsSubformProps<NS>) {
  const [showUpsAccountModal, setShowUpsAccountModal] = useState(false);
  const { values, setFieldValue } = useFormikContext<Record<string, string>>();
  const rateGroupsValues = getIn(values, namespace) satisfies RateGroupsSubformValues;
  const [sortOrder, setSortOrder] = useState<RateGroupSortOrder>(user.rateGroupSortOrder);
  const [updateRateGroupSortOrderMutation] = useUpdateRateGroupSortOrderMutation();
  const isUpsActive = company.activeCarriers.includes('ups');
  const hasMultipleShipments = shipmentCount !== undefined && shipmentCount > 1;

  const nearMaxWarning = getNearMaxWarning(
    packageDimensionsAndWeight,
    getSelectedRateSummaries(rateGroupsValues, rateGroups).some(
      (r) => r.firstMailClass.mailClassKey === '93',
    )
      ? 'groundSaver'
      : 'standard',
    hasMultipleShipments,
  );

  // map rate groups to all relevant data to be rendered
  const rateGroupsRenderData: {
    rateGroup: RateGroup; // raw data
    options: DropdownSelectOption<string, RateSummary>[]; // options are sorted and filtered
    upsellRateSummary?: RateSummary;
    selectedRateSummary?: RateSummary;
  }[] = useMemo(
    () =>
      rateGroups.map((rateGroup) => {
        const { groupKey, rateSummaries } = rateGroup;

        const selectedRateSummary = rateSummaries.find(
          (summary: RateSummary) => summary.uniqueId === rateGroupsValues[groupKey.string],
        );

        // Does this rate group have missing UPS rates due to rate limiting?
        const upsRateLimited = rateGroup.affectedByUpsRateLimit;

        const filteredRateSummaries = filterRateSummaries(rateGroup);
        const filteredAndSortedRateSummaries = sortRateSummaries(filteredRateSummaries, sortOrder);
        // create a set of carrier ids NOT included in the return summaries
        const missingCarrierKeys = getMissingCarrierKeys(filteredAndSortedRateSummaries);

        const options: DropdownSelectOption<string, RateSummary>[] = [
          ...filteredAndSortedRateSummaries.map((summary) => ({
            value: summary.uniqueId,
            disabled: summary.totalPrice <= 0,
            data: summary, // add the summary itself to the option in the data field
          })),
          ...(upsRateLimited ? [{ value: CarrierInfoOption.UpsRateLimited }] : []), // add rate limited info option if rate limited
          ...(missingCarrierKeys.has('usps') ? [{ value: CarrierInfoOption.NoUsps }] : []), // add no usps info option if needed
          ...(missingCarrierKeys.has('ups') && isUpsActive
            ? [{ value: CarrierInfoOption.NoUps }]
            : []), // add no ups info option if needed
        ];

        const upsellRateSummary = determineUpsellRateSummary(
          selectedRateSummary,
          rateGroup,
          sortOrder === 'CHEAPEST',
        );

        return {
          rateGroup,
          options,
          upsellRateSummary,
          selectedRateSummary,
        };
      }),
    [isUpsActive, rateGroups, rateGroupsValues, sortOrder],
  );

  // this iterates over all rate summaries in all rate groups and returns the set of DIFFERENT carriers
  const carriersSet = new Set(
    rateGroupsRenderData.flatMap(({ options }) =>
      options.flatMap((option) => option.data?.carrier.carrierKey ?? []),
    ),
  );

  // the sort order is updated immediately so the user does not have to wait for the mutation response
  const updateRateSortOrder = async (order: RateGroupSortOrder) => {
    // do not send another mutation when the selected order is already applied
    if (sortOrder === order) {
      return;
    }
    setSortOrder(order);
    try {
      const result = await updateRateGroupSortOrderMutation({
        variables: { sortOrder: order },
        optimisticResponse: {
          updateRateGroupSortOrder: {
            __typename: 'User',
            id: user.id,
            rateGroupSortOrder: order,
          },
        },
      });
      if (result.errors) {
        // quietly fail for now
      }
      // quietly fail for now
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error) {
      // quietly fail for now
    }
  };

  // set the initial value of the rate group select to the first rate summary in each rate group
  useEffect(() => {
    // set initial values according to selected sort order
    rateGroups.forEach((rg) => {
      const filteredRateSummaries = filterRateSummaries(rg);
      const filteredAndSortedRateSummaries = sortRateSummaries(filteredRateSummaries, sortOrder);
      setFieldValue(
        `${namespace}.${rg.groupKey.string}`,
        filteredAndSortedRateSummaries[0].uniqueId,
      );
    });
  }, [namespace, rateGroups, setFieldValue, sortOrder]);

  useEffect(() => {
    if (rateGroupsRenderData.length === 1) {
      const {
        best,
        fastest,
        cheapest,
        totalPrice,
        shipmentCount: rateGroupShipmentCount,
        mailClassTitle,
      } = rateGroupsRenderData[0].selectedRateSummary ?? {};

      const upsellTotalPrice = rateGroupsRenderData[0].upsellRateSummary?.totalPrice;

      let savings;
      if (totalPrice && upsellTotalPrice) {
        savings = roundFloat(Math.abs(upsellTotalPrice - totalPrice), 2);
        savings = upsellTotalPrice > totalPrice ? -savings : savings;
      }

      const { groupKey, numberOfCheapestOrBestServices } = rateGroupsRenderData[0].rateGroup ?? {};

      setRumGlobalContextProperty(
        'initial_state_rateGroupSubform',
        {
          sortOrder,
          groupKey: groupKey.string,
          mailClassTitle,
          numberOfCheapestOrBestServices,
          numberOfRateGroups: rateGroups.length,
          rateGroupShipmentCount,
          totalShipmentCount: shipmentCount,
          totalPrice,
          best,
          fastest,
          cheapest,
          upsellSavings: savings,
        },
        '1.0.0',

        false,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <UpsAccountModals
        open={showUpsAccountModal}
        headline="Accept the UPS terms"
        buttonText="Accept & Get Rates"
        onAccountCreatedOrUpdated={() => {
          if (onMerchantCreated) {
            onMerchantCreated();
          }
          setShowUpsAccountModal(false);
        }}
        onCancel={() => setShowUpsAccountModal(false)}
      />
      {rateGroupsRenderData.map(
        ({ rateGroup, options, upsellRateSummary, selectedRateSummary }, groupIndex: number) => (
          <Styled.RateGroup key={rateGroup.groupKey.string}>
            <Styled.TopBar>
              <Styled.TopBarElementRight>
                <Label>{rateGroup.title}&nbsp;</Label>
                {rateGroup.intercomLink && (
                  <IntercomArticleLink href={rateGroup.intercomLink}>
                    Learn more
                  </IntercomArticleLink>
                )}
              </Styled.TopBarElementRight>
              {groupIndex === 0 && (
                <RateGroupsSortOrder
                  sortOrder={sortOrder}
                  onUpdateSortOrder={updateRateSortOrder}
                  carriersSet={carriersSet}
                />
              )}
            </Styled.TopBar>
            <FormControl
              as={RateSummarySelect}
              options={options}
              name={`${namespace}.${rateGroup.groupKey.string}`}
              key={rateGroup.groupKey.string}
              className={`rategroup-select-${rateGroup.groupKey.string}`}
              rateGroup={rateGroup}
              hasUpsell={upsellRateSummary !== null}
              onRequestUpsMerchantCreation={() => setShowUpsAccountModal(true)}
            />
            {selectedRateSummary && upsellRateSummary && (
              <RateGroupUpsell
                upsellRateSummary={upsellRateSummary}
                selectedTotalPrice={selectedRateSummary.totalPrice}
                onSwitch={(uniqueId) => {
                  setFieldValue(`${namespace}.${rateGroup.groupKey.string}`, uniqueId);
                }}
              />
            )}
            {selectedRateSummary?.carrier.carrierKey === 'ups' && nearMaxWarning && (
              <Styled.NearMaxSizeWarningWrapper>
                <Alert variant="danger" data-testid="near-max-size-warning">
                  {convertBbcode(nearMaxWarning)}
                </Alert>
              </Styled.NearMaxSizeWarningWrapper>
            )}
          </Styled.RateGroup>
        ),
      )}
    </>
  );
}
