import styled from '@emotion/styled';
import { useState } from 'react';
import { COLOR } from '../../styles/colors';
import { SPACING } from '../../styles/spacing';
import { TYPOGRAPHY } from '../../styles/typography';
import { LinkButton } from '../Link';
import ProgressButton from '../form/ProgressButton';
import ConfirmationModal from '../modals/ConfirmationModal';
import { Col, Row } from './Grid';

export type ShipmentFlowPageFooterProps = {
  inProgress?: boolean;
  nextStepTitle: string;
  onNextStep: () => void;
  prevStepTitle?: string;
  onPrevStep?: () => void;
  cancelTitle?: string;
  cancelConfirmationTitle?: string;
  onCancel?: () => void;
};

const Styled = {
  PrevStepLink: styled(LinkButton)`
    font-size: ${TYPOGRAPHY.fontSize.xs};
    padding: ${SPACING.xs} ${SPACING.none};
    text-decoration: underline;
  `,
  CancelLink: styled(LinkButton)`
    font-size: ${TYPOGRAPHY.fontSize.xs};
    padding: ${SPACING.xs} ${SPACING.none};
    color: ${COLOR.red};
    text-decoration: underline;
    &:hover {
      color: ${COLOR.darkRed};
    }
  `,
};

export default function ShipmentFlowPageFooter({
  inProgress,
  nextStepTitle,
  onNextStep,
  prevStepTitle = 'Previous Step',
  onPrevStep,
  cancelTitle = 'Cancel & Delete Label(s)',
  cancelConfirmationTitle = 'Delete Label(s)?',
  onCancel,
}: ShipmentFlowPageFooterProps) {
  const [showCancelModal, setShowCancelModal] = useState(false);

  return (
    <>
      <ConfirmationModal
        title={cancelConfirmationTitle}
        confirmationType="destructive"
        onCancel={() => setShowCancelModal(false)}
        onConfirm={() => {
          setShowCancelModal(false);

          if (onCancel) {
            onCancel();
          }
        }}
        open={showCancelModal}
      />

      <Row align="center" justify="between" direction="row-reverse">
        <Col xs={12} sm="content" spaceBelow>
          <ProgressButton
            id="buy-button"
            type="button"
            fullWidth
            size="xLarge"
            variant="success"
            data-dd-action-name="Buy Label(s)"
            disabled={inProgress}
            progress={inProgress}
            onClick={() => onNextStep()}
          >
            {nextStepTitle}
          </ProgressButton>
        </Col>

        {!inProgress && (onPrevStep || onCancel) && (
          <Col spaceBelow>
            <Row>
              {onPrevStep && (
                <Col md={12}>
                  <Styled.PrevStepLink onClick={() => onPrevStep()}>
                    {prevStepTitle}
                  </Styled.PrevStepLink>
                </Col>
              )}
              {onCancel && (
                <Col md={12}>
                  <Styled.CancelLink
                    onClick={() => setShowCancelModal(true)}
                    data-dd-action-name="Cancel & Delete Label(s)"
                  >
                    {cancelTitle}
                  </Styled.CancelLink>
                </Col>
              )}
            </Row>
          </Col>
        )}
      </Row>
    </>
  );
}
