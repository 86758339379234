import { css } from '@emotion/react';
import styled from '@emotion/styled';
import range from 'lodash.range';
import useIsMobile from '../../../hooks/useIsMobile';
import { BORDER_RADIUS, BORDER_WIDTH } from '../../../styles/borders';
import { GREYSCALE } from '../../../styles/colors';
import Icon from '../../Icon';

export type PaginationButtonProps = {
  active?: boolean;
};

export type PaginationDropdownOptionProps = {
  type: string;
};

const Styled = {
  Pagination: styled.div`
    display: flex;
  `,
  PaginationDropdown: styled.select`
    background: ${GREYSCALE.white};
    color: ${GREYSCALE.grey80};
    border: ${BORDER_WIDTH.xs} solid ${GREYSCALE.grey30};
    border-right-width: ${BORDER_WIDTH.xl};

    // the following props copied over from kendogrid
    border-right: transparent;
    outline: none;
    box-sizing: border-box;
    cursor: pointer;
    text-align: center;
    min-width: 4em;
    padding: 0 0.714em 0 0.429em;
    :focus-visible {
      outline: 1px dotted;
    }
  `,
  PaginationDropdownOption: styled.option<PaginationDropdownOptionProps>`
    text-align: right;
    padding-left: 3px;
    padding-right: 3px;
  `,
  PaginationButton: styled.button<PaginationButtonProps>`
    min-width: 2em;
    height: 2em;
    line-height: 2em;
    text-align: center;
    color: ${GREYSCALE.grey80};
    background: ${GREYSCALE.white};
    border: ${BORDER_WIDTH.xs} solid ${GREYSCALE.grey30};
    border-right-width: ${BORDER_WIDTH.none};
    outline: none;
    box-sizing: border-box;
    cursor: pointer;

    :first-of-type {
      border-radius: ${BORDER_RADIUS.sm} ${BORDER_RADIUS.none} ${BORDER_RADIUS.none}
        ${BORDER_RADIUS.sm};
    }

    :last-of-type {
      border-right-width: ${BORDER_WIDTH.xs};
      border-radius: ${BORDER_RADIUS.none} ${BORDER_RADIUS.sm} ${BORDER_RADIUS.sm}
        ${BORDER_RADIUS.none};
    }

    :disabled {
      color: ${GREYSCALE.grey50};
    }

    ${({ active }) =>
      !active &&
      css`
        :hover {
          background-color: ${GREYSCALE.grey20};
        }
      `}

    ${({ active }) =>
      active &&
      css`
        background-color: ${GREYSCALE.black};
        color: ${GREYSCALE.white};
      `}
  `,
};

export type PaginationProps = {
  canPreviousPage: boolean;
  canNextPage: boolean;
  gotoPage: (page: number) => void;
  previousPage: () => void;
  nextPage: () => void;
  pageCount: number;
  pageIndex: number;
  buttonCount?: number;
};

export default function Pagination({
  canPreviousPage,
  canNextPage,
  gotoPage,
  previousPage,
  nextPage,
  pageCount,
  pageIndex,
  buttonCount = 5,
}: PaginationProps) {
  const { isMobile } = useIsMobile();
  const lastPageIndex = pageCount - 1;
  const isCloseToEnd = lastPageIndex - pageIndex < Math.floor(buttonCount / 2);
  // the index of the leftmost page button in the UI
  const leftmostPageIndex = isCloseToEnd
    ? Math.max(0, pageCount - buttonCount)
    : Math.max(0, pageIndex - Math.floor(buttonCount / 2));

  return (
    <Styled.Pagination role="navigation" aria-label="Pagination Navigation">
      <Styled.PaginationButton
        type="button"
        onClick={() => gotoPage(0)}
        disabled={!canPreviousPage}
        title="Go to the first page"
      >
        <Icon icon="angle-double-left" />
      </Styled.PaginationButton>
      <Styled.PaginationButton
        type="button"
        onClick={previousPage}
        disabled={!canPreviousPage}
        title="Go to the previous page"
      >
        <Icon icon="angle-left" />
      </Styled.PaginationButton>
      {isMobile ? (
        <Styled.PaginationDropdown
          data-testid="pagination-dropdown"
          disabled={pageCount === 1}
          value={pageIndex}
          onChange={(e) => gotoPage(Number(e.target.value))}
        >
          {range(0, pageCount).map((index) => (
            <Styled.PaginationDropdownOption
              key={`paginationDropdown-${index}`}
              type="button"
              title={`Go to page ${index + 1}`}
              value={index}
            >
              {index + 1}
            </Styled.PaginationDropdownOption>
          ))}
        </Styled.PaginationDropdown>
      ) : (
        <>
          {range(0, Math.min(pageCount, buttonCount)).map((index) => {
            const displayNumber = leftmostPageIndex + index + 1;
            const isActive = leftmostPageIndex + index === pageIndex;

            return (
              <Styled.PaginationButton
                key={leftmostPageIndex + index}
                type="button"
                active={isActive}
                data-active={isActive}
                data-testid="pagination-button"
                title={`Go to page ${displayNumber}`}
                onClick={() => gotoPage(leftmostPageIndex + index)}
              >
                {displayNumber}
              </Styled.PaginationButton>
            );
          })}
        </>
      )}
      <Styled.PaginationButton
        type="button"
        onClick={nextPage}
        disabled={!canNextPage}
        title="Go to the next page"
      >
        <Icon icon="angle-right" />
      </Styled.PaginationButton>
      <Styled.PaginationButton
        type="button"
        onClick={() => gotoPage(pageCount - 1)}
        disabled={!canNextPage}
        title="Go to the last page"
      >
        <Icon icon="angle-double-right" />
      </Styled.PaginationButton>
    </Styled.Pagination>
  );
}
