import { DeliveryConfirmation, ReturnLabel } from '../gql/graphql';
import { formatCurrencyNoFractionDigits } from './currency';
import formatWeight from './formatWeight';
import sortDims from './sortDims';

// TODO: a lot of these string types could be string enums
export type ShipmentPreset = {
  id: string;
  title: string;
  packageTypeKey: string;
  packageTypeId?: string;
  dimensionX: number;
  dimensionY: number;
  dimensionZ: number;
  weight: number; // weightInOunces
  deliveryConfirmation: DeliveryConfirmation;
  qualifiesAsMediaMail: boolean;
  insuredValue: number;
  returnLabel: ReturnLabel;
  customsFormEnabled: boolean;
  hazardousMaterialsEnabled: boolean;
};

export type PackageType = {
  id?: string;
  packageTypeKey: string;
  title: string;
};

export default function formatPreset(
  preset: ShipmentPreset,
  packageTypes: PackageType[],
  options = { delimiter: ' - ' },
): string {
  const parts = [];

  if (preset.packageTypeKey === 'Parcel') {
    const dimensions = sortDims([preset.dimensionX, preset.dimensionY, preset.dimensionZ]).filter(
      (d) => d > 0,
    );
    parts.push(`${dimensions.join('x')}"`);
  } else if (preset.packageTypeKey === 'SoftEnvelope') {
    const dimensions = sortDims([preset.dimensionX, preset.dimensionY]);
    parts.push(`${dimensions.join('x')}"`);
  } else {
    const packageType = packageTypes.find((type) => type.id === preset.packageTypeId);
    parts.push(packageType ? packageType.title : preset.packageTypeKey);
  }

  parts.push(formatWeight({ pounds: 0, ounces: preset.weight }));

  if (preset.deliveryConfirmation === 'signature') {
    parts.push('Signature');
  } else if (preset.deliveryConfirmation === 'adult_signature') {
    parts.push('Adult Signature');
  }

  if (preset.insuredValue > 0) {
    const formattedInsuredValue = formatCurrencyNoFractionDigits(preset.insuredValue);
    parts.push(`Insured Value: ${formattedInsuredValue}`);
  }

  if (preset.qualifiesAsMediaMail) {
    parts.push('Qualifies for Media Mail');
  }

  if (preset.returnLabel === 'return') {
    parts.push('Return Labels Only');
  } else if (preset.returnLabel === 'standard_and_return') {
    parts.push('Additional Return Label');
  }

  if (preset.customsFormEnabled) {
    parts.push('Customs Form');
  }

  if (preset.hazardousMaterialsEnabled) {
    parts.push('Hazardous Materials');
  }

  return parts.join(options.delimiter);
}
