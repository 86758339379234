import * as yup from 'yup';
import { DEFAULT_COUNTRY_CODE } from '../../../constants';
import stringToNumber from '../../../utils/stringToNumber';
import { CustomsLineItemSubformValues } from './CustomsLineItemSubform';

// eslint-disable-next-line no-template-curly-in-string
const ITEM_VALUE_MAX_MSG = 'Value must be less than or equal to $${max}';
const HS_TARIFF_REQUIRED_MSG =
  'Required for shipments to the EU, Norway, Switzerland and Military APO/DPO/FPO';

export const INITIAL_CUSTOMS_LINE_ITEM_SUBFORM_VALUES: CustomsLineItemSubformValues = {
  title: '',
  quantity: '',
  itemValue: '',
  weightPounds: '', // string are required for autofill functionality
  weightOunces: '', // string are required for autofill functionality
  hsTariffNumber: '',
  countryCodeOfOrigin: DEFAULT_COUNTRY_CODE,
};

export const customsLineItemValidationSchema = (
  hsTariffNumberRequired: boolean,
  maxWeight: number,
) =>
  yup.object<CustomsLineItemSubformValues>({
    title: yup.string().required().min(2).max(31).customsDescriptionBlacklist(),
    quantity: yup.number().required().min(1).max(999),
    itemValue: yup.number().required().positive().max(2500, ITEM_VALUE_MAX_MSG),
    weightPounds: yup.number().required().min(0).default(0), // TODO allow fractions
    weightOunces: yup.number().required().min(0).default(0),
    combinedWeight: yup
      .mixed<undefined>()
      .when(['weightOunces', 'weightPounds'], (weightOunces, weightPounds) => {
        const ounces: number =
          typeof weightOunces === 'string' ? stringToNumber(weightOunces) : weightOunces;
        const pounds: number =
          typeof weightPounds === 'string' ? stringToNumber(weightPounds) : weightPounds;
        const weightConfig = { ounces, pounds, maxWeight };
        return yup.object().packageWeight(weightConfig);
      }),
    hsTariffNumber: hsTariffNumberRequired
      ? yup.string().required(HS_TARIFF_REQUIRED_MSG).hsCode().hsCodeBlacklist()
      : yup.string().hsCode().hsCodeBlacklist(),
    countryCodeOfOrigin: yup.string().required(),
  });
