import { useMutation } from '@apollo/client';
import styled from '@emotion/styled';
import { ShipmentsPrintModal } from '@ps/printLabels';
import {
  ShipmentRefundModal,
  getShipmentRefundSuccessMessage,
  refundShipmentMutation,
} from '@ps/refundLabels';
import type { Row as TableRow } from '@tanstack/react-table';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { addFlashMessage, setFlashMessage } from '../../apollo/cache/flashMessages';
import IntercomArticleLink from '../../components/IntercomArticleLink';
import ConnectedDataGrid from '../../components/dataGrid/ConnectedDataGrid';
import { DataGridImperativeHandleRef } from '../../components/dataGrid/DataGrid';
import Button from '../../components/form/Button';
import ProgressButton from '../../components/form/ProgressButton';
import { Col, PageContainer, Row } from '../../components/layout/Grid';
import PageTitle from '../../components/layout/PageTitle';
import { MEDIA_QUERY } from '../../styles/breakpoints';
import { SPACING } from '../../styles/spacing';
import environment from '../../utils/environment';
import pluralize from '../../utils/pluralize';
import mapFilterTemplateNameToFilters from '../utils/mapFilterTemplateNameToFilters';

const Styled = {
  PageContainer: styled(PageContainer)`
    header {
      @media (max-width: ${MEDIA_QUERY.mdMin}) {
        padding-bottom: ${SPACING.md};
      }
    }
  `,
  Subtitle: styled.p`
    padding-bottom: ${SPACING.md};
  `,
};

type OriginalRow = {
  id: string;
  title: string;
  shipment_id: string;
  batch_id: string;
  created_at: string;
  carrier_title: string;
  instant_refund: boolean;
};

type ShipmentsPageProps = {
  bridgeFilterTemplateName?: string;
  searchFromLegacyShipOverview?: string;
};

function ShipmentsPage({
  bridgeFilterTemplateName,
  searchFromLegacyShipOverview,
}: ShipmentsPageProps) {
  const [refundShipment, { loading: loadingRefundShipment }] = useMutation(refundShipmentMutation);
  const [isExporting, setIsExporting] = useState(false);
  const [refundModalOpen, setRefundModalOpen] = useState(false);
  const [showPrintModal, setShowPrintModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const shipment = useRef<OriginalRow>();
  const imperativeHandleRef = useRef<DataGridImperativeHandleRef>(null);
  const navigate = useNavigate();

  const { filterTemplateName } = useParams<'filterTemplateName'>();
  const templateName = bridgeFilterTemplateName ?? filterTemplateName ?? '';

  const initialSearchTerm =
    templateName.match(/^SEARCH_TERM_(.*)/)?.[1] ?? searchFromLegacyShipOverview;
  const initialFilters = mapFilterTemplateNameToFilters(templateName);

  const viewShipment = (
    row: TableRow<{ id: string; batch_id: string }>,
    event?: { ctrlKey: boolean; metaKey: boolean },
  ) => {
    const shipmentId = row.original.id;
    const batchId = row.original.batch_id;

    if (event && (event.ctrlKey || event.metaKey)) {
      // open in new tab - won't work in web-client, only via bridge
      window.open(`${window.location.origin}/ship/shipment?id=${shipmentId}`);
      return;
    }

    if (environment.isBridge()) {
      // we need to use window for the bridged version to work correctly
      window.location.href = `${window.location.origin}/ship/shipment?id=${shipmentId}`;
    } else {
      navigate(`/batch/${batchId}/shipment/${shipmentId}`);
    }
  };

  const handleRefundButton = (row: TableRow<unknown>) => {
    shipment.current = row.original as OriginalRow;
  };

  const handleRefund = async () => {
    if (!shipment.current) return;
    const shipmentId = shipment.current.id;
    try {
      await refundShipment({
        variables: {
          shipmentId,
        },
      });

      addFlashMessage(
        getShipmentRefundSuccessMessage(
          shipment.current.instant_refund,
          shipment.current.carrier_title === 'UPS',
        ),
        'success',
      );
      imperativeHandleRef.current?.refetchData(); // call the rows fetcher from within the grid
    } catch (error: unknown) {
      if (error instanceof Error) {
        setFlashMessage(error.message, 'danger');
      }
    }
    setRefundModalOpen(false);
  };

  return (
    <Styled.PageContainer>
      <ShipmentsPrintModal
        open={showPrintModal}
        onClose={() => {
          setShowPrintModal(false);
          imperativeHandleRef.current?.refetchData(); // call the rows fetcher from within the grid
        }}
        shipmentIds={selectedRows}
      />
      <ShipmentRefundModal
        open={refundModalOpen}
        onCancel={() => setRefundModalOpen(false)}
        onConfirm={handleRefund}
        confirmationButtonProgress={loadingRefundShipment}
        canInstantRefundShipment={shipment.current?.instant_refund ?? false}
        isUpsShipment={shipment.current?.carrier_title === 'UPS'}
      />
      <PageTitle>Shipments</PageTitle>
      <Styled.Subtitle>
        Need a guide to the grid? Check{' '}
        <IntercomArticleLink href="https://support.pirateship.com/en/articles/4143612-can-i-export-a-report-of-my-shipment-history">
          this
        </IntercomArticleLink>{' '}
        out 🤘
      </Styled.Subtitle>
      <Row>
        <Col md={12}>
          <ConnectedDataGrid
            imperativeHandleRef={imperativeHandleRef}
            queryName="shipmentReport"
            queryOptions={{ fetchPolicy: 'cache-first' }}
            initialSearchTerm={initialSearchTerm}
            initialColumnFilters={initialFilters}
            rowSelectEnabled
            useCheckableIds
            onSelectedRowsChange={setSelectedRows}
            rowActionHandlers={{
              view: viewShipment,
              refund: (row) => {
                setRefundModalOpen(true);
                handleRefundButton(row);
              },
            }}
            rightSideSlot={
              <Col sm={12} md={6} key="actionButtons">
                <Row>
                  <Col xs={12} sm={6} spaceBelow>
                    <ProgressButton
                      progress={isExporting}
                      disabled={isExporting}
                      fullWidth
                      size="medium"
                      variant="info"
                      onClick={async () => {
                        setIsExporting(true);
                        await imperativeHandleRef.current?.exportData();
                        setIsExporting(false);
                      }}
                    >
                      Export Data
                    </ProgressButton>
                  </Col>
                  <Col xs={12} sm={6} spaceBelow>
                    <Button
                      disabled={selectedRows.length === 0}
                      fullWidth
                      variant="success"
                      onClick={selectedRows.length > 0 ? () => setShowPrintModal(true) : () => null}
                    >
                      {pluralize('Print Selected [Label|Labels]', selectedRows.length)}
                    </Button>
                  </Col>
                </Row>
              </Col>
            }
          />
        </Col>
      </Row>
    </Styled.PageContainer>
  );
}

export default ShipmentsPage;
