import styled from '@emotion/styled';
import { BORDER_RADIUS, BORDER_WIDTH } from '../../styles/borders';
import { COLOR, GREYSCALE } from '../../styles/colors';
import { SPACING } from '../../styles/spacing';
import { TYPOGRAPHY } from '../../styles/typography';
import carrierKeyToIconUrl from '../../utils/carrierKeyToIconUrl';
import { formatCurrency } from '../../utils/currency';
import pluralize from '../../utils/pluralize';
import roundFloat from '../../utils/roundFloat';
import { RateSummary } from '../form/RateSummarySelect';

const Styled = {
  Wrapper: styled.div`
    display: flex;
    align-content: center;
    justify-content: flex-end;
  `,
  Upsell: styled.button`
    height: 20px;
    box-sizing: content-box;
    cursor: pointer;
    padding: ${SPACING.sm};
    border: solid;
    border-color: ${GREYSCALE.grey30};
    border-width: ${BORDER_WIDTH.none} ${BORDER_WIDTH.xs} ${BORDER_WIDTH.xs} ${BORDER_WIDTH.xs};
    border-radius: ${BORDER_RADIUS.none} ${BORDER_RADIUS.none} ${BORDER_RADIUS.xs}
      ${BORDER_RADIUS.xs};
    background-color: ${GREYSCALE.grey10};
    font-size: ${TYPOGRAPHY.fontSize.xs};
    font-weight: ${TYPOGRAPHY.fontWeight.regular};
    &:hover {
      background-color: ${COLOR.lightGreen};
    }
    display: block;
    align-items: center;
    white-space: pre-wrap;
  `,
  CarrierIcon: styled.img`
    height: 18px;
    padding-right: ${SPACING.xs};
    vertical-align: text-bottom;
  `,
  FakeLink: styled.span`
    color: ${COLOR.blue};
    padding-right: ${SPACING.xs};
  `,
  Savings: styled.span`
    font-weight: ${TYPOGRAPHY.fontWeight.bold};
    padding-right: ${SPACING.xs};
  `,
};

type RateGroupUpsellProps = {
  upsellRateSummary: RateSummary;
  selectedTotalPrice: number;
  onSwitch: (uniqueId: string) => void;
};

function RateGroupUpsell({
  upsellRateSummary,
  selectedTotalPrice,
  onSwitch,
}: RateGroupUpsellProps) {
  const { carrier, firstMailClass, totalPrice, uniqueId } = upsellRateSummary;
  const key =
    firstMailClass.mailClassKey === 'FirstGlobalRate' ||
    firstMailClass.mailClassKey === 'SimpleExportRate'
      ? 'ps'
      : carrier.carrierKey;

  const savings = roundFloat(Math.abs(selectedTotalPrice - totalPrice), 2);
  const savingsCents = Math.round(savings * 100);
  const renderSavings = () =>
    savings >= 1 ? formatCurrency(savings) : pluralize(`%d [cent|cents]`, savingsCents);

  const carrierKeyToTitle = (value: string) => {
    switch (value) {
      case 'usps':
        return 'USPS';
      case 'ups':
        return 'UPS®';
      case 'ps':
        return 'Simple Export Rate®';
      default:
        return '';
    }
  };

  return (
    <Styled.Wrapper>
      <Styled.Upsell
        role="button"
        data-testid="rategroup-upsell"
        data-dd-action-name={`Switch to ${carrierKeyToTitle(key)}`}
        onClick={() => {
          onSwitch(uniqueId);
        }}
      >
        <Styled.CarrierIcon src={carrierKeyToIconUrl(key, '')} />{' '}
        <Styled.FakeLink>Switch to {carrierKeyToTitle(key)}</Styled.FakeLink>for{' '}
        <strong>{renderSavings()} </strong>
        {selectedTotalPrice > totalPrice ? 'less' : 'more'}
      </Styled.Upsell>
    </Styled.Wrapper>
  );
}

export default RateGroupUpsell;
