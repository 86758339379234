import { ShipmentStatusSummary } from '../../gql/graphql';
import { COLOR } from '../../styles/colors';
import Section, { SectionEntries } from './Section';

type ShipmentSummarySectionProps = {
  hideIf?: boolean;
  shipmentStatusSummary: Partial<ShipmentStatusSummary>;
};

function ShipmentSummarySection({ hideIf, shipmentStatusSummary }: ShipmentSummarySectionProps) {
  const renderValue = (key: keyof ShipmentStatusSummary) => {
    const value = Number(shipmentStatusSummary[key]);
    if (value > 0) {
      return value;
    }
    return null;
  };

  const entries: SectionEntries = [
    ['Ready to Print', renderValue('readyToPrintCount')],
    ['Ready to Ship', renderValue('readyToShipCount')],
    ['In Transit', renderValue('inTransitCount')],
    ['Refunded', renderValue('refundedCount'), { color: COLOR.red }],
    ['Refund Pending', renderValue('refundPendingCount'), { color: COLOR.red }],
    ['Delivered', renderValue('deliveredCount'), { color: COLOR.green }],
    ['Error', renderValue('errorCount'), { color: COLOR.red }],
  ];

  return <Section title="Shipment Summary" hideIf={hideIf} entries={entries} />;
}

export default ShipmentSummarySection;
