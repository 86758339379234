import { ReactNode } from 'react';
import { BottomRowActions } from '../form/BottomRowActions';
import { Col, Row } from '../layout/Grid';
import PageLoading from '../loading/PageLoading';
import Modal from './Modal';

export type ConfirmationModalProps = {
  open: boolean;
  title?: string;
  loading?: boolean;
  message?: string | null;
  children?: ReactNode;
  onCancel: () => void;
  onConfirm: () => void;
  confirmationType?: 'destructive' | 'default';
  cancelButtonLabel?: string;
  confirmationButtonLabel?: string;
  confirmationButtonProgress?: boolean;
};

export default function ConfirmationModal({
  open,
  title,
  loading,
  message,
  onCancel,
  onConfirm,
  confirmationType = 'default',
  cancelButtonLabel = 'Cancel',
  confirmationButtonLabel = 'Continue',
  confirmationButtonProgress = false,
  children,
}: ConfirmationModalProps) {
  return (
    <Modal title={title} width={600} open={open} onClose={onCancel}>
      {({ closeDialogIfClosable }) => (
        <>
          {loading ? (
            <PageLoading height="200px" />
          ) : (
            <Row>
              {message && <Col md={12}>{message}</Col>}
              {children && <Col md={12}>{children}</Col>}
            </Row>
          )}
          <BottomRowActions
            primaryAction={{
              title: confirmationButtonLabel,
              type: 'submit',
              variant: confirmationType === 'default' ? 'primary' : 'danger',
              onClick: onConfirm,
              progress: confirmationButtonProgress,
              disabled: loading || confirmationButtonProgress,
            }}
            secondaryActions={[
              {
                title: cancelButtonLabel,
                variant: confirmationType === 'default' ? 'danger' : 'primary',
                disabled: confirmationButtonProgress,
                onClick: () => {
                  closeDialogIfClosable();
                },
              },
            ]}
          />
        </>
      )}
    </Modal>
  );
}
