import { LazyQueryHookOptions, QueryHookOptions, useLazyQuery, useQuery } from '@apollo/client';
import { gql } from '../../gql';
import { NumbersQuery, NumbersQueryVariables } from '../../gql/graphql';

export const numbersQuery = gql(/* GraphQL */ `
  query NumbersQuery($datetimeRange: DatetimeRangeInput!) {
    numbers(datetimeRange: $datetimeRange) {
      carrierAdjustmentsAmount
      carrierAdjustmentsCount
      payments
      pendingNumbers {
        returns {
          amount
          count
        }
        refunds {
          amount
          count
        }
        paymentsTotalAmount
      }
      netLabelCount
      netLabelTotal
      netLabelAverage
      netLabelAverageDom
      netLabelAverageInt
    }
  }
`);

export const useNumbersQuery = (options: QueryHookOptions<NumbersQuery, NumbersQueryVariables>) =>
  useQuery(numbersQuery, options);

export const useNumbersLazyQuery = (
  options: LazyQueryHookOptions<NumbersQuery, NumbersQueryVariables>,
) => useLazyQuery(numbersQuery, options);
