import { useQuery } from '@apollo/client';
import { gql } from '../../gql';

export const billingPageQuery = gql(/* GraphQL */ `
  query BillingPageQuery {
    chargeAmountOptions
    user {
      id
      email
    }
    company {
      id
      accountBalance
      settings {
        defaultDestinationCountryCode
        defaultPaymentSourceId
        defaultChargeAmount
      }
      paymentSources {
        ...PaymentSourceFragment
      }
      activePaymentMethods
    }
    paymentApiMethods {
      ...PaymentApiMethodFragment
    }
  }
`);

export const useBillingPageQuery = () => useQuery(billingPageQuery, { partialRefetch: true });
