import styled from '@emotion/styled';
import type { Row as TableRow } from '@tanstack/react-table';
import { useMemo, useRef, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useAutoRefundedShipmentsSummaryQuery } from '../../../operations/queries/autoRefundedShipments';
import { SPACING } from '../../../styles/spacing';
import pluralize from '../../../utils/pluralize';
import ConnectedDataGrid from '../../dataGrid/ConnectedDataGrid';
import { DataGridImperativeHandleRef } from '../../dataGrid/DataGrid';
import ProgressButton from '../../form/ProgressButton';
import { Col, PageContainer, Row } from '../../layout/Grid';
import PageHeader from '../../layout/PageHeader';
import PageSectionDescription from '../../layout/PageSectionDescription';

type OriginalRow = {
  id: string;
  title: string;
  shipment_id: string;
  batch_id: string;
  created_at: string;
  carrier_title: string;
  instant_refund: boolean;
};

const Styled = {
  PageSectionDescription: styled(PageSectionDescription)`
    margin-top: calc(${SPACING.lg} - ${SPACING.xxl});
    padding-bottom: ${SPACING.xxl};
  `,
};

function RefundsPage() {
  const { data, loading } = useAutoRefundedShipmentsSummaryQuery();
  const [isExporting, setIsExporting] = useState(false);
  const imperativeHandleRef = useRef<DataGridImperativeHandleRef>(null);

  const autoRefunded = useMemo(() => {
    if (!loading && data) {
      const {
        autoRefundedShipmentsSummary: { amount, count },
      } = data;
      return { amount, count };
    }
    return { count: 0, amount: 0 };
  }, [data, loading]);

  const makeLinkToShipment = (row: TableRow<OriginalRow>): string => {
    const id = row.original.id;
    const batch = row.original.batch_id;
    // if (event.target.tagName.toLowerCase() === 'a') return ''; TODO: work out the purpose of this and why it was implemented
    return `/batch/${batch}/shipment/${id}`;
  };

  return (
    <PageContainer>
      <PageHeader title="Refund Report" />
      {!loading && autoRefunded.count > 0 && (
        <Styled.PageSectionDescription>
          Pirate Ship has saved you{' '}
          <NumberFormat
            value={autoRefunded.amount}
            displayType="text"
            prefix="$"
            decimalScale={2}
            fixedDecimalScale
          />{' '}
          {pluralize(`with %d Auto [Refund|Refunds] 👍`, autoRefunded.count)}
        </Styled.PageSectionDescription>
      )}
      <Row>
        <Col md={12}>
          <ConnectedDataGrid
            imperativeHandleRef={imperativeHandleRef}
            queryName="refundReport"
            onRowClick={makeLinkToShipment}
            rightSideSlot={
              <Col xs={12} md={3} spaceBelow>
                <ProgressButton
                  progress={isExporting}
                  disabled={isExporting}
                  fullWidth
                  size="medium"
                  variant="info"
                  onClick={async () => {
                    setIsExporting(true);
                    await imperativeHandleRef.current?.exportData();
                    setIsExporting(false);
                  }}
                >
                  Export
                </ProgressButton>
              </Col>
            }
          />
        </Col>
      </Row>
    </PageContainer>
  );
}

export default RefundsPage;
