import { ApolloError, useMutation } from '@apollo/client';
import styled from '@emotion/styled';
import {
  mapBatchToSingleShipmentFormValues,
  singleShipmentFormValueStorage,
} from '@ps/shipmentLead';
import { Form, Formik, FormikProps } from 'formik';
import isEmpty from 'lodash.isempty';
import isEqual from 'lodash.isequal';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { dispatch } from 'use-bus';
import * as yup from 'yup';
import { setFlashMessage } from '../../apollo/cache/flashMessages';
import RecipientAddress from '../../components/RecipientAddress';
import BatchTitle from '../../components/form/BatchTitle';
import FormEffect from '../../components/form/FormEffect';
import Label from '../../components/form/Label';
import { Col, PageContainer, Row } from '../../components/layout/Grid';
import ShipmentFlowPageFooter from '../../components/layout/ShipmentFlowPageFooter';
import PageLoading from '../../components/loading/PageLoading';
import AddPaymentMethodModal from '../../components/modals/AddPaymentMethodModal';
import ChangePaymentSourceModal from '../../components/modals/ChangePaymentSourceModal';
import PlaidRelinkModal from '../../components/modals/PlaidRelinkModal';
import PlaidRelinkingHelpModal from '../../components/modals/PlaidRelinkingHelpModal';
import UpsAccountModals from '../../components/modals/UpsAccountModals';
import UspsAddressCorrectionModal from '../../components/modals/UspsAddressCorrectionModal';
import { PageContainerProps } from '../../components/pages/settings/BillingPage';
import ShipmentDetailsBox from '../../components/shipmentDetailsBox/BuyPageShipmentDetailsBox';
import FinishPurchaseSubform, {
  ShipDateSettingRow,
  calculateMinimumCharge,
  finishPurchaseSubformInitialValues,
  finishPurchaseValidationSchema,
  shipDateSettingRowInitialValues,
  shipDateSettingRowValidationSchema,
  type FinishPurchaseSubformValues,
  type ShipDateSettingRowValues,
} from '../../components/subforms/FinishPurchaseSubform';
import {
  Challenge,
  PaymentMethodModalVia,
  PaymentMethodPromotion,
  STORAGE_KEYS,
  SubmitStep,
  UiEvents,
} from '../../constants';
import { BatchQuery, PaymentSource, RateGroupKey, RateGroupTrait } from '../../gql/graphql';
import useDateInUserTimezone from '../../hooks/useDateInUserTimezone';
import useFeature from '../../hooks/useFeature';
import useLogger from '../../hooks/useLogger';
import useMappedCountries from '../../hooks/useMappedCountries';
import useNavigateOrHref from '../../hooks/useNavigateOrHref';
import usePayPal from '../../hooks/usePayPal';
import { useCreateUspsMerchantAccountMutation } from '../../operations/mutations/createUspsMerchant';
import { useDeleteBatchMutation } from '../../operations/mutations/deleteBatch.mutation';
import { useUpdateBatchTitleMutation } from '../../operations/mutations/updateBatchTitle';
import { getItem, removeItem, setItem } from '../../services/storage';
import { SPACING } from '../../styles/spacing';
import blockForwardingProps from '../../utils/blockForwardingProps';
import convertCurrencyToNumber from '../../utils/convertCurrencyToNumber';
import { formatCurrencyNoUnit } from '../../utils/currency';
import environment from '../../utils/environment';
import getSelectedRateSummaries from '../../utils/getSelectedRateSummaries';
import pluralize from '../../utils/pluralize';
import roundFloat from '../../utils/roundFloat';
import triggerGoogleTagManagerCustomEvent from '../../utils/triggerGoogleTagManagerCustomEvent';
import NotifyRecipientsSettingRow, {
  NotifyRecipientsSettingRowValues,
} from '../components/NotifyRecipientsSettingRow';
import RateGroupsSubform, { RateGroupsSubformValues } from '../components/RateGroupsSubform';
import {
  getDefaultEmailTemplate,
  getNotifyRecipientsDate,
  notifyRecipientsSettingRowValidationSchema,
} from '../components/notifyRecipientsSettingRowUtils';
import { useBatchQuery } from '../operations/batch.query';
import buyBatchMutation from '../operations/buyBatch.mutation';
import rerateBatchMutation from '../operations/rerateBatch.mutation';
import { useSetBatchToNewMutation } from '../operations/setBatchToNew.mutation';
import logBuyEventToDatadog from '../utils/buyPageTracking';
import getFormattedRateSelectionInput from '../utils/formatRateSelectionInput';
import getAvailableShipDates from '../utils/getAvailableShipDates';
import getRateGroupsInitialState from '../utils/rateGroupInitialState';

const Styled = {
  // to overcome legacy print styles in bridge
  PageContainer: styled(PageContainer, blockForwardingProps('isModalOpen'))<PageContainerProps>`
    @media print {
      @page {
        size: letter portrait;
        margin: 0.5in;
      }
      ${(props) => (props.isModalOpen ? 'display: none' : '')};
    }
  `,
  TitleWrapper: styled.div`
    margin-bottom: ${SPACING.xxl};
  `,
};

export type BuyFormValues = {
  submitStep: SubmitStep;
  summaryIds: RateGroupsSubformValues;
  shipDate: ShipDateSettingRowValues;
  notifyRecipients: NotifyRecipientsSettingRowValues;
  finishPurchase: FinishPurchaseSubformValues;
};

type RateGroup = BatchQuery['batch']['rateGroups'][0];

// determine what carriers are in the selected rate groups in the form
function determineSelectedCarriers(
  selectedSummaryIds: Record<string, string>,
  rateGroups: ReadonlyArray<RateGroup>,
): { ups: boolean; usps: boolean } {
  if (selectedSummaryIds && !isEmpty(selectedSummaryIds)) {
    const selectedSummaries = getSelectedRateSummaries(selectedSummaryIds, rateGroups);
    return {
      ups: selectedSummaries.some((summary) => summary.carrier.carrierKey === 'ups'),
      usps: selectedSummaries.some((summary) => summary.carrier.carrierKey === 'usps'),
    };
  }
  return { ups: false, usps: false };
}

function isReturnLabelsOnly(groupKey: RateGroupKey): boolean {
  const directionLayer = groupKey.traits.find(
    (trait: RateGroupTrait) => trait.layer === 'Direction',
  );

  return directionLayer?.value === 'RETURN';
}

// find the total cost of all selected labels
function getTotalCost(
  selectedSummaryIds: Record<string, string>,
  rateGroups: ReadonlyArray<RateGroup>,
): number {
  if (selectedSummaryIds && !isEmpty(selectedSummaryIds)) {
    const selectedSummaries = getSelectedRateSummaries(selectedSummaryIds, rateGroups);
    // Only add the label cost to the total price if it is NOT a return label, we don't charge for these at this point
    const costRelatedSummaries = selectedSummaries.filter(
      (summary) => !isReturnLabelsOnly(summary.groupKey),
    );
    return roundFloat(
      costRelatedSummaries.reduce((sum, summary) => sum + summary.totalPrice, 0),
      2,
    );
  }
  return 0;
}

function getInitialSelectedSummaryIds(
  rateGroups: BatchQuery['batch']['rateGroups'],
  rateGroupSortOrder: BatchQuery['user']['rateGroupSortOrder'],
  forwardedMailClassKey: string | undefined,
) {
  const preselects = getItem(STORAGE_KEYS.preselectedSummaryIdsStorageKey);

  // after getting the initial values, set the Summary IDs to these values
  // then unset the local storage variable because we don't need it anymore!
  if (preselects) {
    removeItem(STORAGE_KEYS.preselectedSummaryIdsStorageKey);
    return preselects;
  }
  // if coming from inApp calc -> single shipment -> here, preselect the passed
  // forwarded mail class key
  if (forwardedMailClassKey && rateGroups.length === 1) {
    const summary = rateGroups[0].rateSummaries.find(
      (rs) => rs.firstMailClass.mailClassKey === forwardedMailClassKey,
    );
    if (summary) {
      return { [rateGroups[0].groupKey.string]: summary.uniqueId };
    }
  }
  // otherwise, get the initial state the standard way
  return getRateGroupsInitialState(rateGroups, rateGroupSortOrder);
}

export type BuyPageProps = {
  entityId?: string; // passed if we are in the bridge
  forwardedMailClassKey?: string; // passed if we are in the bridge
};

export default function BuyPage({ entityId, forwardedMailClassKey }: BuyPageProps) {
  const navigateOrHref = useNavigateOrHref();
  const navigate = useNavigate();
  const logger = useLogger();
  const [searchParams] = useSearchParams();
  const { batchId } = useParams<'batchId'>();
  const id = entityId || batchId || '';
  const [showPaymentMethodModal, setShowPaymentMethodModal] = useState(false);
  const [showUpsAccountModal, setShowUpsAccountModal] = useState(false);
  const [showUspsAddressCorrectionModal, setShowUspsAddressCorrectionModal] = useState(false);
  const formikRef = useRef<FormikProps<BuyFormValues> | null>(null);
  const [buyInProgress, setBuyInProgress] = useState(false);
  const { formatDate, loading: offsetLoading, createDate } = useDateInUserTimezone();
  const isReactSingleShipmentFormPageEnabled = useFeature('Pp.feature.singleShipmentFormPage');

  const [paymentMethodModalVia, setPaymentMethodModalVia] =
    useState<PaymentMethodModalVia>('submit');
  const [paymentMethodPromotion, setPaymentMethodPromotion] =
    useState<PaymentMethodPromotion>('none');
  const [backLoading, setBackLoading] = useState(false); // used to block UI when leaving the bridged buy page via a back/cancel link

  const [rerateCheckDone, setRerateCheckDone] = useState(false);
  const [rerateInProgress, setRerateInProgress] = useState(false);
  const [plaidInProgress, setPlaidInProgress] = useState(false);
  const plaidPromotionExperimentEnabled = useFeature('Plaid.promotion.experiment');
  const enablePlaidRelinkingHelpModal = useFeature('Plaid.relinking.help.modal');
  const [failedPaymentSourceId, setFailedPaymentSourceId] = useState<string | null>(null);
  const [plaidRelinkingHelpModalOpen, setPlaidRelinkingHelpModalOpen] = useState(false);
  const [plaidRelinkingModalOpen, setPlaidRelinkingModalOpen] = useState(false);
  const [plaidPaymentSourceId, setPlaidPaymentSourceId] = useState<string>('');
  const [rerateBatch] = useMutation(rerateBatchMutation);
  const [updateBatchTitle] = useUpdateBatchTitleMutation();
  const [createUspsMerchantAccount] = useCreateUspsMerchantAccountMutation();
  const [buyBatch] = useMutation(buyBatchMutation);
  const [deleteBatch] = useDeleteBatchMutation();
  const [setBatchToNew] = useSetBatchToNewMutation();

  // Tracked as state to be able to force a re-render on summaryIds change
  const [totalCost, setTotalCost] = useState<number>();

  // Main data fetching
  const { data } = useBatchQuery({
    variables: {
      id,
    },
    // Required to have onCompleted execute on refetches
    notifyOnNetworkStatusChange: true,
    onCompleted: ({
      batch: {
        shipmentStatusSummary: { errorCount },
        status,
        shipDatePossibleNow,
        rateGroups,
      },
      user: { rateGroupSortOrder },
    }) => {
      // Show flash errors if our batch returns errors
      if (errorCount > 0) {
        setFlashMessage(
          pluralize(
            `Note: [One address|%d addresses] will not be included in the purchase due to errors. You can correct and re-import the [address|addresses] after buying.`,
            errorCount,
          ),
          'info',
        );
      }

      // Set total cost local state
      if (totalCost === undefined) {
        setTotalCost(
          getTotalCost(
            getInitialSelectedSummaryIds(rateGroups, rateGroupSortOrder, forwardedMailClassKey),
            rateGroups,
          ),
        );
      }

      // Navigate to the batch page if the batch is ready to print
      if (status === 'READY_TO_PRINT') {
        navigate(`/batch/${id}`);
        return;
      }

      // Rerate automatically if the ship date is not possible now OR we are missing rate data.
      // Don't re-rate if a process is still running.
      if (status === 'IDLE' && (!shipDatePossibleNow || rateGroups.length === 0)) {
        performRerate(id, null);
      } else {
        setRerateCheckDone(true);
      }
    },
  });

  async function performRerate(
    requestedBatchId: string,
    requestedShipDate: string | null,
    selectedSummaryIds?: Record<string, string>,
  ) {
    // Mark rerate started
    setRerateInProgress(true);
    try {
      const result = await rerateBatch({
        variables: {
          id: requestedBatchId,
          shipDate: requestedShipDate,
        },
      });

      // In case the backend returns with another ship date (auto-advance), update the form
      if (result.data) {
        formikRef.current?.setFieldValue('shipDate.date', result.data.rerateBatch.shipDate);
      }

      // Save selected summary ids for reloading the page after rerating
      if (environment.isBridge() && selectedSummaryIds) {
        setItem(STORAGE_KEYS.preselectedSummaryIdsStorageKey, selectedSummaryIds);
      }
    } catch (error) {
      if (error instanceof ApolloError) {
        error.graphQLErrors.forEach((err) => {
          setFlashMessage(err.message, 'danger');
        });
      }

      // Mark rerate complete
    } finally {
      setRerateInProgress(false);
    }
  }

  async function performBuy(values: BuyFormValues) {
    const { shipDate, notifyRecipients, finishPurchase, summaryIds } = values;

    if (
      !data ||
      // Payment source is required
      finishPurchase.paymentSourceId === null ||
      // RateGroups not initialized yet
      !summaryIds ||
      isEmpty(summaryIds)
    ) {
      setBuyInProgress(false);
      setFlashMessage('Something has gone wrong', 'danger');
      return;
    }

    try {
      // Implciticly refreshes the running process state
      await buyBatch({
        variables: {
          id: data.batch.id,
          rateSelection: getFormattedRateSelectionInput(summaryIds, data.batch),
          paymentSourceId: finishPurchase.paymentSourceId,
          shipDate: shipDate.date,
          totalCharge: convertCurrencyToNumber(finishPurchase.totalCharge),
          mailTemplateId: notifyRecipients.enabled ? notifyRecipients.emailTemplate : null,
          notifyRecipientsDate: getNotifyRecipientsDate(notifyRecipients, formatDate, createDate),
        },

        context: { errorHandled: true },
      });

      // Register event for challenge completion
      dispatch(Challenge.ShipPackage);

      // Track buy event
      logBuyEventToDatadog(values.summaryIds, data.batch);

      // Flag users with a 'batch' in intercom
      if (typeof window.Intercom !== 'undefined') {
        window.Intercom('trackEvent', 'batch-purchased');
      }

      // If in the bridge in the legacy shipment flow, reload the page and rely on the backend to show the loading screen
      if (environment.isBridge() && searchParams.get('id') !== null) {
        window.history.scrollRestoration = 'manual';
        window.location.reload();
      }
    } catch (error) {
      if (error instanceof ApolloError) {
        error.graphQLErrors.forEach(({ extensions, message }) => {
          if (environment.isBridge() && extensions?.redirect) {
            window.location.reload();
            return;
          }

          if (extensions?.failureReason === 'PLAID_UNLINKED_ACCOUNT') {
            setPlaidPaymentSourceId(finishPurchase.paymentSourceId);
            setPlaidInProgress(true);
            if (enablePlaidRelinkingHelpModal) {
              setPlaidRelinkingHelpModalOpen(true);
            } else {
              setPlaidRelinkingModalOpen(true);
            }
            return;
          }

          if (
            extensions?.failureReason === 'PAYMENT_SOURCE_DECLINED' ||
            extensions?.failureReason === 'PLAID_SERVER_UNREACHABLE'
          ) {
            dispatch(UiEvents.APirateIsSad);
            setFailedPaymentSourceId(finishPurchase.paymentSourceId);
            return;
          }

          // a reload of the page will trigger the TermsModal in SideBar.tsx to show
          if (extensions?.failureReason === 'USER_DID_NOT_ACCEPT_TERMS') {
            window.history.scrollRestoration = 'manual';
            window.location.reload();
            return;
          }

          setFlashMessage(message, 'danger');
        });
      }

      setBuyInProgress(false);
    }
  }

  // pre-loading PayPal so the button appears much faster on AddPaymentMethodModal
  const getPaypalApiKey = () => {
    if (data?.company.paymentSources && data?.company.paymentSources.length > 0) {
      return undefined;
    }

    return data?.paymentApiMethods.find((e) => e.code === 'paypal')?.apiKey;
  };
  usePayPal(getPaypalApiKey());

  const hasMultipleShipments = data && data.batch.numShipments > 1;

  const countryMap = useMappedCountries();

  useEffect(() => {
    // TODO remove useEffects
    if (
      plaidPromotionExperimentEnabled &&
      !data?.company?.hasAnyPlaidPaymentSource &&
      (data?.company?.paymentSources.length ?? 0) > 0
    ) {
      setPaymentMethodPromotion('plaidThreePercent');
    }
  }, [
    plaidPromotionExperimentEnabled,
    data?.company?.hasAnyPlaidPaymentSource,
    data?.company?.paymentSources,
  ]);

  // check everything before performing buy
  const onSubmit = async (values: BuyFormValues) => {
    if (!data) return;
    setBuyInProgress(true);

    const userWantsGroundSaver = getSelectedRateSummaries(
      values.summaryIds,
      data.batch.rateGroups,
    ).some((r) => r.firstMailClass.mailClassKey === '93'); // ("93" is the UPS Ground Saver mailClassKey)
    const userHasSelected = determineSelectedCarriers(values.summaryIds, data.batch.rateGroups);
    const uspsMerchantAccount = data.company.merchantAccounts.find(
      ({ carrierKey }) => carrierKey === 'usps',
    );
    const upsMerchantAccount = data.company.merchantAccounts.find(
      ({ carrierKey }) => carrierKey === 'ups',
    );

    // 1. Show payment source modal if the company does not have a payment source yet
    if (data.company.paymentSources.length === 0 && values.submitStep < SubmitStep.PaymentGood) {
      setPaymentMethodModalVia('submit'); // inform modal what opened it
      setShowPaymentMethodModal(true);
      return;
    }

    // 2. Handle USPS account creation and possible address correction
    if (userHasSelected.usps && values.submitStep < SubmitStep.UspsGood) {
      // 2a. User has no USPS account, make account for them now and continue
      if (!uspsMerchantAccount || uspsMerchantAccount.accountStatus === 'NOT_TRANSMITTED') {
        const { data: createUspsMerchantAccountData } = await createUspsMerchantAccount();
        // 2b. Something is wrong with the billing address, show the USPS address correction modal
        if (createUspsMerchantAccountData?.createUspsMerchantAccount.errorMessage) {
          setShowUspsAddressCorrectionModal(true);
          return;
        }
      }
      // 2c: User has previously tried but recieved a transmission error, show the USPS address correction modal
      if (uspsMerchantAccount?.accountStatus === 'TRANSMISSION_ERROR') {
        setShowUspsAddressCorrectionModal(true);
        return;
      }
    }

    // 3. Show UPS first purchase modal if needed
    if (userHasSelected.ups && values.submitStep < SubmitStep.UpsGood) {
      // 3a. User has no UPS account, show the modal with "First Label" headline
      if (!upsMerchantAccount || upsMerchantAccount.accountStatus !== 'OK') {
        setShowUpsAccountModal(true);
        return;
      }
      // 3b. User has outdated terms and wants ground saver (so they need to update their terms for this)
      if (upsMerchantAccount.termsAndConditionsLastAcceptedAt === null && userWantsGroundSaver) {
        setShowUpsAccountModal(true);
        return;
      }
    }

    // 4. Buy label(s)!
    performBuy(values);
  };

  // Check if everything is set up so that we can iniitate the form
  if (
    data === undefined ||
    totalCost === undefined ||
    backLoading ||
    offsetLoading ||
    !rerateCheckDone
  ) {
    return <PageLoading />;
  }

  const isCustomsFormRequired = data.batch.customsFormRequired;
  const is2x7LabelSize = data.batch.labelSize === 'SIZE_2x7';

  const availableShipDates = getAvailableShipDates(data.batch.rateGroups);

  const validationSchema = yup.object<BuyFormValues>({
    submitStep: yup.number().required(),
    summaryIds: yup.object<RateGroupsSubformValues>().required(),
    shipDate: shipDateSettingRowValidationSchema().required(),
    notifyRecipients: notifyRecipientsSettingRowValidationSchema().required(),
    finishPurchase: finishPurchaseValidationSchema({
      accountBalance: data.company.accountBalance,
      totalCost,
    }).required(),
  });

  return (
    <Styled.PageContainer isModalOpen={showPaymentMethodModal}>
      <Formik<BuyFormValues>
        innerRef={formikRef}
        validationSchema={validationSchema}
        initialValues={{
          submitStep: SubmitStep.Start,
          summaryIds: getInitialSelectedSummaryIds(
            data.batch.rateGroups,
            data.user.rateGroupSortOrder,
            forwardedMailClassKey,
          ),
          shipDate: shipDateSettingRowInitialValues(data.batch.shipDate),
          notifyRecipients: {
            defaultTrackingEmailsDelay: data.company.settings.defaultTrackingEmailsDelay,
            emailTemplate: getDefaultEmailTemplate(
              data.company.settings.defaultEmailTemplateId,
              data.company.mailTemplates,
            ),
            enabled: data.company.settings.defaultTrackingEmailsEnabled,
          },
          finishPurchase: finishPurchaseSubformInitialValues({
            accountBalance: data.company.accountBalance,
            totalCost,
            defaultPaymentSourceId: data.company.settings.defaultPaymentSourceId,
            defaultChargeAmount: data.company.settings.defaultChargeAmount,
            rateGroups: data.batch.rateGroups,
          }),
        }}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue, submitForm }) => (
          <>
            <FormEffect<BuyFormValues>
              onChange={(
                { values: { summaryIds: newSelectedSummaryIds } },
                { values: { summaryIds: prevSelectedSummaryIds } },
              ) => {
                if (!isEqual(newSelectedSummaryIds, prevSelectedSummaryIds)) {
                  const newTotalCost = getTotalCost(newSelectedSummaryIds, data.batch.rateGroups);
                  const minimumTotalCharge = calculateMinimumCharge(
                    data.company.accountBalance,
                    newTotalCost,
                    data.company.settings.defaultChargeAmount,
                  );

                  // Triggers a re-render of the purchase box that displays the total cost
                  setTotalCost(newTotalCost);

                  setFieldValue(
                    'finishPurchase.totalCharge',
                    formatCurrencyNoUnit(minimumTotalCharge),
                  );

                  triggerGoogleTagManagerCustomEvent('Choose a Service');
                }
              }}
            />
            <AddPaymentMethodModal
              open={showPaymentMethodModal}
              onClose={() => setShowPaymentMethodModal(false)}
              onManualClose={() => setBuyInProgress(false)}
              onSuccess={async (paymentSourceId) => {
                await setFieldValue('finishPurchase.paymentSourceId', paymentSourceId, false);
                values.finishPurchase.paymentSourceId = paymentSourceId; // Work around Formik setFieldValue update not being immediate
                await setFieldValue('submitStep', SubmitStep.PaymentGood, false);
                values.submitStep = SubmitStep.PaymentGood; // Work around Formik setFieldValue update not being immediate

                // submit the form to buy if we came via the buy button
                if (paymentMethodModalVia === 'submit') {
                  await submitForm();
                  triggerGoogleTagManagerCustomEvent(
                    'Succesful Connect Payment Method (via Modal)',
                  );
                }
              }}
              promotion={paymentMethodPromotion}
              onError={(error) => setFlashMessage(error, 'danger')}
              buttonText={(() => {
                if (paymentMethodModalVia === 'link') {
                  return 'Submit';
                }
                if (hasMultipleShipments) {
                  return 'Submit & Buy Labels';
                }
                return 'Submit & Buy Label';
              })()}
              origin={
                paymentMethodPromotion === 'plaidThreePercent'
                  ? 'buy_page_three_percent_promo'
                  : 'buy_page'
              }
            />
            <UspsAddressCorrectionModal
              open={showUspsAddressCorrectionModal}
              onCancel={() => {
                setBuyInProgress(false);
                setShowUspsAddressCorrectionModal(false);
              }}
              onAccountCreated={async () => {
                setShowUspsAddressCorrectionModal(false);
                await setFieldValue('submitStep', SubmitStep.UspsGood, false);
                values.submitStep = SubmitStep.UspsGood; // Work around Formik setFieldValue update not being immediate
                await submitForm();
              }}
            />
            <UpsAccountModals
              buttonText={pluralize('Accept & Buy [Label|Labels]', data.batch.numShipments)}
              headline="You’re buying your first UPS label! 🥳"
              open={showUpsAccountModal}
              onCancel={() => {
                setBuyInProgress(false);
                setShowUpsAccountModal(false);
              }}
              onAccountCreatedOrUpdated={async () => {
                setShowUpsAccountModal(false);
                await setFieldValue('submitStep', SubmitStep.UpsGood, false);
                values.submitStep = SubmitStep.UspsGood; // Work around Formik setFieldValue update not being immediate
                await submitForm();
              }}
            />

            {enablePlaidRelinkingHelpModal && (
              <PlaidRelinkingHelpModal
                open={plaidRelinkingHelpModalOpen}
                onSuccess={() => {
                  if (!plaidPaymentSourceId) {
                    logger.error('Plaid payment source id not found, abort relinking process');
                    return;
                  }
                  setPlaidRelinkingHelpModalOpen(false);
                  setPlaidRelinkingModalOpen(true);
                }}
                onClose={() => {
                  setPlaidRelinkingHelpModalOpen(false);
                  setPlaidInProgress(false);
                }}
              />
            )}
            <PlaidRelinkModal
              open={plaidRelinkingModalOpen}
              origin="buy_page"
              paymentSourceId={plaidPaymentSourceId}
              onClose={() => {
                setPlaidRelinkingModalOpen(false);
                setPlaidInProgress(false);
                setBuyInProgress(false);
              }}
              onSuccess={() => submitForm()}
            />
            {failedPaymentSourceId !== null && (
              <ChangePaymentSourceModal
                open
                selectedPaymentSourceId={failedPaymentSourceId}
                submitTitle={hasMultipleShipments ? 'Buy Labels' : 'Buy Label'}
                submitButtonVariant="success"
                paymentSources={data?.company.paymentSources as PaymentSource[]}
                onPaymentSourceChange={async (paymentSourceId: string) => {
                  setFailedPaymentSourceId(null);
                  await setFieldValue('finishPurchase.paymentSourceId', paymentSourceId, false);
                  values.finishPurchase.paymentSourceId = paymentSourceId; // Work around Formik setFieldValue update not being immediate
                  await setFieldValue('submitStep', SubmitStep.PaymentGood, false);
                  values.submitStep = SubmitStep.PaymentGood; // Work around Formik setFieldValue update not being immediate
                  await submitForm();
                }}
                onPaymentSourceAddRequest={() => {
                  setFailedPaymentSourceId(null);
                  setShowPaymentMethodModal(true);
                }}
                onClose={() => {
                  setFailedPaymentSourceId(null);
                }}
              />
            )}

            <Row>
              <Col md={12}>
                <Styled.TitleWrapper>
                  {hasMultipleShipments ? (
                    <BatchTitle
                      value={data.batch.title}
                      onUpdate={(title) => {
                        updateBatchTitle({
                          variables: {
                            id,
                            title,
                          },
                          optimisticResponse: {
                            updateBatchTitle: {
                              __typename: 'Batch',
                              id,
                              title,
                            },
                          },
                        });
                      }}
                    />
                  ) : (
                    <RecipientAddress
                      isReturnLabel={data.batch.shipments[0].isReturnLabel}
                      recipientAddress={data.batch.shipments[0].recipientAddress}
                      countryMap={countryMap}
                    />
                  )}
                </Styled.TitleWrapper>
              </Col>
              <Col md={12}>
                <ShipmentDetailsBox batch={data.batch} countryMap={countryMap} />
              </Col>
              <Col md={12}>
                <RateGroupsSubform<keyof BuyFormValues>
                  namespace="summaryIds"
                  rateGroups={data.batch.rateGroups}
                  user={data.user}
                  company={data.company}
                  shipmentCount={data.batch.numShipments}
                  packageDimensionsAndWeight={{
                    // Preset is guaranteed to be set here. If it isn't, the error boundary will catch it.
                    dimensionX: data.batch.packageSummary.packagePreset!.dimensionX,
                    dimensionY: data.batch.packageSummary.packagePreset!.dimensionY,
                    dimensionZ: data.batch.packageSummary.packagePreset!.dimensionZ,
                    weight: data.batch.packageSummary.packagePreset!.weight,
                  }}
                  onMerchantCreated={() => {
                    // Rerate with current ship date
                    performRerate(data.batch.id, values.shipDate.date, values.summaryIds);
                  }}
                />
              </Col>
              <Col md={12}>
                <Label>Finish Purchase</Label>
                <Form>
                  <FinishPurchaseSubform<keyof BuyFormValues>
                    promotion={paymentMethodPromotion}
                    namespace="finishPurchase"
                    settingRows={[
                      <ShipDateSettingRow<keyof BuyFormValues>
                        key="ship-date"
                        namespace="shipDate"
                        availableShipDates={availableShipDates}
                        onShipDateChange={(newShipDate) => {
                          // A batch needs to be re-rated whenever the current and requested ship date don't match
                          if (!isEqual(values.shipDate.date, newShipDate)) {
                            performRerate(data.batch.id, newShipDate, values.summaryIds);
                          }
                        }}
                      />,
                      data.batch.emailNotificationPossible ? (
                        <NotifyRecipientsSettingRow<keyof BuyFormValues>
                          key="notify-recipients"
                          namespace="notifyRecipients"
                          defaultEmailTemplateId={data.company.settings.defaultEmailTemplateId}
                          mailTemplates={data.company.mailTemplates}
                        />
                      ) : null,
                    ]}
                    totalCost={totalCost}
                    accountBalance={data.company.accountBalance}
                    defaultChargeAmount={data.company.settings.defaultChargeAmount}
                    paymentSources={data.company.paymentSources}
                    onAddPaymentMethodClick={() => {
                      setPaymentMethodModalVia('link');
                      setShowPaymentMethodModal(true);
                    }}
                  />
                  <Row>
                    <Col md={12}>
                      <ShipmentFlowPageFooter
                        inProgress={buyInProgress || rerateInProgress || plaidInProgress}
                        nextStepTitle={hasMultipleShipments ? 'Buy Labels' : 'Buy Label'}
                        onNextStep={submitForm}
                        onPrevStep={async () => {
                          // Handle click on "Previous Step" depending on the data source of the batch
                          switch (data.batch.dataSource) {
                            case 'SPREADSHEET':
                              // For spreadsheet uploads, the batch is reset to its initial status and reused, in all other cases the batch is deleted
                              await setBatchToNew({ variables: { id } });
                              navigateOrHref(`/batch/${id}`, `/upload/new?id=${id}`);
                              return;

                            case 'INTEGRATION':
                              // TODO handle import form restoration when new import form page is built
                              await deleteBatch({ variables: { id } });
                              navigateOrHref('/import', `/shipment/newfrombatch?batch_id=${id}`);
                              return;

                            case 'FORM':
                              if (!environment.isBridge() || isReactSingleShipmentFormPageEnabled) {
                                // Writes batch form values to storage so they can be reused in the single shipment form
                                if (data.batch.packageSummary.packagePreset) {
                                  singleShipmentFormValueStorage.writeValues(
                                    mapBatchToSingleShipmentFormValues(
                                      data.batch,
                                      isCustomsFormRequired,
                                      is2x7LabelSize,
                                    ),
                                  );
                                }
                              }
                              await deleteBatch({ variables: { id } });
                              navigateOrHref(
                                '/ship/single',
                                `/shipment/newfrombatch?batch_id=${id}`,
                              );
                              return;

                            default:
                              // If the exhaustiveCheck line shows an error, the switch is missing a case
                              // eslint-disable-next-line no-case-declarations
                              const exhaustiveCheck: string = data.batch.dataSource;

                              // This should never happen
                              throw new Error(`Unhandled batch dataSource case ${exhaustiveCheck}`);
                          }
                        }}
                        cancelTitle={
                          hasMultipleShipments ? 'Cancel & Delete Labels' : 'Cancel & Delete Label'
                        }
                        cancelConfirmationTitle={
                          hasMultipleShipments ? 'Delete Labels?' : 'Delete Label?'
                        }
                        onCancel={async () => {
                          try {
                            setBackLoading(true);
                            await deleteBatch({ variables: { id } });
                          } catch {
                            setBackLoading(false);
                          } finally {
                            navigateOrHref('/ship');
                          }
                        }}
                      />
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </>
        )}
      </Formik>
    </Styled.PageContainer>
  );
}
