import type { CellContext } from '@tanstack/react-table';
import nl2br from 'react-nl2br';
import { US_COUNTRY_CODE } from '../../../constants';

export type AddressValue = {
  company: string;
  full_name: string;
  address1: string;
  address2: string;
  address3: string;
  address4: string;
  city: string;
  postcode: string;
  zip4: string;
  country_id: string;
  region_id: string;
};

export default function AddressCell<TData, TValue>(props: CellContext<TData, TValue>) {
  const value = props.getValue() as AddressValue;
  let address = '';

  if (value.company) {
    address += `${value.company}\n`;
  } else if (value.full_name) {
    address += `${value.full_name}\n`;
  }
  if (value.address1) {
    address += `${value.address1}\n`;
  }
  if (value.address2) {
    address += `${value.address2}\n`;
  }
  if (value.address3) {
    address += `${value.address3}\n`;
  }
  if (value.address4) {
    address += `${value.address4}\n`;
  }
  if (value.zip4) {
    address += `${value.city} ${value.region_id} ${value.postcode}-${value.zip4}\n`;
  } else {
    address += `${value.city} ${value.region_id} ${value.postcode}\n`;
  }
  if (value.country_id && value.country_id !== US_COUNTRY_CODE) {
    address += `${value.country_id}\n`;
  }

  return nl2br(address);
}
