import styled from '@emotion/styled';
import { PropsWithChildren } from 'react';
import { COLOR, GREYSCALE } from '../../styles/colors';
import { SPACING } from '../../styles/spacing';
import { TYPOGRAPHY } from '../../styles/typography';

const Styled = {
  Text: styled.span<{ warning?: boolean; greyedOut?: boolean }>`
    display: inline-block;
    font-size: ${TYPOGRAPHY.fontSize.sm};
    line-height: 20px;
    color: ${({ warning }) => (warning ? COLOR.red : GREYSCALE.grey50)};
    font-weight: ${TYPOGRAPHY.fontSize.sm};
    opacity: ${({ greyedOut }) => (greyedOut ? '0.6' : 'none')};
    margin-bottom: ${SPACING.lg};
  `,
};

type TextProps = {
  warning?: boolean;
  greyedOut?: boolean;
} & PropsWithChildren<object>;

function Text({ warning = false, greyedOut = false, children }: TextProps) {
  return (
    <Styled.Text warning={warning} greyedOut={greyedOut}>
      {children}
    </Styled.Text>
  );
}

export default Text;
