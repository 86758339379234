import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getIn, useFormikContext } from 'formik';
import { ReactNode, useEffect, useId, useState } from 'react';
import { STORAGE_KEYS } from '../../../constants';
import { BatchQuery } from '../../../gql/graphql';
import { getItem as getStorageItem, setItem as setStorageItem } from '../../../services/storage';
import convertCurrencyToNumber from '../../../utils/convertCurrencyToNumber';
import formatPaymentSourceTitle from '../../../utils/formatPaymentSourceTitle';
import Link from '../../Link';
import LinkedIconTooltip from '../../LinkedIconTooltip';
import CurrencyTextField from '../../form/CurrencyTextField';
import FormControl from '../../form/FormControl';
import InputGroup from '../../form/InputGroup';
import Select from '../../form/Select';
import { Row } from '../../layout/Grid';
import {
  PurchaseDescriptionLabel,
  PurchaseDescriptionSubLabel,
  PurchaseNumberFormat,
  PurchaseWrapper,
  StyledPurchaseAlignRightCol,
  StyledPurchaseCol,
  StyledPurchaseFlexCol,
  StyledPurchaseRow,
  StyledPurchaseSubCol,
} from '../../layout/PurchaseGrid';
import {
  FinishPurchaseBottomRow,
  FinishPurchasePromo,
  calcEndingAccountBalance,
} from '../FinishPurchaseSubform';
import { NamespacedSubform } from '../types';
import DetailToggle from './DetailToggle';

export type FinishPurchaseSubformValues = {
  totalCharge: string;
  paymentSourceId: string;
  includesInternationalRategroup?: boolean;
  // virtual fields only used for validation
  endingAccountBalance: void;
};

export type PaymentSources = BatchQuery['company']['paymentSources'];

export type FinishPurchaseSubformProps<NS extends string> = {
  settingRows?: ReactNode[];
  costBreakdownRows?: ReactNode[];
  accountBalance: number;
  totalCost: number;
  defaultChargeAmount: number;
  paymentSources: PaymentSources;
  promotion?: string;
  onAddPaymentMethodClick: () => void;
} & NamespacedSubform<NS>;

export default function FinishPurchaseSubform<NS extends string>({
  namespace,
  settingRows = [],
  costBreakdownRows = [],
  onAddPaymentMethodClick,
  accountBalance,
  totalCost,
  defaultChargeAmount,
  paymentSources,
  promotion,
}: FinishPurchaseSubformProps<NS>) {
  // We don't know about the type of 'values', we just know that the property
  // identified by 'namespace' is FinishPurchaseSubformValues
  const { values, errors, validateForm } = useFormikContext<Record<string, unknown>>();
  const { totalCharge, paymentSourceId, includesInternationalRategroup } = getIn(
    values,
    namespace,
  ) as FinishPurchaseSubformValues;
  const purchaseRowId = useId();
  const endingAccountBalanceError = getIn(errors, `${namespace}.endingAccountBalance`) as
    | string
    | undefined;

  // Show payment details, based on user preference or if there are no payment sources
  const [showPaymentDetails, setShowPaymentDetails] = useState(
    () => getStorageItem(STORAGE_KEYS.showPaymentSettingsStorageKey) || paymentSources.length === 0,
  );

  // Persist payment details setting
  useEffect(() => {
    setStorageItem(STORAGE_KEYS.showPaymentSettingsStorageKey, showPaymentDetails);
  }, [showPaymentDetails]);

  const selectedPaymentSource =
    paymentSources.find((source) => source.id === paymentSourceId) || paymentSources[0];

  // Re-validate when totalCost and thus validationSchema changes, see https://github.com/jaredpalmer/formik/issues/3687
  useEffect(() => {
    validateForm();
  }, [totalCost, validateForm]);

  return (
    <PurchaseWrapper data-testid="finish-purchase-subform">
      {settingRows}

      {showPaymentDetails && (
        <StyledPurchaseRow align="center" type={accountBalance < 0 ? 'red' : 'green'}>
          <StyledPurchaseCol xs={6}>
            <PurchaseDescriptionLabel>Current Account Balance</PurchaseDescriptionLabel>
          </StyledPurchaseCol>
          <StyledPurchaseAlignRightCol xs={6} id="fpsf-account-balance">
            <PurchaseNumberFormat value={accountBalance} />
          </StyledPurchaseAlignRightCol>
        </StyledPurchaseRow>
      )}

      {costBreakdownRows}

      <StyledPurchaseRow align="center" type="darker">
        <StyledPurchaseCol xs={5} md={7}>
          <PurchaseDescriptionLabel>Total Cost</PurchaseDescriptionLabel>
        </StyledPurchaseCol>
        <StyledPurchaseAlignRightCol xs={7} md={5} id="fpsf-total-cost">
          {includesInternationalRategroup ? (
            <LinkedIconTooltip href="https://support.pirateship.com/en/articles/4882871-international-shipping-guide#h_c342351041">
              Pirate Ship offers DDU labels. This reflects your total cost as a shipper. Customs
              fees, import taxes, or brokerage fees may be collected from your recipient at delivery
              if your package qualifies. Click the <FontAwesomeIcon icon="circle-question" /> to
              learn more 👍
            </LinkedIconTooltip>
          ) : null}
          <PurchaseNumberFormat value={totalCost} />
        </StyledPurchaseAlignRightCol>
      </StyledPurchaseRow>

      {showPaymentDetails && (
        <StyledPurchaseRow align="center" type="darker" id={purchaseRowId}>
          <StyledPurchaseCol xs={3.7} sm={4} lg={5}>
            <PurchaseDescriptionLabel>
              Total Charge
              <LinkedIconTooltip href="https://support.pirateship.com/en/articles/3142928-what-is-the-default-payment-amount-feature">
                The Default Payment Amount feature allows you to add funds to your account in
                specified amounts instead of paying per label, avoiding smaller charges on your
                account. Click the <FontAwesomeIcon icon="circle-question" /> below to learn more 👍
              </LinkedIconTooltip>
            </PurchaseDescriptionLabel>
            <PurchaseDescriptionSubLabel>
              Your default payment amount is currently{' '}
              <Link bridgeHref="/settings/billing" to="/settings/billing">
                ${defaultChargeAmount}
              </Link>
            </PurchaseDescriptionSubLabel>
          </StyledPurchaseCol>
          <StyledPurchaseAlignRightCol xs={8.3} sm={8} lg={7}>
            <Row align="center">
              <StyledPurchaseSubCol sm={12} md={7} lg={6}>
                {paymentSources.length > 0 && (
                  <FormControl
                    name={`${namespace}.paymentSourceId`}
                    as={Select}
                    aria-label="Payment Source"
                    options={paymentSources.map((source) => ({
                      title: formatPaymentSourceTitle(source),
                      value: source.id,
                    }))}
                  />
                )}
              </StyledPurchaseSubCol>
              <StyledPurchaseSubCol sm={12} md={5} lg={6}>
                <InputGroup prefixIcon="dollar-sign">
                  <FormControl
                    name={`${namespace}.totalCharge`}
                    aria-label="Total Charge"
                    as={CurrencyTextField}
                    error={!!endingAccountBalanceError}
                  />
                </InputGroup>
              </StyledPurchaseSubCol>
            </Row>
          </StyledPurchaseAlignRightCol>
        </StyledPurchaseRow>
      )}

      {/* Ending Account Balance */}
      {showPaymentDetails && (
        <StyledPurchaseRow align="center" type={endingAccountBalanceError ? 'red' : 'green'}>
          <StyledPurchaseCol xs={5} md={7}>
            <PurchaseDescriptionLabel>Ending Account Balance</PurchaseDescriptionLabel>
          </StyledPurchaseCol>
          <StyledPurchaseAlignRightCol xs={7} md={5} id="fpsf-ending-account-balance">
            <PurchaseNumberFormat
              value={calcEndingAccountBalance(accountBalance, totalCost, totalCharge)}
            />
          </StyledPurchaseAlignRightCol>
        </StyledPurchaseRow>
      )}

      <StyledPurchaseRow align="center" data-testid="purchase-row">
        <StyledPurchaseFlexCol>
          {promotion === 'plaidThreePercent' && (
            <FinishPurchasePromo addPaymentMethodHandler={onAddPaymentMethodClick} />
          )}
          <FinishPurchaseBottomRow
            totalCharge={convertCurrencyToNumber(totalCharge)}
            hasPaymentSource={paymentSources.length > 0}
            addPaymentMethodHandler={onAddPaymentMethodClick}
            totalCost={totalCost}
            selectedPaymentSource={selectedPaymentSource}
            toogleBtnComponent={
              <DetailToggle
                showPaymentDetails={showPaymentDetails}
                setShowPaymentDetails={setShowPaymentDetails}
                idToControl={purchaseRowId}
              />
            }
            accountBalanceErrorMessage={endingAccountBalanceError}
          />
        </StyledPurchaseFlexCol>
      </StyledPurchaseRow>
    </PurchaseWrapper>
  );
}
