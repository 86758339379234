import { SingleShipmentFormValues, mapPresetToFormValues } from '@ps/shipmentLead';
import { DEFAULT_COUNTRY_CODE, NEW_SHIPMENT_PRESET_VALUE } from '../../constants';
import { DeliveryConfirmation, ReturnLabel, ShipmentKey } from '../../gql/graphql';

type BatchStub = {
  readonly warehouse: {
    readonly id: string;
  };
  readonly packageSummary: {
    readonly packagePreset: {
      readonly id: string;
      readonly shy: boolean;
      readonly packageTypeKey: string;
      readonly dimensionX: number;
      readonly dimensionY: number;
      readonly dimensionZ: number;
      readonly weight: number;
      readonly insuredValueFlag: boolean;
      readonly insuredValue: number;
      readonly deliveryConfirmationFlag: boolean;
      readonly deliveryConfirmation: DeliveryConfirmation;
      readonly returnLabelFlag: boolean;
      readonly returnLabel: ReturnLabel;
      readonly qualifiesAsMediaMail: boolean;
      readonly irregularPackage: boolean;
      readonly hazardousMaterialsEnabled: boolean;
      readonly customsFormEnabled: boolean;
      readonly customsSigner: string;
      readonly customsContentType: string;
      readonly exporterTaxId: string;
      readonly recipientTaxId: string;
      readonly title: string;
      readonly customsItems: ReadonlyArray<{
        readonly title: string;
        readonly quantity: number;
        readonly itemValue: number;
        readonly weight: number;
        readonly hsTariffNumber: string;
        readonly countryCodeOfOrigin: string;
      }>;
    } | null;
  };
  readonly rubberStamps: ReadonlyArray<{
    readonly shipmentKey: ShipmentKey;
    readonly valueOrMappingKey: string;
  }>;
  readonly shipments: ReadonlyArray<{
    readonly recipientAddress: {
      readonly email: string | null;
      readonly phone: string;
      readonly fullName: string;
      readonly company: string;
      readonly address1: string;
      readonly address2: string;
      readonly city: string;
      readonly regionCode: string;
      readonly postcode: string;
      readonly zip4: string;
      readonly countryCode: string;
    };
  }>;
};

export default function mapBatchToSingleShipmentFormValues(
  batch: BatchStub,
  isCustomsFormRequired: boolean,
  is2x7LabelSize: boolean,
): DeepPartial<SingleShipmentFormValues> {
  const {
    shipments: {
      // Recipient address taken from first shipment (only one in batch for single batches)
      [0]: { recipientAddress },
    },
    rubberStamps,
    warehouse: { id: warehouseId },
    packageSummary: { packagePreset },
  } = batch;

  const rubberStamp1 =
    rubberStamps.find((rs) => rs.shipmentKey === 'RUBBER_STAMP1')?.valueOrMappingKey ?? '';
  const rubberStamp2 =
    rubberStamps.find((rs) => rs.shipmentKey === 'RUBBER_STAMP2')?.valueOrMappingKey ?? '';

  return {
    shipToAddress: {
      email: recipientAddress.email ?? '',
      phone: recipientAddress.phone ?? '',
      fullName: recipientAddress.fullName ?? '',
      company: recipientAddress.company ?? '',
      address1: recipientAddress.address1 ?? '',
      address2: recipientAddress.address2 ?? '',
      city: recipientAddress.city ?? '',
      regionCode: recipientAddress.regionCode ?? '',
      postcode:
        (recipientAddress.postcode ?? '') +
        (recipientAddress.postcode && recipientAddress.zip4 ? `-${recipientAddress.zip4}` : ''),
      countryCode: recipientAddress.countryCode ?? DEFAULT_COUNTRY_CODE,
    },
    rubberStamps: {
      hasRubberStamps: Boolean(rubberStamp1 || rubberStamp2),
      rubberStamp1,
      rubberStamp2,
    },
    shipFromAddress: {
      warehouseId,
    },
    packagePreset: packagePreset
      ? {
          shipmentPresetId: packagePreset.shy
            ? NEW_SHIPMENT_PRESET_VALUE
            : packagePreset.id ?? NEW_SHIPMENT_PRESET_VALUE,
          modifyPackage: packagePreset.shy ?? false,
          packageDetails: mapPresetToFormValues(
            packagePreset,
            isCustomsFormRequired,
            is2x7LabelSize,
          ),
          savePreset: false,
          presetTitle: packagePreset.title,
        }
      : undefined,
  };
}
