import { QueryHookOptions, useQuery } from '@apollo/client';
import { gql } from '../../gql';
import { SuggestedMappingQuery, SuggestedMappingQueryVariables } from '../../gql/graphql';

export const suggestedMappingQuery = gql(/* GraphQL */ `
  query SuggestedMappingQuery($id: ID!) {
    shipmentUpload(id: $id) {
      suggestedMapping {
        shipmentKey
        firstValue
        userFriendlyShipmentKey
        valueOrMappingKey
      }
    }
  }
`);

export const useSuggestedMappingQuery = (
  options?: QueryHookOptions<SuggestedMappingQuery, SuggestedMappingQueryVariables>,
) => useQuery(suggestedMappingQuery, options);
