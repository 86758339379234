import { ColumnDef, ColumnMeta, createColumnHelper } from '@tanstack/react-table';
import ActionsCell from '../cellRenderer/ActionsCell';
import AddressCell from '../cellRenderer/AddressCell';
import BooleanCell from '../cellRenderer/BooleanCell';
import CurrencyCell from '../cellRenderer/CurrencyCell';
import DateCell from '../cellRenderer/DateCell';
import DateTimeCell from '../cellRenderer/DateTimeCell';
import PercentageCell from '../cellRenderer/PercentageCell';
import RowSelectCell from '../cellRenderer/RowSelectCell';
import TextCell from '../cellRenderer/TextCell';
import TrackingNumberCell from '../cellRenderer/TrackingNumberCell';
import WeightCell from '../cellRenderer/WeightCell';
import BooleanFilterInterface from '../filterInterfaceRenderer/BooleanFilterInterface';
import DateRangeFilterInterface from '../filterInterfaceRenderer/DateRangeFilterInterface';
import DateTimeRangeFilterInterface from '../filterInterfaceRenderer/DateTimeRangeFilterInterface';
import MultiCheckFilterInterface, {
  FetchMultiCheckOptionsCallbackType,
  MultiCheckFilterInterfaceSettings,
} from '../filterInterfaceRenderer/MultiCheckFilterInterface';
import NumberRangeFilterInterface from '../filterInterfaceRenderer/NumberRangeFilterInterface';
import TextFilterInterface from '../filterInterfaceRenderer/TextFilterInterface';
import BooleanFilterTag from '../filterTagRenderer/BooleanFilterTag';
import CurrencyFilterTag from '../filterTagRenderer/CurrencyFilterTag';
import DateFilterTag from '../filterTagRenderer/DateFilterTag';
import DateTimeFilterTag from '../filterTagRenderer/DateTimeFilterTag';
import MultiCheckFilterTag, {
  MultiCheckFilterTagSettings,
} from '../filterTagRenderer/MultiCheckFilterTag';
import PercentageFilterTag from '../filterTagRenderer/PercentageFilterTag';
import RangeFilterTag from '../filterTagRenderer/RangeFilterTag';
import TextFilterTag from '../filterTagRenderer/TextFilterTag';
import WeightFilterTag from '../filterTagRenderer/WeightFilterTag';
import { Action, ColumnDataType } from '../types';

// This is the column type we get via backend
export interface RemoteColumn {
  readonly label: string;
  readonly field: string;
  readonly type: string;
  readonly width: number | null;
  readonly alternativeSortingField: string | null;
  readonly exactMatch: boolean;
  readonly multiCheck: boolean;
  readonly hidden: boolean;
  readonly sortable: boolean;
}

type MapColumnDefinitionOptions<TData, TValue> = {
  rowSelectEnabled?: boolean;
  remoteColumns: RemoteColumn[];
  rowActions?: Action[];
  fetchMultiCheckOptions: FetchMultiCheckOptionsCallbackType<TData, TValue>;
};

export default function mapColumnDefinition<TData extends Record<string, unknown>, TValue>({
  rowSelectEnabled,
  remoteColumns,
  rowActions = [],
  fetchMultiCheckOptions,
}: MapColumnDefinitionOptions<TData, TValue>): ColumnDef<TData, TValue>[] {
  const columnHelper = createColumnHelper<TData>();
  const rowSelectCol: RemoteColumn = {
    label: 'Selection',
    field: 'row_select',
    type: 'row_select',
    width: null,
    alternativeSortingField: null,
    exactMatch: false,
    multiCheck: false,
    hidden: false,
    sortable: false,
  };
  const returnColumns = rowSelectEnabled ? [rowSelectCol, ...remoteColumns] : remoteColumns;
  return returnColumns
    .filter((remoteColumn) => remoteColumn.type !== 'checkbox')
    .map((remoteColumn) => {
      const defaultMeta: ColumnMeta<TData, TValue> = {
        filterInterface: TextFilterInterface,
        filterTag: TextFilterTag,
        filterType: 'text',
        dataType: remoteColumn.type as ColumnDataType,
        hidden: remoteColumn.hidden,
        sortable: remoteColumn.sortable,
      };

      const mappedColumn: ColumnDef<TData, TValue> = {
        id: remoteColumn.field,
        header: remoteColumn.label, // this should always be the header as a string
        size: remoteColumn.width ?? undefined,
        cell: TextCell,
        meta: defaultMeta,
        enableSorting: remoteColumn.sortable,
      };

      switch (remoteColumn.type as ColumnDataType) {
        case 'action': // utility column
          mappedColumn.cell = ActionsCell;
          mappedColumn.enableSorting = false;
          mappedColumn.enableColumnFilter = false;
          mappedColumn.enableHiding = false;
          mappedColumn.meta = {
            ...defaultMeta,
            cellSettings: { staticActions: rowActions },
          };
          break;

        case 'row_select': // utility column
          mappedColumn.cell = RowSelectCell;
          mappedColumn.maxSize = 46;
          mappedColumn.enableResizing = false;
          mappedColumn.enableHiding = false;
          break;

        case 'datetime':
          mappedColumn.cell = DateTimeCell;

          mappedColumn.meta = {
            ...defaultMeta,
            filterType: 'betweenDates',
            filterInterface: DateTimeRangeFilterInterface,
            filterTag: RangeFilterTag,
            filterTagSettings: {
              filterTag: DateTimeFilterTag,
              lowerLimitOnlyPrefix: 'since',
              upperLimitOnlyPrefix: 'until',
            },
          };
          break;

        case 'date':
          mappedColumn.cell = DateCell;
          mappedColumn.meta = {
            ...defaultMeta,
            filterType: 'betweenDates',
            filterInterface: DateRangeFilterInterface,
            filterTag: RangeFilterTag,
            filterTagSettings: {
              filterTag: DateFilterTag,
              lowerLimitOnlyPrefix: 'since',
              upperLimitOnlyPrefix: 'until',
            },
          };
          break;

        case 'currency':
          mappedColumn.cell = CurrencyCell;
          mappedColumn.meta = {
            ...defaultMeta,
            filterType: 'between',
            filterInterface: NumberRangeFilterInterface,
            filterTag: RangeFilterTag,
            filterTagSettings: {
              filterTag: CurrencyFilterTag,
            },
          };
          break;

        case 'weight':
          mappedColumn.cell = WeightCell;
          mappedColumn.meta = {
            ...defaultMeta,
            filterType: 'between',
            filterInterface: NumberRangeFilterInterface,
            filterTag: RangeFilterTag,
            filterTagSettings: { filterTag: WeightFilterTag },
          };
          break;

        case 'id':
          mappedColumn.meta = { ...defaultMeta, filterType: 'exactTextCase' };
          break;

        case 'address':
          mappedColumn.cell = AddressCell;
          break;

        case 'percental':
          mappedColumn.cell = PercentageCell;
          mappedColumn.meta = {
            ...defaultMeta,
            filterType: 'between',
            filterInterface: NumberRangeFilterInterface,
            filterTag: RangeFilterTag,
            filterTagSettings: {
              filterTag: PercentageFilterTag,
            },
          };
          break;

        case 'boolean':
          mappedColumn.cell = BooleanCell;
          mappedColumn.meta = {
            ...defaultMeta,
            filterType: 'exact',
            filterInterface: BooleanFilterInterface,
            filterTag: BooleanFilterTag,
          };
          break;

        case 'commasplit':
          mappedColumn.cell = TextCell;
          mappedColumn.meta = {
            ...defaultMeta,
            cellSettings: { splitOnComma: true },
          };
          break;

        case 'carrieradjustment':
          mappedColumn.cell = CurrencyCell;
          mappedColumn.meta = {
            ...defaultMeta,
            cellSettings: { colorPositive: true },
            filterInterface: NumberRangeFilterInterface,
          };
          break;

        case 'checkbox':
          // Filtered out, should never be reached
          throw new Error('Column type checkbox cannot be mapped');

        case 'trackingnumber':
          mappedColumn.cell = TrackingNumberCell;
          mappedColumn.meta = {
            ...defaultMeta,
            filterType: 'exactText',
          };
          break;

        case 'stripbbcodes':
          mappedColumn.cell = TextCell;
          mappedColumn.meta = {
            ...defaultMeta,
            cellSettings: { stripBbCodes: true },
          };
          break;

        case 'default':
        default:
        // use text fallback
      }

      if (remoteColumn.multiCheck) {
        mappedColumn.meta = {
          ...mappedColumn.meta,
          filterType: 'includes',
          filterInterface: MultiCheckFilterInterface,
          filterInterfaceSettings: { fetchMultiCheckOptions } as MultiCheckFilterInterfaceSettings<
            TData,
            TValue
          >,
          filterTag: MultiCheckFilterTag,
          filterTagSettings: { fetchMultiCheckOptions } as MultiCheckFilterTagSettings<
            TData,
            TValue
          >,
        };
      }

      return columnHelper.accessor((originalRow) => originalRow[mappedColumn.id!], {
        ...mappedColumn,
      });
    });
}
