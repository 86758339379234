import { setFlashMessage } from '../../apollo/cache/flashMessages';
import CenteredPageHeader from '../../components/layout/CenteredPageHeader';
import LandlubberContent from '../../components/layout/LandlubberContent';
import environment from '../../utils/environment';
import ChangePasswordForm from '../components/form/ChangePasswordForm';

type InvitePasswordPageProps = {
  turnstileKey?: string;
};

export default function InvitePasswordPage({ turnstileKey }: InvitePasswordPageProps) {
  return (
    <LandlubberContent>
      <CenteredPageHeader title="Create a new password" />
      <ChangePasswordForm
        turnstileKey={turnstileKey}
        labels={{ submit: 'Set Password' }}
        onSuccess={() => {
          if (environment.isBridge()) {
            setFlashMessage('Your password was set successfully', 'success', undefined, true);
          }
        }}
      />
    </LandlubberContent>
  );
}
