import { LazyQueryHookOptions, QueryHookOptions, useLazyQuery, useQuery } from '@apollo/client';
import { gql } from '../../gql';
import { ShipmentChartQuery, ShipmentChartQueryVariables } from '../../gql/graphql';

export const shipmentChartQuery = gql(/* GraphQL */ `
  query ShipmentChartQuery($datetimeRange: DatetimeRangeInput!) {
    shipmentChart(datetimeRange: $datetimeRange) {
      charts
      labels
    }
  }
`);

export const useShipmentChartQuery = (
  options: QueryHookOptions<ShipmentChartQuery, ShipmentChartQueryVariables>,
) => useQuery(shipmentChartQuery, options);

export const useShipmentChartLazyQuery = (
  options: LazyQueryHookOptions<ShipmentChartQuery, ShipmentChartQueryVariables>,
) => useLazyQuery(shipmentChartQuery, options);
