import { Component, ErrorInfo, ReactNode } from 'react';
import { Logger } from '../services/logging';
import generateLogId from '../utils/generateLogId';

type ErrorWithLogId = Error & { logId: string };

type RootErrorBoundaryProps = {
  logger: Logger;
  children: (error?: ErrorWithLogId) => ReactNode;
};

type RootErrorBoundaryState = {
  error?: ErrorWithLogId;
};

export default class RootErrorBoundary extends Component<
  RootErrorBoundaryProps,
  RootErrorBoundaryState
> {
  constructor(props: RootErrorBoundaryProps) {
    super(props);

    this.state = {
      error: undefined,
    };
  }

  static getDerivedStateFromError(error: Error) {
    const errorWithLogId = error as ErrorWithLogId;
    errorWithLogId.logId = generateLogId();

    // Update state so the next render will show the fallback UI.
    return { error: errorWithLogId };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const { logger } = this.props;
    const { error: stateError } = this.state;

    // PP-8522: all logs with severity >= WARN are reduced for now
    logger.info(
      'Uncaught client rendering error',
      { ...errorInfo, logId: stateError?.logId },
      error,
    );
  }

  render() {
    const { children } = this.props;
    const { error } = this.state;

    return children(error);
  }
}
