import { useMutation, useSuspenseQuery } from '@apollo/client';
import styled from '@emotion/styled';
import { ShipmentPrintModal } from '@ps/printLabels';
import {
  ShipmentRefundModal,
  getShipmentRefundSuccessMessage,
  refundShipmentMutation,
} from '@ps/refundLabels';
import {
  mapBatchToSingleShipmentFormValues,
  singleShipmentFormValueStorage,
} from '@ps/shipmentLead';
import FileSaver from 'file-saver';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { addFlashMessage, setFlashMessage } from '../../apollo/cache/flashMessages';
import Link from '../../components/Link';
import RecipientAddress from '../../components/RecipientAddress';
import Button from '../../components/form/Button';
import ProgressButton from '../../components/form/ProgressButton';
import { Col, PageContainer, Row } from '../../components/layout/Grid';
import PageHeader from '../../components/layout/PageHeader';
import { DATE_FORMAT } from '../../constants';
import { ShipmentStatus } from '../../gql/graphql';
import useDateInUserTimezone from '../../hooks/useDateInUserTimezone';
import useFeature from '../../hooks/useFeature';
import useMappedCountries from '../../hooks/useMappedCountries';
import useNavigateOrHref from '../../hooks/useNavigateOrHref';
import { GREYSCALE } from '../../styles/colors';
import { SPACING } from '../../styles/spacing';
import { TYPOGRAPHY } from '../../styles/typography';
import environment from '../../utils/environment';
import triggerGoogleTagManagerCustomEvent from '../../utils/triggerGoogleTagManagerCustomEvent';
import ShipmentDetailsBox from '../components/ShipmentDetailsPageShipmentDetailsBox';
import ShipmentMap from '../components/ShipmentMap';
import ServiceSummaryTable from '../components/ShipmentServiceSummaryTable';
import ShipmentStageDots from '../components/ShipmentStageDots';
import { useDataGridExportStatusLazyQuery } from '../operations/dataGridExportStatus.query';
import shipmentDetailsPageQuery from '../operations/shipmentDetailsPage.query';

type ShipmentStatusFlags = { [key in ShipmentStatus]: boolean };

const displayTrackingStatuses: ShipmentStatusFlags = {
  PURCHASED: true,
  VALIDATED: false,
  RATED: false,
  RETURN_UNUSED: true,
  RETURN_PURCHASED: true,
  CREATED: false,
  IMPORTED: false,
  INVALID: false,
  PURCHASE_FAILED: false,
  RATE_FAILED: false,
  REFUNDED: false,
  REFUND_DECLINED: false,
  REFUND_FAILED: false,
  REFUND_REQUESTED: false,
  REFUND_REVERSED: false,
  RETURN_EXCEEDED: false,
  VALIDATION_FAILED: false,
};

const displayPriceTableStatuses: ShipmentStatusFlags = {
  PURCHASED: true,
  REFUNDED: true,
  REFUND_FAILED: true,
  REFUND_REQUESTED: true,
  REFUND_DECLINED: true,
  REFUND_REVERSED: true,
  RETURN_PURCHASED: true,
  RETURN_UNUSED: true,
  CREATED: false,
  IMPORTED: false,
  INVALID: false,
  PURCHASE_FAILED: false,
  RATED: false,
  RATE_FAILED: false,
  RETURN_EXCEEDED: false,
  VALIDATED: false,
  VALIDATION_FAILED: false,
};

const Styled = {
  TransactionDateText: styled.div`
    color: ${GREYSCALE.grey50};
    font-size: ${TYPOGRAPHY.fontSize.sm};
    padding-bottom: ${SPACING.sm};
  `,
  BatchLinkWrapper: styled.div`
    padding-bottom: ${SPACING.sm};
  `,
  BatchLink: styled(Link)`
    font-size: ${TYPOGRAPHY.fontSize.sm};
  `,
  PrintButtonWrapper: styled.div`
    align-self: end;
    display: flex;
  `,
  HiddenOnPrint: styled.div`
    @media print {
      display: none;
    }
  `,
};

type ShipmentDetailsPageProps = {
  bridgeShipmentId?: string; // only used if we are coming from the bridge
  bridgeBatchId?: string; // only used if we are coming from the bridge
};

export default function ShipmentDetailsPage({
  bridgeShipmentId,
  bridgeBatchId,
}: ShipmentDetailsPageProps) {
  const params = useParams();
  const shipmentId = bridgeShipmentId ?? params.shipmentId ?? '0'; // should never default to 0
  const batchId = bridgeBatchId ?? params.batchId ?? '0'; // should never default to 0
  const [refundModalOpen, setRefundModalOpen] = useState(false);
  const navigateOrHref = useNavigateOrHref();
  const [showShipmentPrintModal, setShowPrintModal] = useState(false);
  const {
    data: { batch, company },
  } = useSuspenseQuery(shipmentDetailsPageQuery, {
    variables: { batchId, shipmentId },
  });
  const shipment = batch.shipments[0];
  const countryMap = useMappedCountries();
  const { formatDate } = useDateInUserTimezone();
  const [refundShipment, { loading: loadingRefundShipment }] = useMutation(refundShipmentMutation);
  const [getDataGridExportStatus, { loading: exportStatusLoading }] =
    useDataGridExportStatusLazyQuery();
  const isReactSingleShipmentFormPageEnabled = useFeature('Pp.feature.singleShipmentFormPage');

  // Navigate back to the batch page if the shipment is not found
  useEffect(() => {
    if (!shipment) {
      navigateOrHref(`/batch/${batchId}`);
    }
  }, [navigateOrHref, shipment, batchId]);

  useEffect(() => {
    // whenever a single shipment is bought we want the
    // screen to be scrolled to the top.
    // this is not happening automatically because the url
    // dose not change during the buy process and the
    // batchProcessStatusQuery is not running when buying a single shipment
    window.scrollTo(0, 0);
  }, []);

  // Don't render anything if we don't have a shipment
  if (!shipmentId || !shipment) {
    return null;
  }

  const handleExport = async () => {
    const exportStatus = await getDataGridExportStatus({
      variables: {
        shipmentId,
      },
    });
    const fileInfo = exportStatus.data?.shipment.trackingDataExportUrl ?? null;

    if (fileInfo?.url) {
      FileSaver.saveAs(fileInfo.url, fileInfo.filename);
    }
  };

  const handleRefund = async () => {
    try {
      await refundShipment({
        variables: {
          shipmentId,
        },
      });

      addFlashMessage(
        getShipmentRefundSuccessMessage(
          shipment.canInstantRefundShipment,
          shipment.carrierKey === 'ups',
        ),
        'success',
      );
    } catch (error: unknown) {
      if (error instanceof Error) {
        setFlashMessage(error.message, 'danger');
      }
    } finally {
      setRefundModalOpen(false);
    }
  };

  return (
    <PageContainer>
      <ShipmentPrintModal
        open={showShipmentPrintModal}
        onClose={() => {
          setShowPrintModal(false);
        }}
        shipmentId={shipmentId}
      />
      <ShipmentRefundModal
        open={refundModalOpen}
        onCancel={() => setRefundModalOpen(false)}
        onConfirm={handleRefund}
        confirmationButtonProgress={loadingRefundShipment}
        canInstantRefundShipment={shipment.canInstantRefundShipment}
        isUpsShipment={shipment.carrierKey === 'ups'}
      />
      <Row>
        <Col>
          <PageHeader
            actionsTopOnMobile
            title={
              <div>
                <Styled.TransactionDateText>
                  {formatDate(
                    'UTC',
                    batch.createdAt,
                    `${DATE_FORMAT.dayOfWeek}, ${DATE_FORMAT.usDate} ${DATE_FORMAT.time12NoLeadingZero} ${DATE_FORMAT.timezone}`,
                  )}
                </Styled.TransactionDateText>
                {batch.numShipments > 1 && (
                  <Styled.BatchLinkWrapper>
                    {/* We shouldn't add a bridgeHref, as we want the wrapping react router to take care of our navigation */}
                    <Styled.BatchLink to={`/batch/${batch.id}`}>{batch.title}</Styled.BatchLink>
                  </Styled.BatchLinkWrapper>
                )}
                <RecipientAddress
                  recipientAddress={shipment.recipientAddress}
                  isReturnLabel={shipment.isReturnLabel}
                  countryMap={countryMap}
                />
              </div>
            }
            rightSideActions={[
              shipment.canPrint && (
                <Button
                  hideForPrint
                  key="printButton"
                  size="xLarge"
                  disabled={batch.cleanupComplete}
                  variant={shipment.printed || batch.cleanupComplete ? 'secondary' : 'success'}
                  onClick={() => {
                    setShowPrintModal(true);
                    triggerGoogleTagManagerCustomEvent('Click Print Label');
                  }}
                >
                  {(() => {
                    if (batch.cleanupComplete) {
                      return 'Label Expired';
                    }
                    if (shipment.printed) {
                      return 'Reprint Label';
                    }
                    return 'Print Label';
                  })()}
                </Button>
              ),
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ShipmentDetailsBox
            batch={batch}
            countryMap={countryMap}
            accountBalance={company.accountBalance}
          />
        </Col>
      </Row>
      {displayTrackingStatuses[shipment.status] && (
        <>
          <Styled.HiddenOnPrint>
            <Row>
              <Col md={12} spaceBelow>
                <ShipmentStageDots stageDots={shipment.stageDots} />
              </Col>
            </Row>
            <Row>
              <Col md={12} spaceBelow>
                <ShipmentMap shipmentId={shipment.id} />
              </Col>
            </Row>
          </Styled.HiddenOnPrint>
        </>
      )}
      {displayPriceTableStatuses[shipment.status] && (
        <Row>
          <Col md={12} spaceBelow>
            <ServiceSummaryTable batch={batch} />
          </Col>
        </Row>
      )}
      <Styled.HiddenOnPrint>
        <Row justify="end">
          <Col md={12} lg={4} spaceBelow>
            <Button
              fullWidth
              key="duplicateButton"
              onClick={() => {
                if (!environment.isBridge() || isReactSingleShipmentFormPageEnabled) {
                  // Writes batch form values to storage so they can be reused in the single shipment form
                  if (batch?.packageSummary.packagePreset) {
                    singleShipmentFormValueStorage.writeValues(
                      mapBatchToSingleShipmentFormValues(
                        batch,
                        batch.customsFormRequired,
                        batch.labelSize === 'SIZE_2x7',
                      ),
                    );
                  }
                  navigateOrHref('/ship/single');
                } else {
                  navigateOrHref('/ship/single', shipment.duplicateShipmentLink);
                }
              }}
            >
              Duplicate Shipment
            </Button>
          </Col>
          <Col md={12} lg={4} spaceBelow>
            <ProgressButton
              fullWidth
              key="exportButton"
              progress={exportStatusLoading}
              onClick={() => handleExport()}
            >
              Export Tracking Data
            </ProgressButton>
          </Col>
          {shipment.isShipmentRefundable && (
            <Col md={12} lg={4} spaceBelow>
              <Button
                fullWidth
                key="refundButton"
                variant="danger"
                onClick={() => setRefundModalOpen(true)}
              >
                Refund Label
              </Button>
            </Col>
          )}
        </Row>
      </Styled.HiddenOnPrint>
    </PageContainer>
  );
}
