import * as yup from 'yup';
import {
  INITIAL_PACKAGE_SUBFORM_VALUES,
  packageValidationSchema,
  type PackageSubformValues,
  type PackageType,
} from '../../../components/subforms/PackageSubform';
import { type ShipmentBoundaries } from '../../../gql/graphql';
import { toOunces } from '../../../utils/formatWeight';
import {
  INITIAL_CUSTOMS_FORM_SUBFORM_VALUES,
  customsFormValidationSchema,
  type CustomsFormSubformValues,
  type CustomsLineItemSubformValues,
} from '../CustomsFormSubform';
import {
  INITIAL_EXTRA_SERVICE_SUBFORM_VALUES,
  extraServicesValidationSchema,
} from '../ExtraServicesSubform';
import {
  INITIAL_HAZARDOUS_MATERIALS_SUBFORM_VALUES,
  hazardousMaterialsValidationSchema,
} from '../HazardousMaterialsSubform';
import { PackageDetailsSubformValues } from './PackageDetailsSubform';

export const initialPackageDetailsSubformValues = (
  userName: string,
  customsFormIsRequired: boolean,
  is2x7LabelSize: boolean,
): PackageDetailsSubformValues => ({
  package: {
    ...INITIAL_PACKAGE_SUBFORM_VALUES,
  },
  extraServices: {
    ...INITIAL_EXTRA_SERVICE_SUBFORM_VALUES,
    ...(is2x7LabelSize && { returnLabelSelectEnabled: false }),
  },
  hazardousMaterials: {
    ...INITIAL_HAZARDOUS_MATERIALS_SUBFORM_VALUES,
  },
  customsForm: {
    ...INITIAL_CUSTOMS_FORM_SUBFORM_VALUES,
    customsSigner: userName,
    customsFormEnabled: customsFormIsRequired && !is2x7LabelSize,
  },
});

export const packageDetailsValidationSchema = (
  isCustomsFormRequired: boolean,
  isHsCodeRequired: boolean,
  is2x7LabelSize: boolean,
  shipmentBoundaries: ShipmentBoundaries,
  packageTypes: readonly PackageType[],
) =>
  yup.object<PackageDetailsSubformValues>({
    package: yup
      .mixed<PackageSubformValues>()
      // Package validation is dependent on customs form values (so that weights are matched)
      .when('customsForm', (customsForm: CustomsFormSubformValues) => {
        const totalCustomsItemsWeightsInOunces = customsForm.customsFormEnabled
          ? customsForm.customsItems.reduce(
              (prev: number, currItem: CustomsLineItemSubformValues) =>
                prev +
                toOunces({
                  pounds: Number(currItem.weightPounds),
                  ounces: Number(currItem.weightOunces),
                }),
              0,
            )
          : 0;
        return packageValidationSchema(
          shipmentBoundaries,
          packageTypes,
          totalCustomsItemsWeightsInOunces,
        ).required();
      }),
    extraServices: extraServicesValidationSchema().required(),
    hazardousMaterials: hazardousMaterialsValidationSchema().required(),
    customsForm: customsFormValidationSchema(
      // We don't validate if a customs form is required when 2x7 label size is selected,
      // because customs forms are not possible anyway. Not requiring it allows the user
      // to proceed with the rating, effective filtering out shipments in that step.
      isCustomsFormRequired && !is2x7LabelSize,
      isHsCodeRequired && !is2x7LabelSize,
      shipmentBoundaries.maxWeight,
    ).required(),
  });
