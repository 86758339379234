import { STORAGE_KEYS } from '../../constants';
import { removeItem as removeStorageItem, setItem as setStorageItem } from '../../services/storage';

const loggedInService = {
  setLoggedIn() {
    setStorageItem(STORAGE_KEYS.loggedInStorageKey, 'true', {
      lifetime: 24 * 60 * 60 * 1000, // 24 hours
    });
  },

  isLoggedIn() {
    return !!localStorage.getItem(STORAGE_KEYS.loggedInStorageKey);
  },

  setLoggedOut() {
    removeStorageItem(STORAGE_KEYS.loggedInStorageKey);
  },
};

export default loggedInService;
