import * as yup from 'yup';
import { ShipmentBoundaries } from '../../../gql/graphql';
import formatWeight, { toOunces } from '../../../utils/formatWeight';
import { PackageSubformValues, type PackageType } from './PackageSubform';

export const INITIAL_PACKAGE_SUBFORM_VALUES: PackageSubformValues = {
  packageTypeKey: 'Parcel',
  dimensionX: '',
  dimensionY: '',
  dimensionZ: '',
  weightPounds: 0,
  weightOunces: 0,
};

/**
 * Returns "Parcel" if the package type key is not found in the package types list.
 * This can happen, if a carrier decides to remove a package type, but users still have this package type saved in their presets.
 */
export const getInitialPackageTypeKey = (
  packageTypes: readonly PackageType[] | undefined,
  packageTypeKey: string,
) => {
  const noPackageTypeFound = !packageTypes?.find(
    (packageType) => packageType.packageTypeKey === packageTypeKey,
  );

  if (noPackageTypeFound) {
    return 'Parcel';
  }
  return packageTypeKey;
};

export const packageValidationSchema = (
  boundaries: ShipmentBoundaries,
  packageTypes: ReadonlyArray<PackageType>,
  customsFormTotalWeightOz: number = 0,
) => {
  const getPackageType = (packageTypeKey: string) =>
    packageTypes.find((pt) => pt.packageTypeKey === packageTypeKey);

  return yup.object<PackageSubformValues>({
    packageTypeKey: yup.string().required(),
    dimensionX: yup.string().when('packageTypeKey', (packageTypeKey, schema: yup.StringSchema) => {
      const packageType = getPackageType(packageTypeKey);
      return packageType?.dimensionsRequired ? schema.required().packageLength() : schema;
    }),
    dimensionY: yup.string().when('packageTypeKey', (packageTypeKey, schema: yup.StringSchema) => {
      const packageType = getPackageType(packageTypeKey);
      return packageType?.dimensionsRequired ? schema.required().packageLength() : schema;
    }),
    dimensionZ: yup.string().when('packageTypeKey', (packageTypeKey, schema: yup.StringSchema) => {
      const packageType = getPackageType(packageTypeKey);
      return packageType?.heightRequired ? schema.required().packageLength() : schema;
    }),
    weightPounds: yup.number().required().min(0).default(0),
    weightOunces: yup.number().required().min(0).default(0),
    combinedDimensions: yup
      .object()
      .when('packageTypeKey', (packageTypeKey, schema: yup.ObjectSchema) => {
        const packageType = getPackageType(packageTypeKey);
        if (packageType?.dimensionsRequired) {
          return packageType.heightRequired
            ? schema.packageDimensions3d(boundaries)
            : schema.packageDimensions2d(boundaries);
        }
        return schema;
      }),
    combinedWeight: yup
      .object()
      .when(
        ['packageTypeKey', 'weightPounds', 'weightOunces'],
        (packageTypeKey, weightPounds, weightOunces, schema: yup.ObjectSchema) => {
          const packageType = getPackageType(packageTypeKey);
          if (packageType?.weightRequired) {
            const packageTotalWeightOz = toOunces({ pounds: weightPounds, ounces: weightOunces });
            return schema.packageWeight(boundaries).test(
              'customs-form-weight-not-over',
              `The weight of the items in your customs form (${formatWeight({
                pounds: 0,
                ounces: customsFormTotalWeightOz,
              })}) is higher than the total weight (${formatWeight({
                pounds: weightPounds,
                ounces: weightOunces,
              })})`,
              () => customsFormTotalWeightOz <= packageTotalWeightOz,
            );
          }
          return schema;
        },
      ),
  });
};
