import debounce from 'lodash.debounce';
import { useMemo, useRef, useState } from 'react';
import TextField, { TextFieldProps } from '../../components/form/TextField';
import useLogger from '../../hooks/useLogger';
import useOutsideClickEffect from '../../hooks/useOutsideClickEffect';
import GooglePlacesClient, {
  PlacePrediction,
  PlacesDetailsResponseStub,
} from '../utils/googlePlacesClient';

const AUTOCOMPLETE_MIN_CHARS = 6;
const AUTOCOMPLETE_DEBOUNCE_MS = 500;
const AUTOCOMPLETE_MAX_WAIT_MS = 1000;

type Address = {
  address1: string;
  number?: string;
  postcode: string;
  city: string;
  regionCode: string;
  countryCode: string;
};

function mapPlaceDetailsToAddress(placeDetails: PlacesDetailsResponseStub): Address {
  const details: Address = {
    address1: '',
    number: '',
    postcode: '',
    city: '',
    regionCode: '',
    countryCode: '',
  };
  const returnedTypes = placeDetails.addressComponents.flatMap(
    (addressComponent) => addressComponent.types,
  );

  placeDetails.addressComponents.forEach((component) => {
    const type = component.types[0];

    switch (type) {
      case 'country':
        details.countryCode = component.shortText;
        break;
      case 'route':
        details.address1 = component.longText;
        break;
      case 'street_number':
        details.number = component.longText;
        break;
      case 'administrative_area_level_1':
        details.regionCode = component.shortText;
        break;
      case 'postal_code':
        details.postcode = component.longText;
        break;

      // for city, we prefer "locality",
      // if empty then we pick "postal_town" or "administrative_area_level_2"
      case 'locality':
        details.city = component.longText;
        break;
      case 'postal_town':
        if (!returnedTypes.includes('locality')) {
          details.city = component.longText;
        }
        break;
      case 'administrative_area_level_2':
        if (!returnedTypes.includes('locality') && !returnedTypes.includes('postal_town')) {
          details.city = component.longText;
        }
        break;
    }
  });

  // passthrough the original address1 if the place details result doesn't contain a house number
  if (details.number !== '') {
    details.address1 = details.number + ' ' + details.address1;
    delete details.number;
  }

  return details;
}

export type GoogleAddressSuggestTextFieldProps = TextFieldProps & {
  googlePlacesApiKey: string;
  onSelectAddress?: (address: Address) => void;
  onBlurAddress?: () => void;
  onConfirmAddress?: () => void;
};

export default function GoogleAddressSuggestTextField({
  googlePlacesApiKey,
  onSelectAddress,
  onBlurAddress,
  onConfirmAddress,
  ...others
}: GoogleAddressSuggestTextFieldProps) {
  const placesClient = useMemo(
    () => new GooglePlacesClient(googlePlacesApiKey),
    [googlePlacesApiKey],
  );
  const logger = useLogger();
  const [focused, setFocused] = useState(false);
  const [placePredictions, setPlacePredictions] = useState<PlacePrediction[]>();

  const wrapperRef = useRef<HTMLDivElement>(null);
  useOutsideClickEffect(wrapperRef, () => {
    setFocused(false);
    onBlurAddress?.();
  });

  // Used to fetch place predictions with a debounce (but send once every second)
  const debouncedFetchPredictions = useMemo(
    () =>
      debounce(
        async (address: string) => {
          try {
            const predictions = await placesClient.getPlacePredictions(address);
            const predictionObjects = predictions.suggestions?.map(
              (suggestion) => suggestion.placePrediction,
            );
            if (predictionObjects) {
              setPlacePredictions(predictionObjects);
            }
          } catch (error) {
            logger.error('Failed to get place predictions', undefined, error as Error);
          }
        },
        AUTOCOMPLETE_DEBOUNCE_MS,
        { maxWait: AUTOCOMPLETE_MAX_WAIT_MS },
      ),
    [placesClient, logger],
  );

  return (
    <div ref={wrapperRef}>
      <TextField
        {...others}
        onFocus={(event) => {
          setFocused(true);
          others.onFocus?.(event);
        }}
        onChange={(event) => {
          others.onChange?.(event);

          // If input is too short, clear the predictions and abort fetching
          if (event.target.value.length < AUTOCOMPLETE_MIN_CHARS) {
            setPlacePredictions(undefined);
            return;
          }

          // Fetch predictions if the change event wasn't prevented by a using component
          if (!event.defaultPrevented) {
            debouncedFetchPredictions(event.target.value);
          }
        }}
        onKeyDown={(event) => {
          // If the user tabs out of the field, unfocus and clear the predictions
          if (event.key === 'Tab') {
            setFocused(false);
            onBlurAddress?.();
          }

          // If the user presses escape, clear the predictions
          if (event.key === 'Escape') {
            event.preventDefault();
            setPlacePredictions(undefined);
          }

          // If the user presses enter, signal to the using component that an address should be confirmed
          if (event.key === 'Enter') {
            event.preventDefault();
            setPlacePredictions(undefined);
            onConfirmAddress?.();
          }
        }}
      />
      {/* This is a temporary debug output to interact with the predictions */}
      {focused && placePredictions && (
        <output>
          {placePredictions.map((prediction) => (
            <button
              type="button"
              key={prediction.placeId}
              onMouseEnter={async () => {
                const placeDetails = await placesClient.getPlaceDetails(prediction.placeId);
                const address = mapPlaceDetailsToAddress(placeDetails);
                onSelectAddress?.(address);
              }}
              onMouseLeave={() => onBlurAddress?.()}
              onClick={(event) => {
                event.preventDefault();
                setPlacePredictions(undefined);
                onConfirmAddress?.();
              }}
              style={{
                appearance: 'none',
                display: 'block',
                width: '100%',
                textAlign: 'left',
                borderWidth: '0 1px 1px 1px',
                borderStyle: 'inset',
                padding: '0.5rem',
                background: 'none',
                cursor: 'pointer',
              }}
            >
              {prediction.text.text}
            </button>
          ))}
        </output>
      )}
    </div>
  );
}
