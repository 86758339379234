import pluralize from './pluralize';

export default function secondsToString(seconds: number) {
  let secs = seconds;
  const years = Math.floor(secs / 31536000);
  const days = Math.floor((secs %= 31536000) / 86400);
  const hours = Math.floor((secs %= 86400) / 3600);
  const minutes = Math.floor((secs %= 3600) / 60);

  if (years) {
    return pluralize('%d [year|years]', years);
  }
  if (days) {
    return pluralize('%d [day|days]', days);
  }
  if (hours) {
    return pluralize('%d [hour|hours]', hours);
  }
  if (minutes) {
    return pluralize('%d [minute|minutes]', minutes);
  }
  return pluralize('%d [second|seconds]', seconds);
}
