import { QueryHookOptions, gql as gqlFuncAliasNotCodegenParsed, useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { DataGridMetaData } from '../../../../gql/graphql';
import { ButtonVariant } from '../../../form/Button';
import constructGridQueryBody from '../../utils/constructGridQueryBody';
import constructGridQueryDefinition from '../../utils/constructGridQueryDefinition';
import constructGridQueryVariables from '../../utils/constructGridQueryVariables';
import { GridQueryVariables } from './connectedGridRows';

export interface Column {
  readonly __typename: 'DataGridColumn';
  readonly label: string;
  readonly field: string;
  readonly type: string;
  readonly width: number | null;
  readonly alternativeSortingField: string | null;
  readonly exactMatch: boolean;
  readonly multiCheck: boolean;
  readonly hidden: boolean;
  readonly sortable: boolean;
}

export interface RowAction {
  readonly __typename: 'DataGridRowAction';
  readonly id: string;
  readonly variant: ButtonVariant;
  readonly label: string;
  readonly fullWidth: boolean;
}

export interface Filter {
  readonly field: string;
  readonly operator: string;
  readonly values: ReadonlyArray<string>;
}

export interface ColumnOverride {
  readonly __typename: 'DataGridColumnOverride';
  readonly field: string;
  readonly width: number;
  readonly hidden: boolean;
}

export interface SortRule {
  readonly __typename: 'DataGridSorting';
  readonly field: string;
  readonly direction: 'ASC' | 'DESC';
}

export interface DataGridSettings {
  readonly __typename: 'DataGridSettings';
  readonly columns: ReadonlyArray<ColumnOverride>;
  readonly columnOrder: ReadonlyArray<string>;
  readonly sorting: ReadonlyArray<SortRule>;
  readonly pageSize: number;
}

export interface ConnectedGridConfigQueryResult {
  readonly __typename: 'DataGridResult';
  readonly id: string;
  readonly columns: ReadonlyArray<Column>;
  readonly rowActions: ReadonlyArray<RowAction> | null;
  readonly maxPageSize: number;
  readonly exportAllowed: boolean;
  readonly staticFilters: ReadonlyArray<Filter>;
  readonly localFiltersEnabled: boolean;
  readonly userSettings: DataGridSettings | null;
  readonly metaData: ReadonlyArray<DataGridMetaData>;
}

export interface ConnectedGridConfigQuery {
  [key: string]: ConnectedGridConfigQueryResult;
}

// the fields queried on the grid field for the config.
const configInnerQuery = `columns {
          label
          field
          type
          width
          alternativeSortingField
          exactMatch
          multiCheck
          hidden
          sortable
        }
        rowActions {
          id
          variant
          label
          fullWidth
        }
        maxPageSize
        exportAllowed
        staticFilters {
          field
          operator
          values
        }
        localFiltersEnabled
        userSettings {
          columns {
            field
            width
            hidden
          }
          columnOrder
          sorting {
            field
            direction
          }
          pageSize
        }
        metaData {
          key
          value
        }`;

export const makeConnectedGridConfigQuery = (
  queryName: string,
  gridQueryVariables?: GridQueryVariables,
) =>
  gqlFuncAliasNotCodegenParsed(`
    query ConnectedGridConfigQuery${constructGridQueryDefinition(gridQueryVariables)}
    {
      ${constructGridQueryBody(queryName, gridQueryVariables, configInnerQuery)}
    }
  `);

export default function useConnectedGridConfigQuery(
  queryName: string,
  gridQueryVariables?: GridQueryVariables,
  queryOptions?: QueryHookOptions<ConnectedGridConfigQuery>,
) {
  // create the query from the string literal in the usual way
  const query = useMemo(
    () => makeConnectedGridConfigQuery(queryName, gridQueryVariables),
    [queryName, gridQueryVariables],
  );

  // create an options object with variables inside if needed
  const options = {
    ...(gridQueryVariables?.length
      ? {
          variables: constructGridQueryVariables(gridQueryVariables),
        }
      : {}),
    ...queryOptions,
  };

  return useQuery<ConnectedGridConfigQuery>(query, options);
}
