import styled from '@emotion/styled';
import { Col, Container, Row } from 'react-grid-system';
import NumberFormat from 'react-number-format';
import { BORDER_RADIUS, BORDER_WIDTH } from '../../styles/borders';
import { MEDIA_QUERY } from '../../styles/breakpoints';
import { COLOR, GREYSCALE } from '../../styles/colors';
import { SPACING } from '../../styles/spacing';
import { TYPOGRAPHY } from '../../styles/typography';
import { ChildrenProps, ContainerProps } from './Grid';

export const StyledPurchaseRow = styled(Row)<{ type?: 'red' | 'green' | 'darker' }>`
  font-size: ${TYPOGRAPHY.fontSize.sm};
  line-height: ${TYPOGRAPHY.body.lineHeight};

  border-color: ${GREYSCALE.grey30};
  border-style: solid;
  border-width: ${BORDER_WIDTH.none} ${BORDER_WIDTH.none} ${BORDER_WIDTH.xs} ${BORDER_WIDTH.none};

  &:last-of-type {
    border-bottom-width: ${BORDER_WIDTH.none};
  }

  background-color: ${({ type }) => {
    switch (type) {
      case 'red':
        return COLOR.lightRed;
      case 'green':
        return COLOR.lightGreen;
      case 'darker':
        return GREYSCALE.grey20;
      default:
        return 'auto';
    }
  }};

  color: ${({ type }) => {
    switch (type) {
      case 'red':
        return COLOR.red;
      case 'green':
        return COLOR.green;
      default:
        return 'inherit';
    }
  }};

  // overwrite for smaller input with 14px font size
  label,
  input,
  select {
    font-size: ${TYPOGRAPHY.fontSize.sm};
  }
  input[type='text'] {
    font-weight: ${TYPOGRAPHY.fontWeight.medium};
    padding-top: 2px;
  }
`;
export const StyledPurchaseCol = styled(Col)`
  padding: ${SPACING.md};
`;
export const StyledPurchaseFlexCol = styled(Col)`
  padding: ${SPACING.md};
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5em 1em;
  flex-wrap: wrap;
`;
export const StyledPurchaseSubCol = styled(Col)`
  @media (max-width: ${MEDIA_QUERY.smMax}) {
    margin-bottom: ${SPACING.md};
  }
`;
export const StyledPurchaseAlignRightCol = styled(Col)`
  padding: ${SPACING.md};
  text-align: right;
`;

const Styled = {
  PurchaseWrapper: styled(Container)`
    border: ${BORDER_WIDTH.sm} solid ${GREYSCALE.grey30};
    border-radius: ${BORDER_RADIUS.sm};
    background-color: ${GREYSCALE.grey10};
    margin-bottom: ${SPACING.xxl};
  `,
  PurchaseNumberFormat: styled(NumberFormat)`
    font-weight: ${TYPOGRAPHY.fontWeight.medium};
  `,
  PurchaseDescriptionLabel: styled.div`
    font-weight: ${TYPOGRAPHY.fontWeight.medium};
  `,
  PurchaseDescriptionSubLabel: styled.div<{ isError?: boolean }>`
    color: ${({ isError }) => (isError ? COLOR.red : 'auto')};
    margin-left: auto;
  `,
};

export function PurchaseWrapper({ children, ...others }: ChildrenProps & ContainerProps) {
  return (
    <Styled.PurchaseWrapper fluid {...others}>
      {children}
    </Styled.PurchaseWrapper>
  );
}

export function PurchaseDescriptionLabel({ children }: ChildrenProps) {
  return <Styled.PurchaseDescriptionLabel>{children}</Styled.PurchaseDescriptionLabel>;
}

type PurchaseDescriptionSubLabelProps = {
  isError?: boolean;
};

export function PurchaseDescriptionSubLabel({
  children,
  isError,
}: ChildrenProps & PurchaseDescriptionSubLabelProps) {
  return (
    <Styled.PurchaseDescriptionSubLabel isError={isError}>
      {children}
    </Styled.PurchaseDescriptionSubLabel>
  );
}

type PurchaseNumberFormatProps = {
  value: number;
  id?: string;
};

export function PurchaseNumberFormat({ value, id }: PurchaseNumberFormatProps) {
  return (
    <Styled.PurchaseNumberFormat
      value={value}
      id={id}
      displayType="text"
      thousandSeparator
      prefix="$"
      decimalScale={2}
      fixedDecimalScale
    />
  );
}
