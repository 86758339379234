import { useMutation } from '@apollo/client';
import useBus from 'use-bus';
import { Challenge } from '../../constants';
import succeedAtChallengeMutation from '../operations/succeedAtChallenge.mutation';

const useRegisterChallengeBus = (key: Challenge) => {
  const [succeedAtChallenge] = useMutation(succeedAtChallengeMutation);
  useBus(key, () => succeedAtChallenge({ variables: { key } }));
};

const useRegisterChallengesBus = () => {
  // Register each challenge once when the hook is called
  useRegisterChallengeBus(Challenge.AddPaymentMethod);
  useRegisterChallengeBus(Challenge.ConnectStore);
  useRegisterChallengeBus(Challenge.EditTracking);
  useRegisterChallengeBus(Challenge.PlaySounds);
  useRegisterChallengeBus(Challenge.ShipPackage);
  useRegisterChallengeBus(Challenge.VisitGeneralSettingsPage);
  useRegisterChallengeBus(Challenge.VisitHelpGeneral);
  useRegisterChallengeBus(Challenge.VisitHelpPrint);
  useRegisterChallengeBus(Challenge.VisitRatesPage);
  useRegisterChallengeBus(Challenge.WatchVideoGeneral);
  useRegisterChallengeBus(Challenge.WatchVideoV2);
};

export default useRegisterChallengesBus;
