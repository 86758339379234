import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import formatDate from 'date-fns/format';
import parseIsoDate from 'date-fns/parseISO';
import { ChangeEvent, useId } from 'react';
import { DATE_FORMAT } from '../../../constants';
import LinkedIconTooltip from '../../LinkedIconTooltip';
import FormControl from '../../form/FormControl';
import Label from '../../form/Label';
import Select from '../../form/Select';
import {
  StyledPurchaseAlignRightCol,
  StyledPurchaseCol,
  StyledPurchaseRow,
} from '../../layout/PurchaseGrid';
import { NamespacedSubform } from '../types';

export type ShipDateSettingRowValues = {
  date: string;
};

export type ShipDateSettingRowProps<NS extends string> = NamespacedSubform<NS> & {
  availableShipDates: string[];
  onShipDateChange?: (shipDate: string) => void;
};

export default function ShipDateSettingRow<NS extends string>({
  namespace,
  availableShipDates,
  onShipDateChange,
}: ShipDateSettingRowProps<NS>) {
  const dateId = useId();

  return (
    <StyledPurchaseRow align="center">
      <StyledPurchaseCol xs={3.7} sm={4} md={7.5}>
        <Label htmlFor={dateId}>Ship Date</Label>
        <LinkedIconTooltip href="https://support.pirateship.com/en/articles/2235933-can-i-choose-my-ship-date">
          You can select a Ship Date up to 7 days in advance. Click the{' '}
          <FontAwesomeIcon icon="circle-question" /> below to learn more 👍
        </LinkedIconTooltip>
      </StyledPurchaseCol>
      <StyledPurchaseAlignRightCol xs={8.3} sm={8} md={4.5}>
        <FormControl
          id={dateId}
          as={Select}
          name={`${namespace}.date`}
          options={availableShipDates.map((date) => ({
            value: date,
            title: formatDate(
              parseIsoDate(date),
              `${DATE_FORMAT.dayOfWeek}, ${DATE_FORMAT.usDate}`,
            ),
          }))}
          onChange={(event: ChangeEvent<HTMLSelectElement>) =>
            onShipDateChange?.(event.target.value)
          }
          data-dd-action-name="Ship Date (Dropdown)"
        />
      </StyledPurchaseAlignRightCol>
    </StyledPurchaseRow>
  );
}
