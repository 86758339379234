import { useMutation } from '@apollo/client';
import { gql } from '../../gql';

export const createBatchFromUpload = gql(/* GraphQL */ `
  mutation CreateBatchFromUploadMutation($mapping: [ShipmentMappingInput!]!, $uploadId: ID!) {
    createBatchFromUpload(mapping: $mapping, uploadId: $uploadId) {
      id
      upload {
        id
        mapping {
          valueOrMappingKey
          shipmentKey
          userFriendlyShipmentKey
        }
      }
    }
  }
`);

export const useCreateBatchFromUploadMutation = () => useMutation(createBatchFromUpload);
