import isEmpty from 'lodash.isempty';
import getShipmentOrderLink, { Order } from '../../utils/shipmentLink';
import { ExternalLink } from '../Link';
import Section, { SectionEntries } from './Section';

type OrderInformationSectionProps = {
  order?:
    | ({
        platform: {
          title: string;
        };
        foreignOrderId: string;
      } & Order)
    | null;
  hideIf?: boolean;
  shipmentForeignOrderId?: string;
};

function OrderInformationSection({
  order,
  hideIf,
  shipmentForeignOrderId,
}: OrderInformationSectionProps) {
  if (hideIf) return null;

  // it is possible that the shipment order is not created (if a spreadsheet is imported and just the order id is mapped)
  // in this edge case the order ID comes in directly via the shipment
  if (shipmentForeignOrderId && isEmpty(order)) {
    return <Section title="Order Information" entries={[['Order ID', shipmentForeignOrderId]]} />;
  }

  if (!order || isEmpty(order)) return null;

  const platformTitle = order.platform.title;

  // if order links do not exist, render the order info with the foreign order ID
  // in this case the order corresponds to an entry from our database
  if (!getShipmentOrderLink(order)) {
    return (
      <Section
        title="Order Information"
        entries={[[`${platformTitle} Order`, `${order?.foreignOrderId}`]]}
      />
    );
  }

  const entries: SectionEntries = [
    [
      `${platformTitle} Order`,
      order.orderLink?.url ? (
        <ExternalLink href={order.orderLink?.url} target="_blank" rel="noopener noreferrer">
          {order?.orderLink?.text}
        </ExternalLink>
      ) : null,
    ],
    [
      `${platformTitle} Subscription`,
      order.subscriptionLink?.url ? (
        <ExternalLink href={order.subscriptionLink?.url} target="_blank" rel="noopener noreferrer">
          {order?.subscriptionLink?.text}
        </ExternalLink>
      ) : null,
    ],
    [`${platformTitle} Shipment`, order.shipmentLink?.text],
  ];
  return <Section title="Order Information" entries={entries} />;
}
export default OrderInformationSection;
