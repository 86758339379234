import { ApolloError } from '@apollo/client';
import styled from '@emotion/styled';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useIntercom } from 'react-intercom-hook';
import * as yup from 'yup';
import { addFlashMessage, setFlashMessage } from '../../../apollo/cache/flashMessages';
import useAriaAnnouncer from '../../../hooks/useAriaAnnouncer';
import { useRefundCreditFromPaymentSource } from '../../../operations/mutations/refundCreditFromPaymentSource';
import { billingPageQuery, useBillingPageQuery } from '../../../operations/queries/billingPage';
import { GREYSCALE } from '../../../styles/colors';
import { SPACING } from '../../../styles/spacing';
import { TYPOGRAPHY } from '../../../styles/typography';
import { formatCurrency } from '../../../utils/currency';
import formatPaymentSourceTitle from '../../../utils/formatPaymentSourceTitle';
import Alert from '../../Alert';
import { FakeLink } from '../../Link';
import FormControl from '../../form/FormControl';
import InputGroup from '../../form/InputGroup';
import Label from '../../form/Label';
import ProgressButton from '../../form/ProgressButton';
import Select, { Option } from '../../form/Select';
import Text from '../../form/Text';
import TextField from '../../form/TextField';
import { Col, PageContainer, Row } from '../../layout/Grid';
import PageHeader from '../../layout/PageHeader';
import PageSectionDescription from '../../layout/PageSectionDescription';
import PageSubtitle from '../../layout/PageSubtitle';
import PageLoading from '../../loading/PageLoading';

const Styled = {
  Col: styled(Col)`
    margin-block-start: calc(
      (${TYPOGRAPHY.body.fontSize} * ${TYPOGRAPHY.body.lineHeight}) + (2 * ${SPACING.sm})
    );
  `,
  CurrencyInput: styled(FormControl)`
    text-align: right;
  `,
  ShiftedSubheading: styled(PageSubtitle)`
    color: ${GREYSCALE.grey60};
    font-size: ${TYPOGRAPHY.fontSize.md};
    font-weight: normal;
    margin-top: -${SPACING.xl};
  `,
};

type RefundValues = {
  paymentSourceId: string;
  refundCreditAmount: number | '';
};

function RefundCreditPage() {
  const announce = useAriaAnnouncer();
  const { data, loading } = useBillingPageQuery();
  const [refundInProgress, setRefundInProgress] = useState<boolean>(false);
  const { show: showIntercom } = useIntercom();
  const [refundCreditFromPaymentSource] = useRefundCreditFromPaymentSource();

  if (!data || loading) {
    return (
      <PageContainer>
        <PageLoading />
      </PageContainer>
    );
  }

  const { accountBalance } = data.company;
  const paymentSourceArray = data.company.paymentSources;

  const accountPaymentSourceOptions: Option[] = data.company.paymentSources
    .filter((source) => source.refundableAmount > 0)
    .map((paymentSource) => ({
      value: paymentSource.id,
      title: formatPaymentSourceTitle(paymentSource),
    }));

  // temporary fix. we are planning to completely refactor the UI of the refund mechanism
  // for the specified case, the user shouldn't even be on this page... :-(
  // PP-14746 PayPal option is not showing up under Payment Source for Refund Credit
  const hasExpiredRefundDateRange =
    accountPaymentSourceOptions.length === 0 && data.company.paymentSources.length === 1;

  if (hasExpiredRefundDateRange) {
    accountPaymentSourceOptions[0] = {
      value: data.company.paymentSources[0].id,
      title: formatPaymentSourceTitle(data.company.paymentSources[0]),
    };
  }

  const refundableCredit = (payId: string | number) => {
    const paymentSource = paymentSourceArray.find((source) => source.id === payId);
    if (paymentSource === undefined) {
      return 0;
    }
    return paymentSource.refundableAmount;
  };

  const initialPaymentSourceId = (() => {
    if (accountPaymentSourceOptions.length > 1) {
      return data.company.settings.defaultPaymentSourceId;
    }
    if (accountPaymentSourceOptions.length === 1) {
      return String(accountPaymentSourceOptions[0].value);
    }
    return '';
  })();

  const initialValues: RefundValues = {
    paymentSourceId: initialPaymentSourceId,
    refundCreditAmount: '',
  };

  return (
    <PageContainer>
      {hasExpiredRefundDateRange && (
        <Alert variant="danger">
          Your payment source with the name {accountPaymentSourceOptions[0].title} has no refundable
          credits.{' '}
          <FakeLink
            onClick={() => {
              showIntercom();
            }}
          >
            Chat with our support
          </FakeLink>{' '}
          if you have questions!
        </Alert>
      )}
      <PageHeader title="Refund Credit from your Pirate Ship account" />
      <Styled.ShiftedSubheading>
        Current balance: {formatCurrency(accountBalance)}
      </Styled.ShiftedSubheading>

      <PageSectionDescription>
        Pirate Ship can only refund payments you’ve made in the past, so refunds are only possible
        to the payment method that was originally used, up to the total amount you’ve ever paid with
        that payment method.
        <br />
        <br />
        This means that depending on the amount of the refund and which payment method you’re trying
        to refund it to, you may see multiple transactions come though, matching payments you’ve
        made in the past to total up to the requested amount.
        <br />
        <br />
        If you have more credit than you’ve made in past payments, just chat with our Support Crew
        to request a manual refund via PayPal or check 👍
      </PageSectionDescription>

      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={yup.object<RefundValues>({
          paymentSourceId: yup.string().required(),
          refundCreditAmount: yup
            .number()
            .min(0.01)
            .required()
            .when('paymentSourceId', (paymentSourceId: string, schema: yup.StringSchema) =>
              schema.max(
                refundableCredit(paymentSourceId),
                `${formatCurrency(refundableCredit(paymentSourceId))} maximum`,
              ),
            ),
        })}
        onSubmit={async ({ paymentSourceId, refundCreditAmount }) => {
          setRefundInProgress(true);
          try {
            await refundCreditFromPaymentSource({
              variables: {
                paymentSourceId,
                refundCreditAmount: Number(refundCreditAmount),
              },
              refetchQueries: [billingPageQuery],
              awaitRefetchQueries: true,
            });
            const message = `${formatCurrency(
              Number(refundCreditAmount),
            )} has been refunded from your account to ${
              accountPaymentSourceOptions.find((option) => option.value === paymentSourceId)?.title
            }`;
            addFlashMessage(message, 'success');
            announce(message, 'polite');
          } catch (error) {
            if (error instanceof ApolloError) {
              error.graphQLErrors.forEach((err) => {
                setFlashMessage(err.message, 'danger');
                announce(err.message, 'assertive');
              });
            }
          } finally {
            setRefundInProgress(false);
          }
        }}
      >
        {({ values }) => (
          <Form>
            <Row>
              <Col md={6} xs={12}>
                <Label>Payment Source</Label>
                <FormControl
                  loading={loading}
                  name="paymentSourceId"
                  as={Select}
                  options={accountPaymentSourceOptions}
                />
                <Text>
                  Maximum Refund available with this payment method:{' '}
                  {formatCurrency(refundableCredit(values.paymentSourceId))}
                </Text>
              </Col>
              <Col md={4} xs={12}>
                <Label>Amount</Label>
                <InputGroup prefixIcon="dollar-sign">
                  <Styled.CurrencyInput
                    disabled={hasExpiredRefundDateRange}
                    name="refundCreditAmount"
                    as={TextField}
                    type="number"
                    placeholder="1.00"
                  />
                </InputGroup>
              </Col>
              <Styled.Col md={2} xs={12}>
                <ProgressButton
                  variant="primary"
                  size="medium"
                  type="submit"
                  disabled={refundInProgress || hasExpiredRefundDateRange}
                  progress={refundInProgress}
                >
                  Request Refund
                </ProgressButton>
              </Styled.Col>
            </Row>
          </Form>
        )}
      </Formik>
    </PageContainer>
  );
}

export default RefundCreditPage;
