import styled from '@emotion/styled';
import { COLOR, GREYSCALE } from '../../../styles/colors';
import { OPACITY } from '../../../styles/opacities';
import { TYPOGRAPHY } from '../../../styles/typography';

export const DatePickerFieldWrapper = styled.div<{ disabled: boolean }>`
  opacity: ${({ disabled }) => (disabled ? OPACITY.disabled : 1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'auto')};
`;

export const DatePickerFieldToggle = styled.div`
  align-items: center;
  cursor: pointer;
  font-size: ${TYPOGRAPHY.fontSize.sm};
  user-select: none;
`;

export const DatePickerFieldToggleWrapper = styled.div<{ disabled: boolean }>`
  color: ${({ disabled }) => (disabled ? GREYSCALE.grey50 : COLOR.blue)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`;
