import { useSuspenseQuery } from '@apollo/client';
import { useCurrentUser } from '@ps/authentication';
import { Form, Formik } from 'formik';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router';
import * as yup from 'yup';
import { setFlashMessage } from '../../../apollo/cache/flashMessages';
import IntercomArticleLink from '../../../components/IntercomArticleLink';
import { BottomRowActions } from '../../../components/form/BottomRowActions';
import FormControl from '../../../components/form/FormControl';
import Label from '../../../components/form/Label';
import TextField from '../../../components/form/TextField';
import { Col, PageContainer, Row } from '../../../components/layout/Grid';
import PageHeader from '../../../components/layout/PageHeader';
import PageTitle from '../../../components/layout/PageTitle';
import ConfirmationModal from '../../../components/modals/ConfirmationModal';
import ShipFromAddressSubform, {
  ShipFromAddressSubformValues,
  initialShipFromAddressSubformValues,
  shipFromAddressValidationSchema,
  useShipFromAddress,
} from '../../../components/subforms/ShipFromAddressSubform';
import { INITIAL_WAREHOUSE_SUBFORM_VALUES } from '../../../components/subforms/WarehouseSubform';
import useNavigateOrHref from '../../../hooks/useNavigateOrHref';
import { useDeleteBatchMutation } from '../../../operations/mutations/deleteBatch.mutation';
import getFilteredPackageTypes from '../../../utils/getFilteredPackageTypes';
import PackagePresetSubform, {
  getDefaultPreset,
  initialPackagePresetSubformValues,
  packagePresetValidationSchema,
  usePackagePreset,
  type PackagePresetSubformValues,
} from '../../components/PackagePresetSubform';
import { batchUploadFormQuery } from '../../operation/batchUploadForm.query';
import { useCompleteBatchFromUploadMutation } from '../../operation/completeBatchFromUpload.mutation';

type UploadFormPageProps = {
  entityId?: string;
};

type ValidNamespaces = keyof UploadFormPageValues;

export type UploadFormPageValues = {
  title: string;
  shipFromAddress: ShipFromAddressSubformValues;
  packagePreset: PackagePresetSubformValues;
};

export default function UploadFormPage({ entityId }: UploadFormPageProps) {
  const { batchId } = useParams<'batchId'>();
  const id = entityId || batchId || '';
  const { data } = useSuspenseQuery(batchUploadFormQuery, {
    variables: { id },
  });
  const {
    company: {
      shipmentPresets,
      settings: { defaultShipmentPresetId },
    },
    batch: {
      customsFormRequired: isCustomsFormRequired,
      hsCodeRequired: isHsCodeRequired,
      packageSummary: { packagePreset },
    },
    shipmentBoundaries,
  } = data;
  const is2x7LabelSize = data.batch.labelSize === 'SIZE_2x7';
  const packageTypes = useMemo(() => getFilteredPackageTypes(data), [data]);
  const [deleteBatch, { loading: isDeletingBatch }] = useDeleteBatchMutation();
  const [getWarehouseId, { loading: isSavingWarehouse }] = useShipFromAddress();
  const [getShipmentPresetId, { loading: isSavingPreset }] = usePackagePreset();
  const [completeBatchFromUploadMutation, { loading: isCompletingBatch }] =
    useCompleteBatchFromUploadMutation();
  const [currentUser] = useCurrentUser();
  const userName = currentUser ? `${currentUser?.firstName} ${currentUser?.lastName}` : '';

  const navigateOrHref = useNavigateOrHref();
  const [deleteBatchModalOpen, setDeleteBatchModalOpen] = useState(false);

  const uploadId = data.batch.upload?.id || '0';

  const initialShipmentPreset =
    packagePreset ?? getDefaultPreset(defaultShipmentPresetId, shipmentPresets, packageTypes);

  const isLoading = isSavingWarehouse || isSavingPreset || isDeletingBatch || isCompletingBatch;

  return (
    <PageContainer>
      <ConfirmationModal
        title="Delete Labels?"
        open={deleteBatchModalOpen}
        onCancel={() => setDeleteBatchModalOpen(false)}
        onConfirm={async () => {
          try {
            await deleteBatch({ variables: { id } });
            setFlashMessage(`Batch Deleted`, 'success');
            navigateOrHref('/ship');
          } catch (error) {
            setFlashMessage((error as Error).message, 'danger');
          }
        }}
        confirmationType="destructive"
        cancelButtonLabel="Cancel"
        confirmationButtonLabel="Continue"
        confirmationButtonProgress={isDeletingBatch}
      />
      <Formik<UploadFormPageValues>
        initialValues={{
          title: data.batch.title,
          shipFromAddress: initialShipFromAddressSubformValues(
            data.company.settings.defaultWarehouseId,
          ),
          packagePreset: initialPackagePresetSubformValues({
            preset: initialShipmentPreset,
            userName,
            isCustomsFormRequired,
            is2x7LabelSize,
          }),
        }}
        validationSchema={yup
          .object<UploadFormPageValues>({
            title: yup.string().required(),
            shipFromAddress: shipFromAddressValidationSchema().required(),
            packagePreset: packagePresetValidationSchema({
              isCustomsFormRequired,
              isHsCodeRequired,
              is2x7LabelSize,
              shipmentBoundaries,
              packageTypes,
            }).required(),
          })
          .required()}
        onSubmit={async (values, { setValues }) => {
          if (isLoading) {
            return;
          }

          let warehouseId = values.shipFromAddress.warehouseId;
          let shipmentPresetId = values.packagePreset.shipmentPresetId;

          try {
            // Step 1: Use or create warehouse
            warehouseId = await getWarehouseId(values.shipFromAddress);

            // Step 2: Use, create or update Shipment Preset
            shipmentPresetId = await getShipmentPresetId(
              values.packagePreset,
              packageTypes,
              defaultShipmentPresetId,
            );

            // Step 3: Complete batch with new/existing warehouse and new/scoped/updated preset
            await completeBatchFromUploadMutation({
              variables: {
                batchId: id,
                batchTitle: values.title,
                warehouseId,
                shipmentPresetId,
              },
            });

            navigateOrHref(`/batch/${id}`);
          } catch (error) {
            if (error instanceof Error) {
              setFlashMessage(error.message, 'danger');
            }
          } finally {
            // Update the form state if either warehouse or preset were changed
            if (
              values.shipFromAddress.warehouseId !== warehouseId ||
              values.packagePreset.shipmentPresetId !== shipmentPresetId
            ) {
              await setValues({
                ...values,
                shipFromAddress: {
                  warehouseId,
                  // Clear the warehouse subform values
                  warehouse: { ...INITIAL_WAREHOUSE_SUBFORM_VALUES },
                },
                packagePreset: {
                  ...values.packagePreset,
                  shipmentPresetId,
                },
              });
            }
          }
        }}
      >
        <Form>
          <Row>
            <Col>
              <PageHeader
                title={
                  <div>
                    <PageTitle>Import from Upload</PageTitle>
                    <p>
                      If you’ve{' '}
                      <IntercomArticleLink href="https://support.pirateship.com/en/articles/2797613-how-do-i-upload-a-spreadsheet-with-different-weights-and-dimensions#step-2-upload-your-spreadsheet-to-pirate-ship">
                        mapped values from your spreadsheet for dimension and weight
                      </IntercomArticleLink>
                      , enter any value below in those fields. Your spreadsheet data will overwrite
                      what is entered here 👍
                    </p>
                  </div>
                }
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Label>Batch Name</Label>
            </Col>
          </Row>
          <Row spaceBelow>
            <Col>
              <FormControl as={TextField} name="title" />
            </Col>
          </Row>
          <ShipFromAddressSubform<ValidNamespaces>
            namespace="shipFromAddress"
            countries={data.countries}
            warehouses={data.company.warehouses}
          />
          <PackagePresetSubform<ValidNamespaces>
            namespace="packagePreset"
            shipmentPresets={data.company.shipmentPresets}
            packageTypes={packageTypes}
            is2x7LabelSize={is2x7LabelSize}
            isCustomsFormRequired={isCustomsFormRequired}
          />
          <BottomRowActions
            primaryAction={{
              type: 'submit',
              title: 'Get Rates',
              variant: 'success',
              size: 'xLarge',
              progress: isLoading,
            }}
            secondaryActions={[
              {
                title: 'Previous Step',
                variant: 'primary',
                onClick: () =>
                  navigateOrHref(
                    `/upload/map/file/${uploadId}`, // ShipmentWorkflow feature flag is turned on
                    `/upload/map?file=${uploadId}`, // ShipmentWorkflow feature flag is turned off
                  ),
              },
              {
                title: 'Cancel & Deleting Labels',
                variant: 'danger',
                onClick: () => setDeleteBatchModalOpen(true),
              },
            ]}
          />
        </Form>
      </Formik>
    </PageContainer>
  );
}
