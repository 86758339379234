import { css } from '@emotion/react';
import styled from '@emotion/styled';
import type { IconName } from '@fortawesome/fontawesome-svg-core';
import { KeyboardEvent, MouseEvent, ReactNode } from 'react';
import { GREYSCALE } from '../../../styles/colors';
import Icon from '../../Icon';

const Styled = {
  ColumnMenuItem: styled.div<{ open?: boolean }>`
    background-color: ${GREYSCALE.grey30};

    :hover {
      background-color: ${GREYSCALE.grey00};
      color: ${GREYSCALE.grey60};
    }

    ${({ open }) =>
      open &&
      css`
        background-color: ${GREYSCALE.grey00};
        color: ${GREYSCALE.grey60};
      `}

    :focus-visible {
      outline: none;
    }
  `,
  ItemLabel: styled('div')`
    padding: 0.5em 1.1em 0.4em;
    line-height: 1.34em;
    cursor: pointer;
  `,
  ItemIcon: styled(Icon)`
    margin-right: 10px;
  `,
  ItemExpandIcon: styled(Icon)`
    float: right;
  `,
};

export type ColumnMenuItemProps = {
  label: string;
  icon: IconName;
  expandable?: boolean;
  onClick?: (event: MouseEvent) => void;
  onKeyDown?: (event: KeyboardEvent) => void;
  open?: boolean;
  children?: () => ReactNode;
};

export default function ColumnMenuItem({
  label,
  icon,
  expandable,
  onClick,
  onKeyDown,
  open,
  children,
}: ColumnMenuItemProps) {
  return (
    <Styled.ColumnMenuItem
      role="menuitem"
      onClick={onClick}
      onKeyDown={onKeyDown}
      // Do not bubble scroll event as it would lead to the column menu being closed
      onScroll={(event) => event.stopPropagation()}
      tabIndex={0}
      open={open}
    >
      <Styled.ItemLabel>
        <Styled.ItemIcon icon={icon} />
        {label}
        {expandable && !open && <Styled.ItemExpandIcon icon="caret-down" />}
      </Styled.ItemLabel>
      {open && children && children()}
    </Styled.ColumnMenuItem>
  );
}
