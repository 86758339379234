import styled from '@emotion/styled';
import Collapsible from '../../components/layout/Collapsible';
import { Col, Row } from '../../components/layout/Grid';
import BatchCustomsFormSection, {
  BatchCustomsFormSummaryProps,
} from '../../components/shipmentDetailsBox/BatchCustomsFormSection';
import BatchPassthroughDataSection from '../../components/shipmentDetailsBox/BatchPassthroughDataSection';
import LabelDetailsSection from '../../components/shipmentDetailsBox/LabelDetailsSection';
import PackageDetailsSection, {
  PackageSummary,
  SpreadsheetMapping,
} from '../../components/shipmentDetailsBox/PackageDetailsSection';
import ReturnAddressSection from '../../components/shipmentDetailsBox/ReturnAddressSection';
import RubberStampsBatchSection, {
  RubberStampsBatchSectionProps,
} from '../../components/shipmentDetailsBox/RubberStampsBatchSection';
import ShipFromAddressSection from '../../components/shipmentDetailsBox/ShipFromAddressSection';
import ShipmentSummarySection from '../../components/shipmentDetailsBox/ShipmentSummarySection';
import type { ShipmentStatusSummary } from '../../gql/graphql';
import { GREYSCALE } from '../../styles/colors';
import { SPACING } from '../../styles/spacing';
import { CountryMap } from '../../utils/createCountryMap';
import { Address } from '../../utils/formatAddress';

const Styled = {
  Container: styled.div`
    margin-bottom: ${SPACING.xl};
  `,
  IconWrapper: styled.span`
    margin: ${SPACING.none} ${SPACING.sm};
    line-height: ${SPACING.none};
  `,
  PlatformIconHeader: styled.div`
    display: flex;
    margin-left: ${SPACING.sm};
  `,
  Title: styled.div`
    color: ${GREYSCALE.grey50};
    position: relative;
    top: 2px;
  `,
  TrackingNumberWrapper: styled.div`
    display: flex;
    gap: ${SPACING.sm};
    padding-left: ${SPACING.md};
    color: ${GREYSCALE.grey60};
  `,
};

type AddressWithId = Address & { id: string };

type Batch = {
  numShipments: number;
  statusText: string;
  warehouse: {
    shy: boolean;
    title: string;
    originAddress: AddressWithId;
    returnAddress: AddressWithId;
  };
  shipmentStatusSummary: Partial<ShipmentStatusSummary>;
  packageSummary: PackageSummary;
  dataSource: string;
  spreadsheetMapping: SpreadsheetMapping;
  customsFormSummary: BatchCustomsFormSummaryProps['customsFormSummary'];
  labelSize: string;
  labelFileFormat: string;
  rubberStamps: RubberStampsBatchSectionProps['rubberStamps'];
};

type BatchDetailsPageShipmentDetailsBoxProps = {
  batch: Batch;
  countryMap: CountryMap;
};

export default function BatchDetailsPageShipmentDetailsBox({
  batch,
  countryMap,
}: BatchDetailsPageShipmentDetailsBoxProps) {
  const renderSupplementaryHeader = () => (
    <Styled.TrackingNumberWrapper>
      {`Multiple Labels (${batch.numShipments})`} / {batch.statusText}
    </Styled.TrackingNumberWrapper>
  );

  return (
    <Styled.Container id="shipment-info">
      <Collapsible label="Shipment Details" customContent={renderSupplementaryHeader()}>
        <Row>
          <Col md={4}>
            <ShipFromAddressSection
              isWarehouseShy={batch.warehouse.shy}
              title={batch.warehouse.title}
              address={batch.warehouse.originAddress}
              countryMap={countryMap}
            />
            <ReturnAddressSection
              hideIf={batch.warehouse.returnAddress.id === batch.warehouse.originAddress.id}
              address={batch.warehouse.returnAddress}
              countryMap={countryMap}
            />
            <ShipmentSummarySection shipmentStatusSummary={batch.shipmentStatusSummary} />
          </Col>
          <Col md={4}>
            <PackageDetailsSection
              packageSummary={batch.packageSummary}
              dataSource={batch.dataSource}
              spreadsheetMapping={batch.spreadsheetMapping}
            />
            <BatchCustomsFormSection customsFormSummary={batch.customsFormSummary} />
          </Col>
          <Col md={4}>
            <LabelDetailsSection
              labelSize={batch.labelSize}
              labelFileFormat={batch.labelFileFormat}
            />
            <BatchPassthroughDataSection
              hideIf={batch.dataSource !== 'SPREADSHEET' || !batch.spreadsheetMapping.length}
              spreadsheetMapping={batch.spreadsheetMapping}
            />
            <RubberStampsBatchSection rubberStamps={batch.rubberStamps} />
          </Col>
        </Row>
      </Collapsible>
    </Styled.Container>
  );
}
