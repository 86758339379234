import * as yup from 'yup';
import { StringSchema } from 'yup';
import { DEFAULT_COUNTRY_CODE } from '../../../constants';
import { AddressSubformValues } from './AddressSubform';

const FULL_NAME_OR_COMPANY_ERROR_MESSAGE = 'Either name or company need to be specified';
export const INITIAL_ADDRESS_SUBFORM_VALUES: AddressSubformValues = {
  fullName: '',
  company: '',
  address1: '',
  address2: '',
  city: '',
  regionCode: '',
  postcode: '',
  countryCode: DEFAULT_COUNTRY_CODE,
  phone: '',
};

export const addressValidationSchema = ({
  nameRequired,
  fullNameSchema = yup.string().required(),
  companyNameSchema = yup.string().defined().default(''),
  phone,
  address1Schema = yup.string().required(),
}: {
  nameRequired?: boolean;
  fullNameSchema?: StringSchema<string>;
  companyNameSchema?: StringSchema<string>;
  phone?: 'US'; // Only option for now
  address1Schema?: StringSchema<string>;
} = {}) =>
  yup.object<AddressSubformValues>().shape(
    {
      fullName: nameRequired
        ? fullNameSchema
        : yup
            .string()
            .when(['fullName', 'company'], {
              is: (fullName, company) => !fullName?.length && !company?.length,
              then: () => yup.string().required(FULL_NAME_OR_COMPANY_ERROR_MESSAGE),
            })
            .default(''),
      company: nameRequired
        ? companyNameSchema
        : yup
            .string()
            .when(['fullName', 'company'], {
              is: (fullName, company) => !fullName?.length && !company?.length,
              then: () => yup.string().required(FULL_NAME_OR_COMPANY_ERROR_MESSAGE),
            })
            .default(''),
      address1: address1Schema,
      address2: yup.string().defined().default(''),
      city: yup.string().required(),
      regionCode: yup.string().required(),
      postcode: yup.string().usZip().required(),
      countryCode: yup.string().required(),
      phone: phone
        ? yup.string().phoneNumber({ format: phone }).required()
        : yup.string().notRequired(),
    },
    // explicitly allow [a => b] dependencies in the 'when' conditions above
    [
      ['company', 'company'],
      ['fullName', 'fullName'],
      ['company', 'fullName'],
      ['fullName', 'company'],
    ],
  );
