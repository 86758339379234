import { CountryMap } from '../../utils/createCountryMap';
import { Address, addressToLines } from '../../utils/formatAddress';
import Section, { SHOW_VALUE_ONLY, SectionEntries } from './Section';

type ReturnAddressSectionProps = {
  hideIf: boolean;
  address: Address;
  countryMap: CountryMap;
};

function ReturnAddressSection({ hideIf, address, countryMap }: ReturnAddressSectionProps) {
  const entries: SectionEntries = addressToLines(address, countryMap).map((value) => [
    SHOW_VALUE_ONLY,
    value,
  ]);

  return <Section title="Return Address" hideIf={hideIf} entries={entries} />;
}

export default ReturnAddressSection;
