import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  forwardRef,
  ReactNode,
  Ref,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { HORIZONTAL_SCROLLBAR_HEIGHT } from '../../../constants';
import useForwardedRef from '../../../hooks/useForwardedRef';
import useIsMobile from '../../../hooks/useIsMobile';
import useWindowSize from '../../../hooks/useWindowSize';
import { GREYSCALE } from '../../../styles/colors';
import { SPACING } from '../../../styles/spacing';
import withOpacity from '../../../utils/withOpacity';
import isHorizontallyScrolling from '../utils/isHorizontallyScrolling';

type ScrollableContainerProps = {
  showLeftShadow?: boolean;
  showRightShadow?: boolean;
};

const scrollbarStyle = css`
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    height: ${HORIZONTAL_SCROLLBAR_HEIGHT};
    background-color: #f5f5f5;
  }
`;

const scrollbarThumbStyle = css`
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #666666;
    box-shadow: 0 0 1px ${withOpacity(GREYSCALE.white, 0.5)};
  }
`;

const Styled = {
  ScrollableContainer: styled.div<ScrollableContainerProps>`
    position: relative;
    overflow: hidden;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      pointer-events: none;
      z-index: 1;
      width: 10px;
    }

    &:before {
      left: -10px;
      transition: left 100ms linear;
      background: black;
      background: linear-gradient(to right, #00000044, #00000000) no-repeat right;
    }

    &:after {
      right: -10px;
      transition: right 100ms linear;
      background: linear-gradient(to left, #00000044, #00000000) no-repeat left;
    }

    ${({ showLeftShadow }) =>
      showLeftShadow &&
      css`
        &:before {
          left: 0;
        }
      `}

    ${({ showRightShadow }) =>
      showRightShadow &&
      css`
        &:after {
          right: 0;
        }
      `}
  `,
  Scrollable: styled.div<{ addTablePadding: boolean }>`
    min-height: auto;
    overflow-x: auto;
    overflow-y: hidden;

    ${scrollbarStyle}
    ${scrollbarThumbStyle}

    ${({ addTablePadding }) =>
      addTablePadding &&
      css`
        table tbody {
          &:before {
            content: '';
            display: block;
            height: ${HORIZONTAL_SCROLLBAR_HEIGHT};
          }
        }
      `}
  `,
  HorizontalScrollbarScrollable: styled.div`
    height: ${HORIZONTAL_SCROLLBAR_HEIGHT};
    overflow-x: auto;
    overflow-y: hidden;
    position: absolute;
    top: ${SPACING.xxl};
    width: 100%;

    ${scrollbarStyle}
    ${scrollbarThumbStyle}
  `,
  FakeScrollableContent: styled.div`
    height: ${HORIZONTAL_SCROLLBAR_HEIGHT};
  `,
};

export type HorizontalScrollbarProps = {
  scrollWidth: number;
  scrollLeft: number;
  onScroll: (scrollLeft: number) => void;
};

/**
 * This component allows to render an additional horizontal scrollbar by providing an explicit scroll width
 * from another scrollable. Using scrollLeft and onScroll, this scrollbar can be synced to the main scrollbar.
 * This uses a fake content div that mimics the main scrollable content's size.
 */
function HorizontalScrollbar({ scrollWidth, scrollLeft, onScroll }: HorizontalScrollbarProps) {
  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (ref.current && ref.current.scrollLeft !== scrollLeft) {
      ref.current.scrollLeft = scrollLeft;
    }
  }, [scrollLeft]);

  return (
    <Styled.HorizontalScrollbarScrollable
      ref={ref}
      onScroll={(event) => onScroll((event.target as HTMLDivElement).scrollLeft)}
    >
      <Styled.FakeScrollableContent style={{ width: scrollWidth }} />
    </Styled.HorizontalScrollbarScrollable>
  );
}

// functions to check if the user has scrolled at all to the left or right
const hasScrolledRight = (el: HTMLElement) => el.scrollLeft > 0;
const hasScrolledLeft = (el: HTMLElement) => el.offsetWidth + el.scrollLeft < el.scrollWidth;

type HorizontalGridScrollWrapperProps = {
  children: ReactNode;
  setSelectedHeaderId?: (id: string | undefined) => void;
  tableTotalSize: number;
};

function HorizontalGridScrollWrapperComponent(
  { children, setSelectedHeaderId, tableTotalSize }: HorizontalGridScrollWrapperProps,
  ref: Ref<HTMLDivElement>,
) {
  const [windowWidth] = useWindowSize();
  const { isMobile } = useIsMobile();
  const [scrollableScrollLeft, setScrollableScrollLeft] = useState<number>(0);
  const [showleftShadow, setShowLeftShadow] = useState(false);
  const [showRightShadow, setShowRightShadow] = useState(false);
  const forwardedRef = useForwardedRef(ref);

  useLayoutEffect(() => {
    if (forwardedRef.current && forwardedRef.current.scrollLeft !== scrollableScrollLeft) {
      forwardedRef.current.scrollLeft = scrollableScrollLeft;
    }
  }, [scrollableScrollLeft, forwardedRef]);

  const updateScrollableShadows = useCallback(() => {
    if (forwardedRef.current) {
      setShowLeftShadow(hasScrolledRight(forwardedRef.current));
      setShowRightShadow(hasScrolledLeft(forwardedRef.current));
    }
  }, [forwardedRef]);

  // if the user adds or removes columns, update the shadows
  useLayoutEffect(() => {
    if (forwardedRef.current) {
      if (tableTotalSize < forwardedRef.current.clientWidth) {
        setShowLeftShadow(false);
        setShowRightShadow(false);
        return;
      }
      setShowLeftShadow(hasScrolledRight(forwardedRef.current));
      setShowRightShadow(hasScrolledLeft(forwardedRef.current));
    }
  }, [forwardedRef, tableTotalSize]);

  // Show scroll shadows once the scrollable element is mounted
  // and recalculate on window resizes and column width changes
  useEffect(updateScrollableShadows, [updateScrollableShadows, windowWidth]);

  return (
    <Styled.ScrollableContainer showLeftShadow={showleftShadow} showRightShadow={showRightShadow}>
      {/* Top Scrollbar (not rendered on mobile) */}
      {!isMobile && isHorizontallyScrolling(forwardedRef) && (
        <HorizontalScrollbar
          scrollWidth={forwardedRef.current?.scrollWidth || 0}
          scrollLeft={scrollableScrollLeft}
          onScroll={(scrollLeft) => setScrollableScrollLeft(scrollLeft)}
        />
      )}
      <Styled.Scrollable
        ref={forwardedRef}
        addTablePadding={!isMobile && isHorizontallyScrolling(forwardedRef)}
        onScroll={(event) => {
          updateScrollableShadows();
          setScrollableScrollLeft((event.target as HTMLDivElement).scrollLeft);
          if (setSelectedHeaderId) setSelectedHeaderId(undefined);
        }}
      >
        {children}
      </Styled.Scrollable>
    </Styled.ScrollableContainer>
  );
}

const HorizontalGridScrollWrapper = forwardRef(HorizontalGridScrollWrapperComponent);
export default HorizontalGridScrollWrapper;
