import { LazyQueryHookOptions, QueryHookOptions, useLazyQuery, useQuery } from '@apollo/client';
import { gql } from '../../gql';
import { PaymentChartQuery, PaymentChartQueryVariables } from '../../gql/graphql';

export const paymentChartQuery = gql(/* GraphQL */ `
  query PaymentChartQuery($datetimeRange: DatetimeRangeInput!) {
    paymentChart(datetimeRange: $datetimeRange) {
      labels
      charts
    }
  }
`);

export const usePaymentChartQuery = (
  options: QueryHookOptions<PaymentChartQuery, PaymentChartQueryVariables>,
) => useQuery(paymentChartQuery, options);

export const usePaymentChartLazyQuery = (
  options: LazyQueryHookOptions<PaymentChartQuery, PaymentChartQueryVariables>,
) => useLazyQuery(paymentChartQuery, options);
