import { LazyQueryHookOptions, QueryHookOptions, useLazyQuery, useQuery } from '@apollo/client';
import { gql } from '../../gql';
import { CompanySavingsQuery, CompanySavingsQueryVariables } from '../../gql/graphql';

export const companySavingsQuery = gql(/* GraphQL */ `
  query CompanySavingsQuery($datetimeRange: DatetimeRangeInput!) {
    companySavings(datetimeRange: $datetimeRange)
  }
`);

export const useCompanySavingsQuery = (
  options: QueryHookOptions<CompanySavingsQuery, CompanySavingsQueryVariables>,
) => useQuery(companySavingsQuery, options);

export const useCompanySavingsLazyQuery = (
  options: LazyQueryHookOptions<CompanySavingsQuery, CompanySavingsQueryVariables>,
) => useLazyQuery(companySavingsQuery, options);
