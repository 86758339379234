import { CSSProperties, RefObject, useLayoutEffect, useState } from 'react';
import useWindowSize from './useWindowSize';

export default function useToTogglePositioning(
  toggleRef: RefObject<HTMLDivElement>,
  elementToBePositionedRef: RefObject<HTMLElement>,
) {
  const [positioning, setPositioning] = useState<CSSProperties>();
  const windowSize = useWindowSize();
  useLayoutEffect(() => {
    if (toggleRef.current && elementToBePositionedRef.current) {
      const { current: toggle } = toggleRef;
      const { current: elementToBePositioned } = elementToBePositionedRef;
      const {
        y: toggleY,
        height: toggleHeight,
        right: toggleRight,
      } = toggle.getBoundingClientRect();
      const { height: elementHeight, width: elementWidth } =
        elementToBePositioned.getBoundingClientRect();
      const [, windowHeight] = windowSize;
      const isCloseToBottomOfScreen = toggleY + elementHeight > windowHeight;
      const top = isCloseToBottomOfScreen
        ? Math.floor(window.scrollY + toggleY - elementHeight)
        : Math.floor(window.scrollY + toggleY + toggleHeight);
      setPositioning({
        top,
        left: Math.floor(toggleRight - elementWidth),
      });
    }
  }, [toggleRef, windowSize, elementToBePositionedRef]);

  return positioning;
}
