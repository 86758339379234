import { LazyQueryHookOptions, useLazyQuery, useQuery } from '@apollo/client';
import { gql } from '../../gql';
import { UserQuery, UserQueryVariables } from '../../gql/graphql';

export const userQuery = gql(/* GraphQL */ `
  query UserQuery {
    user {
      ...CurrentUserFragment
    }
  }
`);

export const useUserQuery = () => useQuery(userQuery);
export const useUserLazyQuery = (options?: LazyQueryHookOptions<UserQuery, UserQueryVariables>) =>
  useLazyQuery(userQuery, options);
