import { QueryHookOptions, useQuery } from '@apollo/client';
import { gql } from '../../gql';
import { ShipmentPresetsQuery, ShipmentPresetsQueryVariables } from '../../gql/graphql';

export const shipmentPresetsQuery = gql(/* GraphQL */ `
  query ShipmentPresetsQuery {
    carriers {
      id
      packageTypes {
        id
        packageTypeKey
        title
        heightRequired
        weightRequired
        dimensionsRequired
      }
    }
    company {
      id
      settings {
        defaultShipmentPresetId
      }
      shipmentPresets {
        ...ShipmentPresetFragment
      }
    }
  }
`);

export const useShipmentPresetsQuery = (
  options?: QueryHookOptions<ShipmentPresetsQuery, ShipmentPresetsQueryVariables>,
) => useQuery(shipmentPresetsQuery, options);
