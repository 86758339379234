import { StoreObject, useMutation } from '@apollo/client';
import { gql } from '../../gql';

export const deleteWarehouseMutation = gql(/* GraphQL */ `
  mutation DeleteWarehouseMutation($warehouseId: ID!) {
    deleteWarehouse(id: $warehouseId) {
      id
    }
  }
`);

export const useDeleteWarehouseMutation = () =>
  useMutation(deleteWarehouseMutation, {
    // Remove entry from list on delete
    update: (cache, { data }) => {
      if (!data) {
        return;
      }

      cache.updateQuery(
        {
          query: gql(/* GraphQL */ `
            query UpdateWarehousesAfterDeleteQuery {
              company {
                id
                warehouses {
                  ...WarehouseFragment
                }
              }
            }
          `),
        },
        (cacheResult) => {
          if (!cacheResult) {
            return undefined;
          }

          const existingEntries = cacheResult.company.warehouses;
          const entryIndex = existingEntries.findIndex(
            (entry) => entry.id === data.deleteWarehouse.id,
          );

          if (entryIndex >= 0) {
            return {
              company: {
                ...cacheResult.company,
                warehouses: [
                  ...existingEntries.slice(0, entryIndex),
                  ...existingEntries.slice(entryIndex + 1),
                ],
              },
            };
          }

          return undefined;
        },
      );
      cache.evict({ id: cache.identify(data.deleteWarehouse as unknown as StoreObject) });
      cache.gc();
    },
  });
