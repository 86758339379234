import styled from '@emotion/styled';
import { PropsWithChildren, useEffect } from 'react';
import { RoutesProps, useLocation } from 'react-router-dom';
import { ageFlashMessages } from '../apollo/cache/flashMessages';

const Styled = {
  Wrapper: styled.div`
    min-height: 100%;
    // height: 100vh;
  `,
};

export default function BridgeApp(props: PropsWithChildren<RoutesProps>) {
  const location = useLocation();

  // Remove all flash messages on location changes
  useEffect(() => {
    ageFlashMessages();
  }, [location]);

  return <Styled.Wrapper {...props} />;
}
