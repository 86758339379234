import { forwardRef, Ref, useEffect, useLayoutEffect, useRef, useState } from 'react';
import Modal, { ModalRef } from '../components/modals/Modal';
import useIsMobile from '../hooks/useIsMobile';
import useWindowSize from '../hooks/useWindowSize';

export type BridgeModalProps = {
  element: HTMLElement;
  width?: number;
  fullHeight?: boolean;
  dark?: boolean;
  onOpen: () => void;
  onClose: () => void;
};

function BridgeModalComponent(
  { element, width, fullHeight, dark, onOpen, onClose }: BridgeModalProps,
  ref: Ref<ModalRef>,
) {
  const contentRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const [openAnimationDone, setOpenAnimationDone] = useState(false);
  const originalParent = useRef<HTMLElement | null>(null);

  useLayoutEffect(() => {
    originalParent.current = element.parentElement;
    setOpen(true);

    return () => {
      originalParent.current = null;
      setOpen(false);
    };
  }, [element]);

  useEffect(() => {
    if (openAnimationDone) {
      contentRef.current?.appendChild(element);

      return () => {
        originalParent.current?.appendChild(element);
      };
    }

    return () => {};
  }, [openAnimationDone, element]);

  const [, windowHeight] = useWindowSize();
  const { isMobile } = useIsMobile();
  const modalMargin = isMobile ? 24 : 48;
  const verticalModalMargin = 2 * modalMargin;
  const modalHeaderHeight = 48;
  const bottomInset = isMobile ? 80 : 0;
  const adminBarHeight = isMobile ? 0 : document.getElementById('admin-bar')?.offsetHeight ?? 0;
  const height = isMobile ? windowHeight : windowHeight - adminBarHeight - verticalModalMargin;

  return (
    <Modal
      ref={ref}
      width={width}
      height={fullHeight && windowHeight >= 975 ? height : undefined}
      theme={dark ? 'dark' : 'light'}
      open={open}
      onOpen={() => {
        setOpenAnimationDone(true);
        onOpen();
      }}
      onClose={() => {
        setOpen(false);
        setOpenAnimationDone(false);
      }}
      onAfterClose={onClose}
    >
      <div
        ref={contentRef}
        style={{
          height: fullHeight ? height - modalHeaderHeight - bottomInset : undefined,
          marginLeft: fullHeight ? -modalMargin : undefined,
          marginRight: fullHeight ? -modalMargin : undefined,
          marginBottom: fullHeight ? -modalMargin : undefined,
        }}
      />
    </Modal>
  );
}

const BridgeModal = forwardRef(BridgeModalComponent);
export default BridgeModal;
