import { getIn, useFormikContext } from 'formik';
import { useEffect } from 'react';
import PackageSubform, {
  type PackageSubformValues,
  type PackageType,
} from '../../../components/subforms/PackageSubform';
import { NamespacedSubform } from '../../../components/subforms/types';
import CustomsFormSubform, {
  INITIAL_CUSTOMS_FORM_SUBFORM_VALUES,
  type CustomsFormSubformValues,
} from '../CustomsFormSubform';
import ExtraServicesSubform, { type ExtraServicesSubformValues } from '../ExtraServicesSubform';
import HazardousMaterialsSubform, {
  type HazardousMaterialsSubformValues,
} from '../HazardousMaterialsSubform';

export type PackageDetailsSubformProps<NS extends string> = NamespacedSubform<NS> & {
  packageTypes: readonly PackageType[];
  is2x7LabelSize?: boolean;
  isCustomsFormRequired?: boolean;
};

export type PackageDetailsSubformValues = {
  package: PackageSubformValues;
  extraServices: ExtraServicesSubformValues;
  hazardousMaterials: HazardousMaterialsSubformValues;
  customsForm: CustomsFormSubformValues;
};

type ValidNamespaces<NS extends string> = `${NS}.${keyof PackageDetailsSubformValues}`;
type FormValues = Record<string, unknown> & { [namespace: string]: PackageDetailsSubformValues };

export default function PackageDetailsSubform<NS extends string>({
  namespace,
  packageTypes,
  is2x7LabelSize,
  isCustomsFormRequired,
}: PackageDetailsSubformProps<NS>) {
  const { values, setFieldValue } = useFormikContext<FormValues>();
  const {
    package: { weightPounds, weightOunces },
    customsForm: { customsFormEnabled, customsItems, customsContentType },
  } = getIn(values, namespace) as PackageDetailsSubformValues;

  // Autofill the customs form with weight values from the package section
  useEffect(() => {
    // if the checkbox is checked once and the package has not weight, it gets autofilled with 0
    if (
      customsFormEnabled &&
      (customsItems[0].weightOunces === '' || customsItems[0].weightOunces === 0) &&
      (customsItems[0].weightPounds === '' || customsItems[0].weightPounds === 0)
    ) {
      setFieldValue(`${namespace}.customsForm.customsItems.0.weightPounds`, weightPounds);
      setFieldValue(`${namespace}.customsForm.customsItems.0.weightOunces`, weightOunces);
    }
    if (customsFormEnabled && customsContentType === '') {
      setFieldValue(
        `${namespace}.customsForm.customsContentType`,
        INITIAL_CUSTOMS_FORM_SUBFORM_VALUES.customsContentType,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customsFormEnabled]);

  return (
    <>
      <PackageSubform<ValidNamespaces<NS>>
        namespace={`${namespace}.package`}
        packageTypes={packageTypes}
      />
      <ExtraServicesSubform<ValidNamespaces<NS>>
        namespace={`${namespace}.extraServices`}
        is2x7LabelSize={is2x7LabelSize}
      />
      <HazardousMaterialsSubform<ValidNamespaces<NS>>
        namespace={`${namespace}.hazardousMaterials`}
      />
      <CustomsFormSubform<ValidNamespaces<NS>>
        namespace={`${namespace}.customsForm`}
        required={isCustomsFormRequired}
        is2x7LabelSize={is2x7LabelSize}
      />
    </>
  );
}
