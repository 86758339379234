import styled from '@emotion/styled';
import { MEDIA_QUERY } from '../../../styles/breakpoints';
import { SPACING } from '../../../styles/spacing';
import Checkbox from '../../form/Checkbox';
import FormControl from '../../form/FormControl';
import TextField from '../../form/TextField';
import { Col, Row } from '../../layout/Grid';
import { NamespacedSubform } from '../types';

export type TitleAndDefaultFlagValues = {
  title: string;
  asDefault: boolean;
};

export type TitleAndDefaultFlagSubformProps<NS extends string> = {
  titleLabel: string;
  loading?: boolean;
} & NamespacedSubform<NS>;

const Styled = {
  Wrapper: styled.div`
    padding-top: ${SPACING.lg};

    @media (max-width: ${MEDIA_QUERY.xsMax}) {
      padding-top: ${SPACING.xl};
      margin-bottom: -${SPACING.xl};
    }
  `,
};

export default function TitleAndDefaultFlagSubform<NS extends string>({
  titleLabel,
  loading = false,
  namespace,
}: TitleAndDefaultFlagSubformProps<NS>) {
  return (
    <Row spaceBelow>
      <Col sm={8} md={9} lg={10}>
        <FormControl
          name={`${namespace}.title`}
          as={TextField}
          loading={loading}
          label={titleLabel}
        />
      </Col>
      <Col sm={4} md={3} lg={2}>
        <Styled.Wrapper>
          <FormControl
            name={`${namespace}.asDefault`}
            as={Checkbox}
            loading={loading}
            type="checkbox"
            label="Set as Default"
          />
        </Styled.Wrapper>
      </Col>
    </Row>
  );
}
