import { useState } from 'react';
import { InMemoryStorageKey, InMemoryStorageValue, getItem, setItem } from '../services/storage';

export default function usePersistedState<K extends InMemoryStorageKey>(
  storageKey: K,
  initialState?: InMemoryStorageValue[K],
) {
  const [state, setState] = useState<InMemoryStorageValue[K] | null>(
    () => initialState ?? getItem(storageKey),
  );

  return [
    state,
    (newValue: InMemoryStorageValue[K]) => {
      setState(newValue);
      setItem(storageKey, newValue);
    },
  ] as const;
}
