import { downloadUrl } from '@ps/printLabels';
import Button from '../../components/form/Button';
import ProgressButton from '../../components/form/ProgressButton';

type ScanFormButtonProps = {
  canCreateScanForm: boolean;
  scanFormUrl: string | null;
  loading: boolean;
  onCreateScanForm: () => void;
};

function ScanFormButton({
  canCreateScanForm,
  scanFormUrl,
  loading,
  onCreateScanForm,
}: ScanFormButtonProps) {
  if (canCreateScanForm && !scanFormUrl) {
    return (
      <ProgressButton
        variant="info"
        fullWidth
        progress={loading}
        data-testid="create-scan-form-button"
        onClick={onCreateScanForm}
      >
        Create SCAN Form
      </ProgressButton>
    );
  }

  if (!canCreateScanForm && scanFormUrl) {
    return (
      <Button
        variant="info"
        fullWidth
        data-testid="download-scan-form-button"
        onClick={(event) => {
          event.stopPropagation();
          downloadUrl(scanFormUrl);
        }}
      >
        Download SCAN Form
      </Button>
    );
  }

  return null;
}

export default ScanFormButton;
