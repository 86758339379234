import { gql } from '../../gql';

const singleShipmentFormQuery = gql(/* GraphQL */ `
  query SingleShipmentFormQuery {
    addressAutocompleteSettings {
      addressProvider
      googleApiKey
    }
    company {
      id
      activeCarriers
      settings {
        shipmentLabelSize
        defaultWarehouseId
        defaultShipmentPresetId
      }
      warehouses {
        ...WarehouseFragment
      }
      shipmentPresets {
        ...ShipmentPresetFragment
      }
    }
    shipmentBoundaries {
      maxWeight
      maxCombinedLength
      maxLengthPlusGirth
      minLongSide
      maxLongSide
      minMiddleSide
      maxMiddleSide
      minShortSide
      maxShortSide
    }
    carriers {
      id
      carrierKey
      packageTypes {
        id
        packageTypeKey
        title
        description
        heightRequired
        weightRequired
        dimensionsRequired
      }
    }
    countries {
      id
      countryCode
      name
    }
    insuranceUnsupportedCountries
  }
`);

export default singleShipmentFormQuery;
