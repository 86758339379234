import { useCurrentUser } from '@ps/authentication';

export default function useFeatures(): Record<string, boolean> {
  const [currentUser] = useCurrentUser();
  const features = currentUser?.company.features || [];

  // Transform into object for easier access
  const featureMap = Object.fromEntries(features.map((feature) => [feature.key, feature.value]));

  return featureMap;
}
