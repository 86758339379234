import * as yup from 'yup';
import { NEW_WAREHOUSE_VALUE } from '../../../constants';
import { type ShipFromAddressSubformValues } from '../ShipFromAddressSubform';
import { INITIAL_WAREHOUSE_SUBFORM_VALUES, warehouseValidationSchema } from '../WarehouseSubform';

export const initialShipFromAddressSubformValues = (
  defaultWarehouseId: string,
): ShipFromAddressSubformValues => ({
  warehouseId: defaultWarehouseId,
  warehouse: { ...INITIAL_WAREHOUSE_SUBFORM_VALUES },
});

export const shipFromAddressValidationSchema = () =>
  yup.object<ShipFromAddressSubformValues>({
    warehouseId: yup.string().required(),
    warehouse: warehouseValidationSchema()
      .defined()
      .when('warehouseId', (warehouseId: string, schema: yup.ObjectSchema) =>
        warehouseId === NEW_WAREHOUSE_VALUE ? schema.required() : yup.object(),
      ),
  });
