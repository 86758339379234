import styled from '@emotion/styled';
import { Column, Table, flexRender } from '@tanstack/react-table';
import { ReactNode, useCallback } from 'react';
import { SPACING } from '../../../styles/spacing';
import Tag, { TagBaseSelector } from '../../Tag';
import Button from '../../form/Button';

const Styled = {
  FilterBar: styled.div`
    margin-bottom: ${SPACING.lg};

    ${TagBaseSelector} {
      margin-right: ${SPACING.sm};
      margin-bottom: ${SPACING.sm};
    }

    ${Button} {
      vertical-align: middle;
    }
  `,
};

export type FilterBarProps<TData> = {
  table: Table<TData>;
  removeGlobalFilter: () => void;
};

export default function FilterBar<TData>({
  table,
  removeGlobalFilter = () => {},
}: FilterBarProps<TData>) {
  const { columnFilters, globalFilter } = table.getState();
  const columns = table.getAllLeafColumns();

  // Typescript does not understand filtering out undefined values unless you do it this way https://www.benmvp.com/blog/filtering-undefined-elements-from-array-typescript/
  const isColumnType = (c: Column<TData, unknown> | undefined): c is Column<TData, unknown> => !!c;

  const activeFilters = columnFilters
    .map((filter) => columns.find((column) => !!column && column.id === filter.id))
    .filter(isColumnType);

  const clearAllFilters = useCallback(
    () => activeFilters.forEach((filter) => filter.setFilterValue(undefined)),
    [activeFilters],
  );

  if (!globalFilter && !activeFilters.length) {
    return false;
  }

  return (
    <Styled.FilterBar>
      {globalFilter && ( // tag for the search term
        <Tag key={globalFilter} removable onRemoveClick={removeGlobalFilter}>
          <strong>Search Term:</strong> {globalFilter}
        </Tag>
      )}
      {activeFilters.map((filter) => (
        <Tag key={filter.id} removable onRemoveClick={() => filter.setFilterValue(undefined)}>
          <strong>{filter.columnDef.header as ReactNode}: </strong>
          {/* we pass the column as the filterTags props object */}
          {flexRender(filter.columnDef.meta?.filterTag, {
            filterValue: filter.getFilterValue(),
            settings: filter.columnDef.meta?.filterTagSettings,
            table,
            column: columns.find((column) => column.id === filter.id),
          })}
        </Tag>
      ))}

      {(activeFilters?.length || globalFilter) && (
        <Button
          size="small"
          variant="danger"
          onClick={() => {
            clearAllFilters();
            removeGlobalFilter();
          }}
        >
          Clear All Filters
        </Button>
      )}
    </Styled.FilterBar>
  );
}
