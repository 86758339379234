import { HeaderContext } from '@tanstack/react-table';
import { useState } from 'react';
import Button from '../../form/Button';
import TextField from '../../form/TextField';
import ButtonBar from '../controls/ButtonBar';

export default function TextFilterInterface<TData, TValue>(
  headerContext: HeaderContext<TData, TValue>,
) {
  const { column, table } = headerContext;
  const loading = table.options.meta?.loading;
  const filterValue = column.getFilterValue(); // filter value as defined by table object
  const [value, setValue] = useState(filterValue); // internal value
  const submit = () => column.setFilterValue(value); // synchronise value on submit
  const clear = () => column.setFilterValue(undefined); // synchronise value on clear

  return (
    <>
      <TextField
        value={(value as string) ?? ''}
        onChange={(event) => {
          setValue(event.currentTarget.value);
        }}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            submit();
          }
        }}
        disabled={loading}
        autoFocus
        small
      />
      <ButtonBar>
        <Button variant="primary" size="small" onClick={submit}>
          Filter
        </Button>
        <Button size="small" onClick={clear}>
          Clear
        </Button>
      </ButtonBar>
    </>
  );
}
