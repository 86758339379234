import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { BORDER_WIDTH } from '../../../styles/borders';
import { COLOR, GREYSCALE } from '../../../styles/colors';
import { FakeLink } from '../../Link';
import ContentLoading from '../../loading/ContentLoading';

type DataPlaceholdersProps = {
  loading: boolean;
  error?: Error;
  showDataOnFirstLoad: boolean;
  anyFiltersActive: boolean;
  clearAllFilters: () => void;
};

const messageStyle = css`
  position: absolute;
  width: 100%;
  display: block;
  text-align: center;
`;

const Styled = {
  PlaceholderRow: styled.tr`
    height: 150px;
  `,
  TableCell: styled.td`
    padding: 0.4em 0.6em;
    line-height: 1.6em;
    vertical-align: middle;
    text-overflow: ellipsis;
    overflow: hidden;
    border-width: ${BORDER_WIDTH.none} ${BORDER_WIDTH.none} ${BORDER_WIDTH.xs} ${BORDER_WIDTH.xs};
    border-style: solid;
    border-color: ${GREYSCALE.grey20};

    &:first-of-type {
      border-left-width: ${BORDER_WIDTH.none};
    }

    &:last-of-type {
      border-right-width: ${BORDER_WIDTH.none};
    }
  `,
  ErrorMessage: styled.div`
    ${messageStyle}
    color: ${COLOR.red};
    top: 50%;
  `,
  TableFeedback: styled.div`
    ${messageStyle}
    top: 50%;
  `,
  LoadingSpinner: styled.div`
    ${messageStyle}
  `,
};

function DataPlaceholders({
  loading,
  error,
  showDataOnFirstLoad,
  anyFiltersActive,
  clearAllFilters,
}: DataPlaceholdersProps) {
  if (loading) {
    return (
      <Styled.PlaceholderRow>
        <Styled.TableCell>
          <Styled.LoadingSpinner>
            <ContentLoading />
          </Styled.LoadingSpinner>
        </Styled.TableCell>
      </Styled.PlaceholderRow>
    );
  }
  if (error) {
    return (
      <Styled.PlaceholderRow>
        <Styled.TableCell>
          <Styled.ErrorMessage>
            {error instanceof Error ? error.message : String(error)}
          </Styled.ErrorMessage>
        </Styled.TableCell>
      </Styled.PlaceholderRow>
    );
  }
  if (!showDataOnFirstLoad && !anyFiltersActive) {
    return (
      <Styled.PlaceholderRow>
        <Styled.TableCell>
          <Styled.TableFeedback>
            This grid is configured to not load data without filters
            <br />
            Please add a filter or enter a search term
          </Styled.TableFeedback>
        </Styled.TableCell>
      </Styled.PlaceholderRow>
    );
  }
  if (anyFiltersActive) {
    return (
      <Styled.PlaceholderRow>
        <Styled.TableCell>
          <Styled.TableFeedback>
            There&apos;s no results with these filters applied!
            <br />
            Modify the filters or{' '}
            <FakeLink role="button" onClick={clearAllFilters}>
              reset grid
            </FakeLink>
          </Styled.TableFeedback>
        </Styled.TableCell>
      </Styled.PlaceholderRow>
    );
  }
  if (!anyFiltersActive) {
    return (
      <Styled.PlaceholderRow>
        <Styled.TableCell>
          <Styled.TableFeedback>No Results</Styled.TableFeedback>
        </Styled.TableCell>
      </Styled.PlaceholderRow>
    );
  }
  return null;
}

export default DataPlaceholders;
