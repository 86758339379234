import add from 'date-fns/add';
import * as yup from 'yup';
import { DATE_FORMAT } from '../../constants';
import { DateInUserTimezoneType } from '../../hooks/useDateInUserTimezone';
import delayStringToDuration from '../../utils/delayStringToDuration';
import { NotifyRecipientsSettingRowValues } from './NotifyRecipientsSettingRow';

export const notifyRecipientsSettingRowValidationSchema = () => {
  return yup.object<NotifyRecipientsSettingRowValues>({
    enabled: yup.boolean().required(),
    emailTemplate: yup.string(),
    defaultTrackingEmailsDelay: yup.string().when('enabled', {
      is: true,
      then: yup.string().required(),
    }),
  });
};

type MailTemplates = ReadonlyArray<{
  readonly __typename: 'MailTemplate';
  readonly id: string;
  readonly subject: string;
  readonly name: string;
  readonly senderEmail: string;
  readonly senderName: string;
  readonly asDefault: boolean;
}>;

export const getDefaultEmailTemplate = (
  defaultEmailTemplateId: string,
  mailTemplates: MailTemplates,
): string => {
  return (
    mailTemplates.find(({ id }) => id === defaultEmailTemplateId)?.id || mailTemplates[0]?.id || ''
  );
};

export const getNotifyRecipientsDate = (
  { enabled, defaultTrackingEmailsDelay }: NotifyRecipientsSettingRowValues,
  formatDate: DateInUserTimezoneType['formatDate'],
  createDate: DateInUserTimezoneType['createDate'],
) => {
  if (!enabled || !defaultTrackingEmailsDelay) {
    return null;
  }

  const delayDuration = delayStringToDuration(defaultTrackingEmailsDelay);

  const now = createDate('now');

  const { days = 0, hours = 0 } = delayDuration;
  if (days >= 1 || hours >= 24) {
    const notifyDate = add(now, { days, hours });
    const date = formatDate('UTC', notifyDate, DATE_FORMAT.date);
    return `${date}, 9:00 AM`;
  }

  const notifyDate = add(now, delayDuration);
  return formatDate('UTC', notifyDate, DATE_FORMAT.dateTime12);
};
