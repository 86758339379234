import { QueryHookOptions, useLazyQuery, useQuery } from '@apollo/client';
import { gql } from '../../gql';
import { AverageCostChartQuery, AverageCostChartQueryVariables } from '../../gql/graphql';

export const averageCostChartQuery = gql(/* GraphQL */ `
  query AverageCostChartQuery($datetimeRange: DatetimeRangeInput!) {
    averageCostChart(datetimeRange: $datetimeRange) {
      charts
      labels
    }
  }
`);

export const useAverageCostChartQuery = (
  options: QueryHookOptions<AverageCostChartQuery, AverageCostChartQueryVariables>,
) => useQuery(averageCostChartQuery, options);
export const useAverageCostChartLazyQuery = (
  options: QueryHookOptions<AverageCostChartQuery, AverageCostChartQueryVariables>,
) => useLazyQuery(averageCostChartQuery, options);
