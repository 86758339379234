/* eslint-disable no-template-curly-in-string */
import * as yup from 'yup';
import pluralize from '../../utils/pluralize';

const initValidation = () =>
  yup.setLocale({
    mixed: {
      default: 'This field is invalid',
      required: 'This field is required',
      notType: ({ type, originalValue }) => {
        // currently, only the number case is relevent for us.
        if (type === 'number') {
          const invalidString = originalValue.replace(/[0-9]/g, '');
          const invalidCharacters = Array.from(new Set(invalidString)).join(', ');
          return invalidCharacters.length > 1
            ? `Values must be numeric. "${invalidCharacters}" are not allowed`
            : `Values must be numeric. ${invalidCharacters} is not allowed`;
        }
        return `Entered values are not allowed`;
      },
    },
    string: {
      length: ({ length }) =>
        pluralize('Value must be exactly %d [character|characters] long', length),
      min: ({ min }) => pluralize('Value must be at least %d [character|characters] long', min),
      max: ({ max }) => pluralize('Value must be at most %d [character|characters] long', max),
      matches: 'Value must match the following: "${regex}"',
      email: 'Enter a valid email address',
      url: 'Value must be a valid URL',
      uuid: 'Value must be a valid UUID',
      trim: 'Value must not start or end with whitespace',
      lowercase: 'Value must be all lowercase',
      uppercase: 'Value must be all uppercase',
    },
    number: {
      min: 'Value must be greater than or equal to ${min}',
      max: 'Value must be less than or equal to ${max}',
      lessThan: 'Value must be less than ${less}',
      moreThan: 'Value must be greater than ${more}',
      notEqual: 'Value must not be equal to ${notEqual}',
      positive: 'Value must be a positive number',
      negative: 'Value must be a negative number',
      integer: 'Value must be an integer',
    },
    date: {
      min: 'Date must be later than ${min}',
      max: 'Date must be at earlier than ${max}',
    },
    boolean: {},
    object: {
      noUnknown: '${path} field has unspecified keys: ${unknown}',
    },
    array: {
      min: ({ min }) => pluralize('Field must have at least %d [item|items]', min),
      max: ({ max }) =>
        pluralize('Field field must have less than or equal to %d [item|items]', max),
    },
  });

export default initValidation;
