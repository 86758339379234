import getYear from 'date-fns/getYear';
import newDate from './utils/newDate';

export const SIDEBAR_COLLAPSIBLE_DURATION = '150ms';
export const SIDEBAR_COLLAPSIBLE_TIMING_FUNCTION = 'ease-out';
export const SIDEBAR_WIDTH = { expanded: '215px', collapsed: '95px' };
export const SIDEBAR_MENU_ITEM_HEIGHT = '75px';
export const SIDEBAR_MENU_ITEM_ICON_SIZE = { width: '70px', height: '66px' };
export const ALL_LOGO_ANIMATIONS = [
  'Smile',
  'SmileBeard',
  'SmileHat',
  'PartyCelebration',
  'Bubble',
  'Twinkle',
  'Cry',
  'LaughTears',
  'Party',
  'Satisfied',
  'July4th',
  'Starfish',
  'HatFlip',
  'Hearts',
  'Ghost',
  'Dracula',
  'JackOLantern',
  'Present',
] as const;

export const HORIZONTAL_SCROLLBAR_HEIGHT = '14px';

export const REPORT_TILE_SIZE = {
  Screen: '190px',
  Print: '140px',
};

export const TALK_LIKE_A_PIRATE_DAY_DATE = '2022-09-19 13:37:01:000Z';
export const COPYRIGHT_LINE = `© 2014-${getYear(newDate('now'))} - All Rights Reserved`;
export const EXTERNAL_SUPPORT_URL = 'https://support.pirateship.com/';
export const GRID_PAGE_SIZE_OPTIONS: ReadonlyArray<number> = [10, 20, 50, 100, 200, 500, 1000];
export const MINIMUM_CC_PROVIDER_AMOUNT = 1;
export const COMPATIBILITY_REDIRECTS: ReadonlyArray<{ path: string; to: string }> = [
  { path: '/login', to: '/' },
  { path: '/reports/credit', to: '/reports/addcredit' },
  { path: '/payment/refund', to: '/reports/refundcredit' },
  { path: '/scanform', to: '/scan-forms' },
];

export const US_COUNTRY_CODE = 'US';
export const DEFAULT_COUNTRY_CODE = US_COUNTRY_CODE;

export const STORAGE_KEYS = {
  loggedInStorageKey: 'psLoggedIn',
  clientTrackedStorageKey: 'psClientTracked',
  sidebarCollapsedStorageKey: 'psSidebarCollapsed',
  showShipmentExtraServiceStorageKey: 'psShowShipmentExtraServices',
  showRubberStampContainerStorageKey: 'psShowRubberStampContainer',
  showShipmentInfoStorageKey: 'psShowShipmentInfo',
  gridFilterStateStorageKey: 'psGridFilterState',
  adminBarVisibleStorageKey: 'psAdminBarVisible',
  reportOverviewDatetimeRangeFilterStorageKey: 'psReportOverviewDatetimeRangeFilter',
  showPaymentSettingsStorageKey: 'psShowPaymentSettings',
  packageLocationStorageKey: 'psPackageLocation',
  specialInstructionsStorageKey: 'psspecialInstructions',
  preselectedSummaryIdsStorageKey: 'preselectedSummaryIds',
  singleShipmentFormValuesStorageKey: 'singleShipmentFormValues',
} as const;

export type DataGridFilterStorageKeyTemplate = `psDataGridFilters_${string}`;
export type CollapsibleCollapsedStorageKeyTemplate = `collapsibleCollapsed_${string}`;

// a generic type to constrain string union types to a subset of said union
export type Subset<T, U extends T> = U;

// reference: https://date-fns.org/v2.28.0/docs/format
// these are common date formats we use in the app, used in combination with the date-fns format function
export const DATE_FORMAT = {
  date: 'yyyy-MM-dd', // we refer to date as year, month, day. 2022-01-30
  dateTime24: 'yyyy-MM-dd HH:mm', // year, month, day, 24 hour hour, minutes, e.g. 2022-01-30 00:30
  dateTime12: 'yyyy-MM-dd hh:mm a', // year, month, day, 12 hour hour, minutes, AM/PM, e.g. 2022-01-30 12:30 PM
  time24: 'HH:mm', // 24 hour time, e.g. 22:30
  time12NoLeadingZero: 'h:mm a', // 12 hour time with no leading 0 and AM/PM, e.g. 4:30 AM
  usDate: 'MM/dd/yyyy', // month/day/year, e.g. 12/30/2022
  usDateNoLeadingZero: 'M/d/yyyy', // month/day/year with no leading 0, e.g. 5/8/2022
  usDateNoLeadingZeroShortYear: 'M/d/yy', // month/day/year with no leading 0, e.g. 5/8/22
  usVerboseDate: `MMMM dd, yyyy`, // month as unabbreviated word, day, four digit year, e.g. January 06, 2022
  usSemiVerboseDate: 'MMM dd, yyyy', // month as three-letter abbreviated word, then the rest as above: e.g Jan 02, 2022
  dayOfWeek: 'cccc', // unabbreviated day of week, e.g Monday, Tuesday
  timezone: 'zzz',
  dateWithWeekday: 'cccc, MM/dd/yyyy',
} as const;

// enumeration of weekdays as the Date API sees them, i.e. 0 - 6 = Sun - Sat
export enum Weekday {
  Sunday,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
}

// order of steps we go through before buying a batch / purchasing a pickup
export enum SubmitStep {
  Start,
  PaymentGood,
  UspsGood,
  UpsGood,
}

// countries for which adding a postcode is possible but does not lead to an error if omitted
export const POST_CODE_OPTIONAL_COUNTRIES = ['CA', 'MX'] as const;

export const US_ASSOCIATED_STATES = ['AS', 'GU', 'MP', 'MH', 'FM', 'PW'] as const; // states associated with the US
export const US_MILITARY_REGIONS = ['AA', 'AE', 'AP'] as const; // military regions of the US
export const US_MILITARY_CITIES = ['APO', 'FPO', 'DPO'] as const; // military cities of the US
export const US_DOMESTIC_COUNTRIES = ['US', 'PR', 'VI'] as const; // countries that require no customs forms when shipping from the US
export const HS_CODE_COUNTRY_CODES = [
  'BE', // BELGIUM
  'BG', // BULGARIA
  'CZ', // CZECHIA
  'DK', // DENMARK
  'DE', // GERMANY
  'EE', // ESTONIA
  'IE', // IRELAND
  'GR', // GREECE
  'ES', // SPAIN
  'FR', // FRANCE
  'HR', // CROATIA
  'IT', // ITALY
  'CY', // CYPRUS
  'LV', // LATVIA
  'LT', // LITHUANIA
  'LU', // LUXEMBOURG
  'HU', // HUNGARY
  'MT', // MALTA
  'NL', // NETHERLANDS
  'AT', // AUSTRIA
  'PL', // POLAND
  'PT', // PORTUGAL
  'RO', // ROMANIA
  'SI', // SLOVENIA
  'SK', // SLOVAKIA
  'FI', // FINLAND
  'SE', // SWEDEN
  'GP', // GUADELOUPE
  'GF', // FRENCH_GUIANA
  'MQ', // MARTINIQUE
  'RE', // REUNION
  'YT', // MAYOTTE
  'MF', // SAINT_MARTIN
  'AX', // ALAND_ISLANDS
  'MC', // MONACO
  'SM', // SAN_MARINO
  'NO', // NORWAY // No EU country, but requires HS Code (PP-10515)
  'CH', // SWITZERLAND // No EU country, but requires HS Code (PP-10515)
] as const;

export enum UiEvents {
  BatchPurchased = 'batch_purchased',
  APirateIsSad = 'a_pirate_is_sad',
}

// the following is a list of "challenges" the user can complete to start their pirate ship experience
export enum Challenge {
  AddPaymentMethod = 'CHALLENGE_ADD_PAYMENT_METHOD',
  ConnectStore = 'CHALLENGE_CONNECT_STORE',
  EditTracking = 'CHALLENGE_EDIT_TRACKING',
  PlaySounds = 'CHALLENGE_PLAY_SOUNDS',
  ShipPackage = 'CHALLENGE_SHIP_PACKAGE',
  VisitGeneralSettingsPage = 'CHALLENGE_VISIT_GENERAL_SETTINGS_PAGE',
  VisitHelpGeneral = 'CHALLENGE_VISIT_HELP_GENERAL',
  VisitHelpPrint = 'CHALLENGE_VISIT_HELP_PRINT',
  VisitRatesPage = 'CHALLENGE_VISIT_RATES_PAGE',
  WatchVideoGeneral = 'CHALLENGE_WATCH_VIDEO_GENERAL',
  WatchVideoV2 = 'CHALLENGE_WATCH_VIDEO_V2',
}

// Show logo animation every few minutes (+- jitter). E.g. an interval of 5 min
// and a jitter of 1 min can result in pauses ranging from 4 to 6 minutes.
export const LOGO_ANIMATION_INTERVAL = 5 * 60 * 1000; // 5 minutes
export const LOGO_ANIMATION_INTERVAL_JITTER = 1 * 60 * 1000; // 1 minute
export const LOGO_ANIMATION_DO_NOT_REPEAT_LAST_X = 3; // keep track of 3 most recent animations to not play again
export const BATCH_PROCESS_POLL_INTERVAL = 200;

export type PaymentMethodModalVia = 'promo-link' | 'link' | 'submit'; // user path to the payment method modal on the buy page
export type PaymentMethodPromotion = 'plaidThreePercent' | 'none';

// used for the pickup pages
export const NEW_WAREHOUSE_VALUE = '0';
export const NEW_SHIPMENT_PRESET_VALUE = '0';

export enum CarrierInfoOption {
  UpsRateLimited = 'ups-rate-limited',
  NoUsps = 'no-usps',
  NoUps = 'no-ups',
}

export type LocalOrUTC = 'UTC' | 'local';

export const INTERCOM_ARTICLE_LINKS = {
  fileInsurance:
    'https://support.pirateship.com/en/articles/1068439-how-do-i-file-an-insurance-claim',
};

export type BatchAndShipmentIds = {
  batchId?: string;
  shipmentId?: string;
};

export const EMAIL_TEMPLATE_TITLE = 'Select email template';
export const EMAIL_DELAY_TITLE = 'Select Email Delay';

export const PASSWORD_MANAGER_SUPPRESS_ATTRIBUTES = {
  'data-1p-ignore': '', // disable 1Password autofill
  'data-form-type': 'other', // disable Dashlane autofill
  'data-bwignore': '', // disable Bitwarden autofill
  'data-lpignore': 'true', // disable LastPass autofill
};
