import { gql as gqlFuncAliasNotCodegenParsed } from '@apollo/client';
import {
  DataGridFilterInput,
  DataGridMetaData,
  DataGridSortingInput,
} from '../../../../gql/graphql';
import constructGridQueryBody from '../../utils/constructGridQueryBody';
import constructGridQueryDefinition from '../../utils/constructGridQueryDefinition';
import { GridQueryVariables } from './connectedGridRows';

export interface ConnectedGridExportQueryResult {
  readonly __typename: 'DataGridResult';
  readonly id: string;
  readonly exportUrl: {
    readonly exportId: number;
    readonly url: string;
    readonly downloadable: boolean;
    readonly filename: string;
  };
  readonly metaData: ReadonlyArray<DataGridMetaData>;
}

export interface ConnectedGridExportQuery {
  [key: string]: ConnectedGridExportQueryResult;
}

export interface ConnectedGridExportQueryVariables {
  readonly searchTerm?: string | null;
  readonly filters?: ReadonlyArray<DataGridFilterInput> | null;
  readonly sorting?: ReadonlyArray<DataGridSortingInput> | null;
  readonly columns?: ReadonlyArray<string> | null;
  readonly exportId?: number | null;
}

// the set of variables with type used in the generic connected data grid export query
const genericGridQueryDefinitionVariables = [
  { variableName: 'searchTerm', type: 'String' },
  { variableName: 'filters', type: '[DataGridFilterInput!]' },
  { variableName: 'sorting', type: '[DataGridSortingInput!]' },
  { variableName: 'columns', type: '[String!]' },
  { variableName: 'exportId', type: 'Int' },
];

const exportInnerQuery = `exportUrl(
  searchTerm: $searchTerm,
  filters: $filters,
  sorting: $sorting,
  columns: $columns,
  exportId: $exportId,
  ids: $ids
) {
  exportId
  url
  downloadable
  filename
}
metaData {
  key
  value
}
`;

export const makeConnectedGridExportQuery = (
  queryName: string,
  gridQueryVariables?: GridQueryVariables,
) => {
  const queryDefinition = constructGridQueryDefinition([
    ...(gridQueryVariables?.length ? gridQueryVariables : []),
    ...genericGridQueryDefinitionVariables,
  ]);
  return gqlFuncAliasNotCodegenParsed(`
    query ConnectedGridExportQuery${queryDefinition}
    {
      ${constructGridQueryBody(queryName, gridQueryVariables, exportInnerQuery)}
    }
  `);
};
