import { useMemo } from 'react';
import { DropdownSelectOption } from '../../components/form/DropdownSelect';
import { NEW_SHIPMENT_PRESET_VALUE } from '../../constants';
import formatPreset, { PackageType, ShipmentPreset } from '../../utils/formatPreset';

// not a typo, the object you give a function is also called options. blöd
type UseShipmentPresetOptionsOptions = {
  shipmentPresets?: ReadonlyArray<ShipmentPreset>;
  packageTypes?: ReadonlyArray<PackageType>;
};

// given package preset data, return an array of options for use in the dropdown.
export default function useShipmentPresetOptions({
  shipmentPresets,
  packageTypes,
}: UseShipmentPresetOptionsOptions): DropdownSelectOption<string>[] {
  const warehouseOptions = useMemo(() => {
    if (!shipmentPresets || !packageTypes) {
      return [];
    }

    const options = shipmentPresets.map(
      (shipmentPreset): DropdownSelectOption<string> => ({
        value: shipmentPreset.id,
        title: shipmentPreset.title,
        description: formatPreset(shipmentPreset, [...packageTypes]),
      }),
    );

    // the first option is to enter package details manually.
    options.unshift({
      value: NEW_SHIPMENT_PRESET_VALUE,
      title: "Don't use Saved Package",
      description: 'Enter package details manually',
    });

    return options;
  }, [shipmentPresets, packageTypes]);
  return warehouseOptions;
}
