import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import spinnerUrl from '../../assets/loading/spinner.svg';
import whiteSpinnerUrl from '../../assets/loading/spinnerWhite.svg';

const SpinnerAnimation = keyframes`
  0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
`;

const Styled = {
  Spinner: styled.div<BaseLoadingProps>`
    width: ${(props) => props.size}px;
    height: ${(props) => props.size}px;
    background: transparent no-repeat center center;
    background-image: ${({ color }) =>
      color === 'white' ? `url("${whiteSpinnerUrl}")` : `url("${spinnerUrl}")`};
    background-size: ${(props) => props.size}px ${(props) => props.size}px;
    transform: translateZ(0);
    animation: ${SpinnerAnimation} 1s steps(8, end) infinite;
  `,
};

export type BaseLoadingProps = {
  size?: number;
  color?: 'white' | 'black';
};

function BaseLoading({ size = 30, color = 'black' }: BaseLoadingProps) {
  // id="ps-loading-spinner" used in BuyPage/FinishPurchaseSubformCest
  return <Styled.Spinner size={size} id="ps-loading-spinner" color={color} />;
}

export default BaseLoading;
