import { ApolloError } from '@apollo/client';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useCurrentUser } from '@ps/authentication';
import { Form, Formik } from 'formik';
import { useEffect, useMemo, useRef, useState } from 'react';
import * as yup from 'yup';
import { setFlashMessage } from '../../apollo/cache/flashMessages';
import useIsMobile from '../../hooks/useIsMobile';
import useModal, { validModalTypes } from '../../hooks/useModal';
import { useAcceptTermsMutation } from '../../operations/mutations/acceptTerms';
import { COLOR } from '../../styles/colors';
import { SPACING } from '../../styles/spacing';
import { addRumCustomAction } from '../../utils/addRumCustomAttributes';
import Button from '../form/Button';
import Checkbox from '../form/Checkbox';
import FormControl from '../form/FormControl';
import ProgressButton from '../form/ProgressButton';
import DpaPage from '../pages/legal/DpaPage';
import PrivacyPage from '../pages/legal/PrivacyPage';
import TermsOfUsePage from '../pages/legal/TermsOfUsePage';
import Modal, { ModalRef } from './Modal';

type LegalTextsModalProps = {
  modalType: string;
  open: boolean;
  onClose?: () => void;
  onLocationChange?: (url: string) => void;
};

export type ScreenSizeProps = {
  mobile?: boolean;
  shortScreen?: boolean;
};

export default function LegalTextsModal({
  modalType,
  open,
  onClose,
  onLocationChange,
}: LegalTextsModalProps) {
  const modalRef = useRef<ModalRef>(null);
  const [currentUser] = useCurrentUser();
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [pageNode, setPageNode] = useState<HTMLDivElement | null>(null);
  const [error, setError] = useState<boolean>(false);
  const [acceptInProgress, setAcceptInProgress] = useState<boolean>(false);
  const [acceptTerms] = useAcceptTermsMutation();
  const [currentModalType, setCurrentModalType] = useState(modalType);
  const extractSubdir = (url: string) => url.substring(url.lastIndexOf('/') + 1);
  const setModal = useModal();
  const { isMobile, isLandscape, isShortScreen } = useIsMobile();
  const openEventCalled = useRef<boolean>(false);

  useEffect(() => setCurrentModalType(modalType), [modalType]);

  useEffect(() => {
    const linkClickHandler = (event: MouseEvent) => {
      const targetLink = (event.target as HTMLElement | null)?.closest('a') || null;
      if (targetLink && validModalTypes.includes(extractSubdir(targetLink.href))) {
        event.preventDefault();
        setCurrentModalType(extractSubdir(targetLink.href));

        // if link to other modal page is clicked on mobile, scroll to top!
        if (isMobile) {
          modalRef.current?.scrollToTop();
        }
        if (onLocationChange) {
          onLocationChange(extractSubdir(targetLink.href));
        }
      }
    };

    pageNode?.addEventListener('click', linkClickHandler);

    return () => {
      pageNode?.removeEventListener('click', linkClickHandler);
    };
  }, [pageNode, currentModalType, onLocationChange, isMobile]);

  const roles = currentUser?.roles;
  const userDidNotAcceptTerms = useMemo(
    () => roles && roles.includes('user_did_not_accept_terms'),
    [roles],
  );

  const closable = useMemo(() => !userDidNotAcceptTerms, [userDidNotAcceptTerms]);

  type AcceptTermsFormValues = {
    accepted: boolean;
  };

  const acceptedError = 'Please check the box to consent before accepting terms';

  const Styled = {
    ConsentWrapper: styled('div')<ScreenSizeProps>`
      margin-block: ${SPACING.xxl};
      ${({ mobile }) =>
        mobile
          ? css`
              background-color: white;
              position: fixed;
              bottom: 0;
              width: calc(100% - 2 * ${SPACING.xl});
              padding: ${SPACING.lg} 0;
              margin-block: 0;
            `
          : ''};
      ${({ shortScreen }) =>
        shortScreen
          ? css`
              margin-block: ${SPACING.lg} 0;
            `
          : ''};
    `,
    Error: styled('p')`
      position: absolute;
      margin: 0;
      font-size: smaller;
      color: ${COLOR.red};
    `,
    AcceptButtonWrapper: styled('div')<ScreenSizeProps>`
      display: flex;
      justify-content: center;
      margin-top: ${({ mobile, shortScreen }) =>
        mobile || shortScreen ? `${SPACING.md}` : `${SPACING.xxl}`};
    `,
    CloseButtonWrapper: styled('div')<{ mobile?: boolean }>`
      display: flex;
      justify-content: center;
      margin-top: ${({ mobile }) => (mobile ? `0` : `${SPACING.xxl}`)};
    `,

    CloseWrapper: styled('div')<{ mobile?: boolean }>`
      ${({ mobile }) =>
        mobile
          ? css`
              background-color: white;
              position: fixed;
              bottom: 0;
              width: calc(100% - 2 * ${SPACING.xl});
              padding: ${SPACING.lg} 0;
            `
          : ''};
    `,
  };

  const initialValues: AcceptTermsFormValues = {
    accepted: isChecked,
  };

  const validationSchema = () =>
    yup.object({
      accepted: yup.boolean().required(),
    });

  const handleCheckBox = (checkState: boolean) => {
    if (checkState === true) setIsChecked(false);
    if (checkState === false) {
      setIsChecked(true);
      setError(false);
    }
  };
  const handleAcceptTerms = async (values: AcceptTermsFormValues) => {
    if (values.accepted === false) {
      setError(true);
      setAcceptInProgress(false);
    } else {
      setAcceptInProgress(true);

      try {
        await acceptTerms({
          variables: {
            accepted: values.accepted,
          },
        });

        addRumCustomAction('tos_accepted', {
          event: 'tos_accepted',
          userid: currentUser?.id,
        });

        if (onClose) {
          setAcceptInProgress(false);
          onClose();
        }
        // Reset to initial url
        setCurrentModalType(modalType);
      } catch (err) {
        if (err instanceof ApolloError) {
          setAcceptInProgress(false);
          err.graphQLErrors.forEach((e) => {
            setFlashMessage(e.message, 'danger');
          });
        }
      } finally {
        setAcceptInProgress(false);
      }
    }
  };

  const renderModal = () => {
    switch (modalType) {
      case 'terms':
        return (
          <TermsOfUsePage clickHandler={setPageNode} requireAcceptance={userDidNotAcceptTerms} />
        );
      case 'privacy':
        return <PrivacyPage clickHandler={setPageNode} requireAcceptance={userDidNotAcceptTerms} />;
      case 'dpa':
        return <DpaPage clickHandler={setPageNode} requireAcceptance={userDidNotAcceptTerms} />;
      default:
        break;
    }

    return null;
  };

  const getAcceptanceButtonSize = () => {
    if ((isMobile && !isLandscape) || isShortScreen) {
      return 'large';
    }

    if (!isMobile) {
      return 'xLarge';
    }

    if (isMobile && isLandscape) {
      return 'medium';
    }

    return 'large';
  };

  useEffect(() => {
    if (userDidNotAcceptTerms && !open) {
      setModal('terms');
    }
  }, [userDidNotAcceptTerms, setModal, open]);

  // Modal.tsx is full of bugs. This time: onOpen is not called when the modal is default open.
  // that's why I created this hack
  // Nicolai will create a refactoring ticket
  useEffect(() => {
    if (modalType === 'terms' && !openEventCalled.current && userDidNotAcceptTerms) {
      openEventCalled.current = true;

      addRumCustomAction('tos_modal_open_not_accepted', {
        event: 'tos_modal_open_not_accepted',
        userid: currentUser?.id,
      });
    }
  }, [userDidNotAcceptTerms, setModal, open, modalType, currentUser?.id, openEventCalled]);

  return (
    <Modal
      ref={modalRef}
      width={800}
      open={open}
      onClose={() => {
        if (onClose) onClose();

        openEventCalled.current = false;

        // Reset to initial url
        setCurrentModalType(modalType);
      }}
      closable={closable}
      forceMobileScrollbars={isMobile}
      overScrollBehaviour="none"
      showFadeIn={!isMobile}
    >
      {renderModal()}

      {userDidNotAcceptTerms && modalType === 'terms' && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleAcceptTerms}
        >
          {() => (
            <Form>
              <Styled.ConsentWrapper mobile={isMobile} shortScreen={isShortScreen}>
                <FormControl
                  type="checkbox"
                  name="accepted"
                  as={Checkbox}
                  checkboxSize="large"
                  checked={isChecked}
                  label="By checking this box, you agree that you have read and accept these Terms"
                  onChange={() => handleCheckBox(isChecked)}
                />
                {error && <Styled.Error>{acceptedError}</Styled.Error>}
                <Styled.AcceptButtonWrapper mobile={isMobile} shortScreen={isShortScreen}>
                  <ProgressButton
                    disabled={!isChecked || acceptInProgress}
                    progress={acceptInProgress}
                    type="submit"
                    variant="success"
                    size={getAcceptanceButtonSize()}
                  >
                    Accept Terms
                  </ProgressButton>
                </Styled.AcceptButtonWrapper>
              </Styled.ConsentWrapper>
            </Form>
          )}
        </Formik>
      )}
      {userDidNotAcceptTerms && modalType !== 'terms' && (
        <Styled.CloseWrapper mobile={isMobile}>
          <Styled.CloseButtonWrapper mobile={isMobile}>
            <Button
              type="submit"
              size={isMobile ? 'medium' : 'large'}
              onClick={() => {
                setCurrentModalType('terms');
                onLocationChange?.('terms');
              }}
            >
              Back to the Terms
            </Button>
          </Styled.CloseButtonWrapper>
        </Styled.CloseWrapper>
      )}
      {closable && (
        <Styled.CloseWrapper mobile={isMobile}>
          <Styled.CloseButtonWrapper mobile={isMobile}>
            <Button
              type="submit"
              size={isMobile ? 'medium' : 'large'}
              onClick={() => {
                if (onClose) onClose();

                // Reset to initial url
                setCurrentModalType(modalType);
              }}
            >
              Close
            </Button>
          </Styled.CloseButtonWrapper>
        </Styled.CloseWrapper>
      )}
    </Modal>
  );
}
