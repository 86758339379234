import { ApolloError } from '@apollo/client';
import { Ref, useImperativeHandle, useRef, useState } from 'react';
import { useCreatePlaidAccessTokenMutation } from '../operations/mutations/createPlaidAccessTokenMutation';
import { PlaidActivationPayloadProps } from './usePlaidLinkToken';

export type PlaidAccessTokenImperativeHandleRef = {
  onSuccess: (payload: PlaidActivationPayloadProps) => void;
  setLoading: (loading: boolean) => void;
};

export type PlaidSuccessCallbackProps = {
  paymentSourceId?: string;
  accountName?: string;
  accountMask?: string;
  successMessage?: string;
};

export type PlaidAccessTokenProps = {
  onEventCallback?: (event: string) => void;
  onSuccessCallback?: (payload: PlaidSuccessCallbackProps) => void;
  onErrorCallback?: (msg: string) => void;
  imperativeHandleRef?: Ref<PlaidAccessTokenImperativeHandleRef>;
  successMessage?: string;
};

export default function usePlaidAccessToken({
  onSuccessCallback,
  onErrorCallback,
  successMessage,
  imperativeHandleRef,
}: PlaidAccessTokenProps) {
  const accountName = useRef<string>();
  const accountMask = useRef<string>('');
  const plaidPaymentSourceId = useRef<string>('');
  const [plaidAccessTokenLoading, setPlaidAccessTokenLoading] = useState<boolean>(false);
  const [
    createPlaidAccessToken,
    { loading: creatingPlaidAccessToken, error: plaidAccessTokenError },
  ] = useCreatePlaidAccessTokenMutation();

  const getPlaidAccessToken = async (payload: PlaidActivationPayloadProps) => {
    try {
      setPlaidAccessTokenLoading(true);
      // send public_token
      const token = await createPlaidAccessToken({
        variables: {
          publicToken: payload.token,
          handle: payload.handle,
        },
      });

      accountName.current = token.data?.createPlaidAccessToken.name ?? '';
      accountMask.current = token.data?.createPlaidAccessToken.mask ?? '';

      if (token && token.data?.createPlaidAccessToken.paymentSource?.id) {
        plaidPaymentSourceId.current =
          token.data?.createPlaidAccessToken.paymentSource.id.toString();

        onSuccessCallback?.({
          paymentSourceId: plaidPaymentSourceId.current,
          accountName: accountName.current,
          accountMask: accountMask.current,
          successMessage,
        });
      }
    } catch (error) {
      if (error instanceof ApolloError) {
        let msg = '';
        for (let i = 0; i < error.graphQLErrors.length; i++) {
          msg += `${error.graphQLErrors[i].message} \n`;
        }
        onErrorCallback?.(msg);
      }
    } finally {
      setPlaidAccessTokenLoading(false);
    }
  };

  const setLoading = (loading: boolean) => {
    setPlaidAccessTokenLoading(loading);
  };
  /*
    Due to the nature of the PlaidEmbeddedLink lib we need to have a handle to call
    the usePlaid success handler from outside. Basically PlaidEmbeddedLink is a functional component
    <PlaidEmbeddedLink> in the dom, I can't separate its onSuccess functionality from the view, but I want to keep
    our usePlaid hook. So this is the only drawback there is. But since the PlaidEmbeddedLink is beta (Jan 24)
    maybe there are changes one day that make it possible to extract the functionality somehow
  */
  useImperativeHandle(imperativeHandleRef, () => ({
    onSuccess: getPlaidAccessToken,
    setLoading,
  }));

  return {
    getPlaidAccessToken,
    plaidAccessTokenLoading,
    creatingPlaidAccessToken,
    plaidAccessTokenError,
    successMessage,
  };
}
