import { gql } from '../../gql';

const mappableShipmentFieldsQuery = gql(/* GraphQL */ `
  query MappableShipmentFieldsQuery {
    mappableShipmentFields {
      key
      maxMappings
      required
      title
    }
  }
`);

export default mappableShipmentFieldsQuery;
