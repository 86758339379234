import { useQuery } from '@apollo/client';
import { gql } from '../../gql';

export const batchUploadFormQuery = gql(/* GraphQL */ `
  query BatchUploadFormQuery($id: ID!) {
    batch(id: $id) {
      id
      title
      step
      labelSize
      customsFormRequired
      hsCodeRequired
      upload {
        id
      }
      packageSummary {
        packagePreset {
          id
          shy
          packageTypeKey
          dimensionX
          dimensionY
          dimensionZ
          weight
          insuredValueFlag
          insuredValue
          deliveryConfirmationFlag
          deliveryConfirmation
          returnLabelFlag
          returnLabel
          qualifiesAsMediaMail
          irregularPackage
          hazardousMaterialsEnabled
          customsFormEnabled
          customsSigner
          customsContentType
          customsItems {
            title
            quantity
            itemValue
            weight
            hsTariffNumber
            countryCodeOfOrigin
          }
          exporterTaxId
          recipientTaxId
          title
        }
      }
    }
    company {
      id
      activeCarriers
      settings {
        defaultWarehouseId
        defaultShipmentPresetId
      }
      warehouses {
        ...WarehouseFragment
      }
      shipmentPresets {
        ...ShipmentPresetFragment
      }
    }
    shipmentBoundaries {
      maxWeight
      maxCombinedLength
      maxLengthPlusGirth
      minLongSide
      maxLongSide
      minMiddleSide
      maxMiddleSide
      minShortSide
      maxShortSide
    }
    carriers {
      id
      carrierKey
      packageTypes {
        id
        packageTypeKey
        title
        description
        heightRequired
        weightRequired
        dimensionsRequired
      }
    }
    countries {
      id
      countryCode
      name
    }
  }
`);

export const useBatchUploadFormQuery = () => useQuery(batchUploadFormQuery);
