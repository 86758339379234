import * as yup from 'yup';
import { TitleAndDefaultFlagValues } from './TitleAndDefaultFlagSubform';

export const INITIAL_TITLE_DEFAULT_FLAG_SUBFORM_VALUES: TitleAndDefaultFlagValues = {
  title: '',
  asDefault: false,
};

export const titleDefaultValidationSchema = () =>
  yup.object<TitleAndDefaultFlagValues>({
    title: yup.string().required(),
    asDefault: yup.boolean().defined(),
  });
