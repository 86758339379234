import { Form, Formik } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { setFlashMessage } from '../../../apollo/cache/flashMessages';
import Checkbox from '../../../components/form/Checkbox';
import FormControl from '../../../components/form/FormControl';
import ProgressButton from '../../../components/form/ProgressButton';
import { Col, PageContainer, Row } from '../../../components/layout/Grid';
import PageHeader from '../../../components/layout/PageHeader';
import PageSubtitle from '../../../components/layout/PageSubtitle';
import PageLoading from '../../../components/loading/PageLoading';
import AddressSubform, {
  INITIAL_ADDRESS_SUBFORM_VALUES,
  addressValidationSchema,
  type AddressSubformValues,
} from '../../../components/subforms/AddressSubform';
import TitleAndDefaultFlagSubform, {
  titleDefaultValidationSchema,
  type TitleAndDefaultFlagValues,
} from '../../../components/subforms/TitleAndDefaultFlagSubform';
import { useCreateWarehouseMutation } from '../../../operations/mutations/createWarehouse';
import { useSetDefaultWarehouseMutation } from '../../../operations/mutations/setDefaultWarehouse';
import { useUpdateWarehouseMutation } from '../../../operations/mutations/updateWarehouse';
import { useWarehouseLazyQuery } from '../../../operations/queries/warehouse';
import deepCopyGraphqlData from '../../../utils/deepCopyGraphqlData';
import sanitizePhoneNumber from '../../../utils/sanitizePhoneNumber';

type ShipFromAddressFormValues = {
  titleDefault: TitleAndDefaultFlagValues;
  originAddress: AddressSubformValues;
  returnAddress: AddressSubformValues;
  useOriginAsReturnAddress: boolean;
};

const initialValues: ShipFromAddressFormValues = {
  titleDefault: { title: '', asDefault: true },
  originAddress: { ...INITIAL_ADDRESS_SUBFORM_VALUES },
  returnAddress: { ...INITIAL_ADDRESS_SUBFORM_VALUES },
  useOriginAsReturnAddress: true,
};

const initValidationSchema = () =>
  yup.object<ShipFromAddressFormValues>({
    titleDefault: titleDefaultValidationSchema().required(),
    originAddress: addressValidationSchema({ phone: 'US' }).required(),
    returnAddress: addressValidationSchema()
      .defined()
      .when(
        'useOriginAsReturnAddress',
        (useOriginAsReturnAddress: boolean, schema: yup.MixedSchema) =>
          useOriginAsReturnAddress ? yup.object() : schema.required(),
      ),
    useOriginAsReturnAddress: yup.boolean().defined(),
  });

type EditAddressProps = {
  entityId?: string;
};

export default function EditShipFromAddressPage({ entityId }: EditAddressProps) {
  const navigate = useNavigate();
  const params = useParams<'id'>();

  // entityId comes from bridge rendering and id from default routing
  const id = entityId || params.id;

  const [loading, setLoading] = useState(true);

  const validationSchema = useMemo(initValidationSchema, []);
  const [fetchWarehouse, { data: warehouseData, loading: warehouseLoading }] =
    useWarehouseLazyQuery();

  const [createWarehouse, { loading: creating }] = useCreateWarehouseMutation();
  const [updateWarehouse, { loading: updating }] = useUpdateWarehouseMutation();
  const [setDefaultWarehouse, { loading: settingDefault }] = useSetDefaultWarehouseMutation();
  const warehouse = deepCopyGraphqlData(
    warehouseData?.company.warehouses.find((wh) => wh.id === id),
  );
  const isDefault = warehouseData?.company.settings.defaultWarehouseId === id;

  useEffect(() => {
    if (warehouseData || !id) {
      setLoading(warehouseLoading);
    }
  }, [id, warehouseData, warehouseLoading]);

  useEffect(() => {
    if (id) {
      fetchWarehouse({
        variables: {
          id,
        },
      });
    }
  }, [fetchWarehouse, id]);

  const handleSubmit = useCallback(
    async ({
      titleDefault,
      originAddress,
      returnAddress,
      useOriginAsReturnAddress,
    }: ShipFromAddressFormValues) => {
      // Edit address
      if (id) {
        if (originAddress) {
          const result = await updateWarehouse({
            variables: {
              id,
              title: titleDefault.title,
              useOriginAsReturnAddress,
              originAddress: {
                ...originAddress,
                phone: originAddress.phone && sanitizePhoneNumber(originAddress.phone),
              },
              returnAddress: useOriginAsReturnAddress ? undefined : returnAddress,
            },
          });
          const asDefaultHasChanged = titleDefault.asDefault !== isDefault;
          if (result.data && asDefaultHasChanged) {
            await setDefaultWarehouse({
              variables: {
                id: titleDefault.asDefault ? result.data?.updateWarehouse.id : '', // Set as default? Set the warehouseid! Unset default? Set to "0"
              },
            });
          }
          navigate('/settings/shipfrom');
          setFlashMessage('Ship From Address updated', 'success', -1, true);
        }
        return;
      }
      // Create address
      if (originAddress) {
        const result = await createWarehouse({
          variables: {
            title: titleDefault.title,
            saveAddressForReuse: true,
            useOriginAsReturnAddress,
            originAddress: {
              ...originAddress,
              phone: originAddress.phone && sanitizePhoneNumber(originAddress.phone),
            },
            returnAddress: useOriginAsReturnAddress ? undefined : returnAddress,
          },
        });
        if (result.data && titleDefault.asDefault) {
          await setDefaultWarehouse({
            variables: {
              id: result.data?.createWarehouse.id,
            },
          });
        }
        navigate('/settings/shipfrom');
        setFlashMessage('Ship From Address was saved', 'success', -1, true);
      }
    },
    [id, updateWarehouse, navigate, setDefaultWarehouse, createWarehouse, isDefault],
  );

  return (
    <PageContainer>
      <Formik
        initialValues={{
          ...initialValues,
          ...(warehouse && {
            titleDefault: {
              title: warehouse.title,
              asDefault: isDefault,
            },
            originAddress: {
              ...warehouse.originAddress,
              postcode: `${warehouse.originAddress.postcode}${
                warehouse.originAddress.zip4 &&
                !warehouse.originAddress.postcode.match(/\d{5}-\d{4}/)
                  ? `-${warehouse.originAddress.zip4}`
                  : ''
              }`,
            },
            returnAddress: {
              ...warehouse.returnAddress,
              postcode: `${warehouse.returnAddress.postcode}${
                warehouse.returnAddress.zip4 &&
                !warehouse.originAddress.postcode.match(/\d{5}-\d{4}/)
                  ? `-${warehouse.returnAddress.zip4}`
                  : ''
              }`,
            },
            useOriginAsReturnAddress: warehouse.useOriginAsReturnAddress,
          }),
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ values: { useOriginAsReturnAddress } }) => (
          <Form noValidate>
            {loading ? (
              <PageLoading />
            ) : (
              <>
                <Row>
                  <Col md={12}>
                    <PageHeader
                      title={id ? 'Edit Ship From Address' : 'Create New Ship From Address'}
                    />
                  </Col>
                  <Col md={12}>
                    <TitleAndDefaultFlagSubform<keyof ShipFromAddressFormValues>
                      namespace="titleDefault"
                      titleLabel="Nickname this Ship From Address"
                    />
                  </Col>
                  <Col md={12}>
                    <PageSubtitle>Physical Address</PageSubtitle>
                  </Col>
                  <Col spaceBelow md={12}>
                    Enter the physical home, office, or warehouse address you&apos;re shipping from.
                    This is where the carrier will come if you schedule a pickup, and where your
                    shipping rates will be calculated from.
                  </Col>
                  <Col md={12}>
                    <AddressSubform<keyof ShipFromAddressFormValues>
                      namespace="originAddress"
                      phone="US"
                    />
                  </Col>
                  <Col spaceBelow md={12}>
                    <FormControl
                      name="useOriginAsReturnAddress"
                      type="checkbox"
                      as={Checkbox}
                      label="Use this address as the Return Address on my shipping labels"
                      disabled={loading}
                    />
                  </Col>
                  {!useOriginAsReturnAddress && (
                    <>
                      <Col md={12}>
                        <PageSubtitle>Return Address (optional)</PageSubtitle>
                      </Col>
                      <Col spaceBelow md={12}>
                        Enter the address you&apos;d like printed on your shipping labels. This is
                        where undeliverable packages will be returned to.
                      </Col>
                      <Col md={12} spaceBelow>
                        <AddressSubform<
                          keyof ShipFromAddressFormValues
                        > namespace="returnAddress" />
                      </Col>
                    </>
                  )}
                </Row>

                <Row justify="end">
                  <Col sm="content">
                    <ProgressButton
                      fullWidth
                      type="submit"
                      size="xLarge"
                      variant="primary"
                      disabled={loading || creating || updating || settingDefault}
                      progress={creating || updating || settingDefault}
                    >
                      {id ? 'Save Ship From Address' : 'Create Ship From Address'}
                    </ProgressButton>
                  </Col>
                </Row>
              </>
            )}
          </Form>
        )}
      </Formik>
    </PageContainer>
  );
}
