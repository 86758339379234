import { useQuery } from '@apollo/client';
import { gql } from '../../gql';

export const packageTypesQuery = gql(/* GraphQL */ `
  query PackageTypesQuery {
    carriers {
      id
      carrierKey
      packageTypes {
        id
        packageTypeKey
        title
        description
        heightRequired
        weightRequired
        dimensionsRequired
      }
    }
    company {
      id
      activeCarriers
    }
  }
`);

export const usePackageTypesQuery = () => useQuery(packageTypesQuery);
