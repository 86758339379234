import { useMutation } from '@apollo/client';
import { gql } from '../../gql';

export const createScopedShipmentPresetMutation = gql(/* GraphQL */ `
  mutation CreateScopedShipmentPreset(
    $originalPresetId: ID!
    $shipmentPreset: ShipmentPresetInput!
  ) {
    createScopedShipmentPreset(originalPresetId: $originalPresetId, values: $shipmentPreset) {
      id
    }
  }
`);

export const useCreateScopedShipmentPresetMutation = () =>
  useMutation(createScopedShipmentPresetMutation);
