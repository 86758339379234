import { StoreObject, useMutation } from '@apollo/client';
import { gql } from '../../gql';

export const deleteShipmentPresetMutation = gql(/* GraphQL */ `
  mutation DeleteShipmentPresetMutation($id: ID!) {
    deleteShipmentPreset(id: $id) {
      id
    }
  }
`);

export const useDeleteShipmentPresetMutation = () =>
  useMutation(deleteShipmentPresetMutation, {
    // Remove entry from list on delete
    update: (cache, { data }) => {
      if (!data) {
        return;
      }

      cache.updateQuery(
        {
          query: gql(/* GraphQL */ `
            query UpdateShipmentPresetsAfterDeleteQuery {
              company {
                id
                shipmentPresets {
                  ...ShipmentPresetFragment
                }
                settings {
                  defaultShipmentPresetId
                }
              }
            }
          `),
        },
        (cacheResult) => {
          if (!cacheResult) {
            return undefined;
          }

          const existingEntries = cacheResult.company.shipmentPresets;
          const entryIndex = existingEntries.findIndex(
            (entry) => entry.id === data.deleteShipmentPreset.id,
          );

          if (entryIndex >= 0) {
            return {
              company: {
                ...cacheResult.company,
                shipmentPresets: [
                  ...existingEntries.slice(0, entryIndex),
                  ...existingEntries.slice(entryIndex + 1),
                ],
                settings: {
                  ...cacheResult.company.settings,
                  defaultShipmentPresetId:
                    data.deleteShipmentPreset.id ===
                    cacheResult.company.settings.defaultShipmentPresetId
                      ? '0'
                      : cacheResult.company.settings.defaultShipmentPresetId,
                },
              },
            };
          }

          return undefined;
        },
      );
      cache.evict({ id: cache.identify(data.deleteShipmentPreset as unknown as StoreObject) });
      cache.gc();
    },
  });
