import FormControl from '../../../components/form/FormControl';
import TextField from '../../../components/form/TextField';

type SignupEmailProps = {
  onPaste: () => void;
};

export default function SignupEmail({ onPaste }: SignupEmailProps) {
  return (
    <FormControl
      name="email"
      type="email"
      as={TextField}
      label="Enter your email"
      onPaste={onPaste}
    />
  );
}
