import styled from '@emotion/styled';
import { HeaderContext } from '@tanstack/react-table';
import { RefObject, Suspense, useCallback } from 'react';
import useDateInUserTimezone from '../../../hooks/useDateInUserTimezone';
import { SPACING } from '../../../styles/spacing';
import Button from '../../form/Button';
import DatePickerField from '../../form/dateTimePickers/DatePickerField';
import Shimmer from '../../loading/Shimmer';
import ButtonBar from '../controls/ButtonBar';

const Styled = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    gap: ${SPACING.sm};
  `,
};

export default function DateTimeRangeFilterInterface<TData, TValue>(
  headerContext: HeaderContext<TData, TValue> & { overlayRef?: RefObject<HTMLDialogElement> },
) {
  const { column, table, overlayRef } = headerContext;
  const { createDate } = useDateInUserTimezone();
  const filterValue = column.getFilterValue() as Array<string | undefined>;
  const loading = table.options.meta?.loading;
  const [startDate, endDate] = filterValue ?? [];
  const clear = useCallback(() => column.setFilterValue(undefined), [column]);

  return (
    <>
      <Styled.Wrapper>
        <div>Select date range</div>
        <Suspense
          fallback={[
            <Shimmer.Rectangle height={30} key={0} />,
            <Shimmer.Rectangle height={30} key={1} />,
          ]}
        >
          <DatePickerField
            calendarRef={overlayRef}
            closeOnSelect
            size="small"
            value={startDate ? createDate(startDate) : undefined}
            localOrUTC="UTC"
            onChange={(date) => column.setFilterValue([date.toISOString(), endDate])}
            disabled={loading}
            timePicker
            defaultTimeToMidnightOfUserTimezone
          />
          <DatePickerField
            calendarRef={overlayRef}
            closeOnSelect
            size="small"
            value={endDate ? createDate(endDate) : undefined}
            localOrUTC="UTC"
            onChange={(date) => column.setFilterValue([startDate, date.toISOString()])}
            disabled={loading}
            timePicker
            defaultTimeToMidnightOfUserTimezone
          />
        </Suspense>
      </Styled.Wrapper>
      <ButtonBar>
        <Button size="small" onClick={clear}>
          Clear
        </Button>
      </ButtonBar>
    </>
  );
}
