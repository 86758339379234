import {
  UserEmailVerificationFailedModal,
  UserEmailVerificationSucceededModal,
} from '@ps/userEmailVerification';

export default function GlobalUserEmailVerificationModals() {
  return (
    <>
      <UserEmailVerificationSucceededModal />
      <UserEmailVerificationFailedModal />
    </>
  );
}
