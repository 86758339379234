import { useEffect } from 'react';
import { DatetimeRangeInput } from '../../../../gql/graphql';
import { useShipmentPieChartLazyQuery } from '../../../../operations/queries/shipmentPieChart';
import PieChart from '../../../charts/PieChart';
import { Col } from '../../../layout/Grid';
import { ReportsOverviewStyles } from '../ReportsOverviewPage';

export type ReportsServicesChartProps = {
  dateFormatter: () => DatetimeRangeInput;
};

export default function ReportsServicesChart({ dateFormatter }: ReportsServicesChartProps) {
  const [loadShipmentPieChart, { loading: shipmentPieChartLoading, data: shipmentPieChartData }] =
    useShipmentPieChartLazyQuery({
      variables: {
        datetimeRange: dateFormatter(),
      },
    });

  useEffect(() => {
    loadShipmentPieChart({
      variables: {
        datetimeRange: dateFormatter(),
      },
    });
  }, [dateFormatter, loadShipmentPieChart]);

  return (
    <Col md={4}>
      <ReportsOverviewStyles.ShortChart
        title="Services"
        fullHeight
        loading={shipmentPieChartLoading}
        labelId="reports-services-headline"
      >
        <PieChart
          data={shipmentPieChartData?.shipmentPieChart.charts ?? []}
          labels={shipmentPieChartData?.shipmentPieChart.labels ?? []}
          customOptions={{
            chart: {
              className: 'screen-chart',
            },
            accessibility: {
              screenReaderSection: {
                beforeChartFormat: '<div>{typeDescription}</div>',
              },
              point: {
                valueSuffix: '%',
              },
            },
          }}
        />
        <PieChart
          data={shipmentPieChartData?.shipmentPieChart.charts ?? []}
          labels={shipmentPieChartData?.shipmentPieChart.labels ?? []}
          customOptions={{
            accessibility: {
              enabled: false,
            },
            chart: {
              className: 'print-chart',
              height: 225,
              width: 270,
              marginBottom: 75,
            },
            legend: {
              enabled: true,
              backgroundColor: 'rgba(255,255,255,0.75)',
              width: 250,
              layout: 'vertical',
              align: 'left',
              floating: true,
              labelFormat: '{name}: {y}%',
            },
          }}
        />
      </ReportsOverviewStyles.ShortChart>
    </Col>
  );
}
