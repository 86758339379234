import { RateGroup, RateSummary } from '../components/form/RateSummarySelect';
import { CarrierKey } from '../gql/graphql';
import difference from './setDifference';

// create a set of carrier ids NOT included in the rate summaries
export const getMissingCarrierKeys = (rateSummaries: readonly RateSummary[]) =>
  difference<CarrierKey>(
    new Set(['ups', 'usps']) as Set<CarrierKey>, // typescript needs help here
    new Set(
      rateSummaries.flatMap(({ carrier, totalPrice }) =>
        totalPrice > 0 ? [carrier.carrierKey] : [],
      ),
    ),
  );

export default function filterRateSummaries(rateGroup: RateGroup) {
  const missingCarrierKeys = getMissingCarrierKeys(rateGroup.rateSummaries);

  return rateGroup.rateSummaries.filter((summary: RateSummary) => {
    // filter out UPS options if no UPS options are available
    if (missingCarrierKeys.has('ups') && summary.carrier.carrierKey === 'ups') {
      return false;
    }

    // filter out USPS options if no USPS options are available
    if (missingCarrierKeys.has('usps') && summary.carrier.carrierKey === 'usps') {
      return false;
    }

    // filter out any options that are not available (i.e. have a total price of 0) if the group only has one shipment
    if (rateGroup.maximumShipments === 1 && summary.totalPrice === 0) {
      return false;
    }

    // do not show summary when it is not available for any shipment in the group
    if (summary.shipmentCount === 0) {
      return false;
    }

    return true;
  });
}
