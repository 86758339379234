import * as Highcharts from 'highcharts';
import Chart from 'highcharts-react-official';
import HighchartsA11y from 'highcharts/modules/accessibility';
import { standardChartOptions } from '../../styles/charts';
import { DEFAULT_CHART_COLORS } from '../../styles/colors';
import { ChartProps } from './types';

HighchartsA11y(Highcharts);

export default function PieChart({
  labels,
  colors = DEFAULT_CHART_COLORS,
  data,
  customOptions,
}: ChartProps) {
  const options: Highcharts.Options = Highcharts.merge(
    {
      chart: {
        height: 320,
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          colors: [...colors],
          borderWidth: 3,
          dataLabels: {
            enabled: false,
          },
        },
      },
      series: [
        {
          type: 'pie',
          data: labels.map((l, i) => ({ name: l, y: data[i] })),
          showInLegend: true,
        },
      ],
      tooltip: {
        formatter() {
          // eslint-disable-next-line react/no-this-in-sfc
          return `${this.point.name}: ${this.percentage?.toPrecision(3)}%`;
        },
      },
    },
    standardChartOptions,
    customOptions,
  );
  return <Chart highcharts={Highcharts} options={options} />;
}
