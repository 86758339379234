import { LazyQueryHookOptions, useLazyQuery } from '@apollo/client';
import { gql } from '../../gql';
import { WarehouseQuery, WarehouseQueryVariables } from '../../gql/graphql';

export const warehouseQuery = gql(/* GraphQL */ `
  query WarehouseQuery($id: ID!) {
    company {
      id
      settings {
        defaultWarehouseId
      }
      warehouses(id: $id) {
        ...WarehouseFragment
      }
    }
  }
`);

export const useWarehouseLazyQuery = (
  options?: LazyQueryHookOptions<WarehouseQuery, WarehouseQueryVariables>,
) => useLazyQuery(warehouseQuery, options);
