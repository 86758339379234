import styled from '@emotion/styled';
import { BORDER_RADIUS, BORDER_WIDTH } from '../../../styles/borders';
import { COLOR, GREYSCALE } from '../../../styles/colors';

const Styled = {
  PageSizeSelect: styled.select`
    grid-area: pagesizeselect;
    justify-self: end;
    height: 28px;
    max-width: 9em;
    min-width: 7em;
    border: ${BORDER_WIDTH.xs} solid ${GREYSCALE.grey30};
    border-radius: ${BORDER_RADIUS.sm};
    background: ${GREYSCALE.white} !important;
    color: ${GREYSCALE.grey80};

    :hover {
      background-color: ${GREYSCALE.grey20};
      border-color: ${GREYSCALE.grey50};
    }

    :focus {
      border-color: ${COLOR.blue};
    }
  `,
  PageSizeSelectOption: styled.option`
    :checked {
      box-shadow: 0 0 10px 100px ${GREYSCALE.black} inset;
      background: #000 !important;
      color: ${GREYSCALE.white};
    }
  `,
};

export type PageSizeSelectProps = {
  pageSize: number;
  setPageSize: (pageSize: number) => void;
  pageSizeOptions: readonly number[];
  maxPageSize: number;
};

export default function PageSizeSelect({
  pageSize,
  setPageSize,
  pageSizeOptions,
  maxPageSize,
}: PageSizeSelectProps) {
  return (
    <Styled.PageSizeSelect
      data-testid="page-size-select"
      value={pageSize}
      onChange={(e) => setPageSize(Number(e.target.value))}
    >
      {pageSizeOptions
        .filter((size) => size <= maxPageSize)
        .map((size) => (
          <Styled.PageSizeSelectOption key={size} value={size}>
            Show {size}
          </Styled.PageSizeSelectOption>
        ))}
    </Styled.PageSizeSelect>
  );
}
