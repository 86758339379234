import { ShipFromAddressSubformValues } from '.';
import { NEW_WAREHOUSE_VALUE } from '../../../constants';
import { AddressInput } from '../../../gql/graphql';
import { useCreateWarehouseMutation } from '../../../operations/mutations/createWarehouse';
import sanitizePhoneNumber from '../../../utils/sanitizePhoneNumber';
import { AddressSubformValues } from '../AddressSubform';

export default function useShipFromAddress() {
  const [createWarehouse, { loading }] = useCreateWarehouseMutation();

  return [
    // First array element is the function that creates a warehouse
    async ({ warehouseId, warehouse }: ShipFromAddressSubformValues) => {
      if (warehouseId === NEW_WAREHOUSE_VALUE) {
        const {
          saveAddressForReuse,
          useOriginAsReturnAddress,
          originAddress,
          returnAddress,
          title,
        } = warehouse;

        const { data, errors } = await createWarehouse({
          awaitRefetchQueries: true,
          variables: {
            title,
            saveAddressForReuse, // create a new warehouse or not
            useOriginAsReturnAddress,
            originAddress: mapAddressToWarehouseAddress(originAddress),
            returnAddress: useOriginAsReturnAddress
              ? undefined
              : mapAddressToWarehouseAddress(returnAddress),
          },
        });

        if (data) {
          return data.createWarehouse.id;
        }

        throw new Error(errors?.[0].message ?? 'Failed to create warehouse');
      }

      return warehouseId;
    },
    // Second array element is the loading state of the mutation
    { loading },
  ] as const;
}

function mapAddressToWarehouseAddress(address: AddressSubformValues): AddressInput {
  return {
    fullName: address.fullName,
    company: address.company,
    address1: address.address1,
    address2: address.address2,
    city: address.city,
    regionCode: address.regionCode,
    postcode: address.postcode,
    countryCode: address.countryCode,
    phone: address.phone && sanitizePhoneNumber(address.phone),
  };
}
