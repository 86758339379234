/* eslint-disable react/no-unescaped-entities */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import useIsMobile from '../../../hooks/useIsMobile';
import { SPACING } from '../../../styles/spacing';
import convertBbcode from '../../../utils/convertBbcode';
import { PageContainer } from '../../layout/Grid';
import PageHeader from '../../layout/PageHeader';
import PageSectionDescription from '../../layout/PageSectionDescription';

import useInModal from '../../../hooks/useInModal';
import { useLatestTermsQuery } from '../../../operations/queries/latestTerms';
import { BORDER_RADIUS } from '../../../styles/borders';
import { MEDIA_QUERY } from '../../../styles/breakpoints';
import { GREYSCALE } from '../../../styles/colors';
import { TYPOGRAPHY } from '../../../styles/typography';
import ContentLoading from '../../loading/ContentLoading';

type TermsOfUseProps = {
  requireAcceptance?: boolean;
  clickHandler?: (elem: HTMLDivElement | null) => void;
};

const Styled = {
  PageContainerWithLoading: styled(PageContainer)<{
    loading: boolean;
    shortScreen: boolean;
    modal?: boolean;
  }>`
    max-width: 750px;
    @media (max-width: ${MEDIA_QUERY.xsMax}) {
      height: ${({ loading }) => (loading ? '85vh' : 'auto')}; // 85vh = minus header height
    }
    p {
      margin-block: ${SPACING.none} ${SPACING.md} !important;
    }
    ${({ shortScreen }) =>
      shortScreen
        ? css`
            header {
              padding-bottom: ${SPACING.md};
            }
            > p,
            > p > p {
              margin-block: 0;
            }
          `
        : ''};
    ${({ modal }) =>
      !modal &&
      css`
        header {
          padding-bottom: ${SPACING.xl};
        }
      `}
  `,
  Scrollbox: styled.div<{ requireAcceptance: boolean; shortScreen: boolean }>`
    height: ${({ requireAcceptance }) => (requireAcceptance ? '400px' : '500px')};
    max-width: 80ch;
    overflow: auto;
    border: ${SPACING.xxs} solid ${GREYSCALE.grey30};
    border-radius: ${BORDER_RADIUS.sm};
    padding: ${SPACING.lg};
    margin-top: -3px;
    ${({ shortScreen }) =>
      shortScreen
        ? css`
            height: 300px;
          `
        : ''};
  `,
  Content: styled.div`
    h2 {
      margin-block: 1.4em 1em;
    }
    p,
    li {
      font-size: ${TYPOGRAPHY.fontSize.sm};
    }
  `,
  TermsHeader: styled(PageSectionDescription)`
    margin-top: -${SPACING.xl};
    margin-bottom: ${SPACING.xxl};
  `,
  MobileTermsContent: styled.div`
    margin-bottom: 140px; // height of accept button div
  `,
};

function TermsOfUsePage({ requireAcceptance = false, clickHandler }: TermsOfUseProps) {
  const { data, loading } = useLatestTermsQuery();
  const { isMobile, isShortScreen } = useIsMobile();
  const { inModal } = useInModal();

  // replace legacy headline, dirty but I see no other way right now, will be removed when SPA goes 100%
  const text = convertBbcode(data?.latestTerms.terms.replace('[h1]Terms of Use[/h1]', '') || '');

  return (
    <Styled.PageContainerWithLoading loading={loading} shortScreen={isShortScreen}>
      <PageHeader title={requireAcceptance ? 'Accept the Terms of Use' : 'Terms of Use'} />

      {requireAcceptance && (
        <Styled.TermsHeader>
          <p>The landlubbin' lawyers require your agreement to these terms 😉</p>
          <p>Scroll through them, check the box, and get back to y'er shippin' matey!</p>
        </Styled.TermsHeader>
      )}

      {!isMobile && inModal && (
        <Styled.Scrollbox
          ref={clickHandler}
          requireAcceptance={requireAcceptance}
          shortScreen={isShortScreen}
        >
          {loading ? <ContentLoading /> : text}
        </Styled.Scrollbox>
      )}

      {isMobile && inModal && (
        <Styled.MobileTermsContent ref={clickHandler}>
          {loading ? <ContentLoading /> : text}
        </Styled.MobileTermsContent>
      )}

      {!inModal && <Styled.Content>{loading ? <ContentLoading /> : text}</Styled.Content>}
    </Styled.PageContainerWithLoading>
  );
}

export default TermsOfUsePage;
