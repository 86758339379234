import { ApolloError } from '@apollo/client';
import styled from '@emotion/styled';
import { Form, Formik, FormikHelpers } from 'formik';
import { useState } from 'react';
import { dispatch } from 'use-bus';
import * as yup from 'yup';
import { addFlashMessage, setFlashMessage } from '../../../apollo/cache/flashMessages';
import { UiEvents } from '../../../constants';
import { PaymentSource } from '../../../gql/graphql';
import useAriaAnnouncer from '../../../hooks/useAriaAnnouncer';
import useFeature from '../../../hooks/useFeature';
import useNavigateOrHref from '../../../hooks/useNavigateOrHref';
import { useAddCreditToPaymentSource } from '../../../operations/mutations/addCreditToPaymentSource';
import { billingPageQuery, useBillingPageQuery } from '../../../operations/queries/billingPage';
import loggingService from '../../../services/logging';
import { GREYSCALE } from '../../../styles/colors';
import { SPACING } from '../../../styles/spacing';
import { TYPOGRAPHY } from '../../../styles/typography';
import { formatCurrency } from '../../../utils/currency';
import environment from '../../../utils/environment';
import formatPaymentSourceTitle from '../../../utils/formatPaymentSourceTitle';
import FormControl from '../../form/FormControl';
import InputGroup from '../../form/InputGroup';
import Label from '../../form/Label';
import ProgressButton from '../../form/ProgressButton';
import Select, { Option } from '../../form/Select';
import TextField from '../../form/TextField';
import { Col, PageContainer, Row } from '../../layout/Grid';
import PageHeader from '../../layout/PageHeader';
import PageSubtitle from '../../layout/PageSubtitle';
import PageLoading from '../../loading/PageLoading';
import ChangePaymentSourceModal from '../../modals/ChangePaymentSourceModal';
import PlaidRelinkModal from '../../modals/PlaidRelinkModal';
import PlaidRelinkingHelpModal from '../../modals/PlaidRelinkingHelpModal';

const Styled = {
  Col: styled(Col)`
    margin-block-start: calc(
      (${TYPOGRAPHY.body.fontSize} * ${TYPOGRAPHY.body.lineHeight}) + (2 * ${SPACING.sm})
    );
  `,
  CurrencyInput: styled(FormControl)`
    text-align: right;
  `,
  ShiftedSubheading: styled(PageSubtitle)`
    color: ${GREYSCALE.grey60};
    font-size: ${TYPOGRAPHY.fontSize.md};
    font-weight: normal;
    margin-top: -${SPACING.xl};
  `,
};

type CreditValues = {
  paymentSourceId: string;
  addCreditAmount: number | '';
};

export default function AddCreditPage() {
  const navigate = useNavigateOrHref();
  const logger = loggingService.getLogger();
  const announce = useAriaAnnouncer();
  const { data, loading } = useBillingPageQuery();
  const [creditInProgress, setCreditInProgress] = useState<boolean>(false);
  const enablePlaidRelinkingHelpModal = useFeature('Plaid.relinking.help.modal');
  const [plaidRelinkingHelpModalOpen, setPlaidRelinkingHelpModalOpen] = useState(false);
  const [plaidRelinkingModalOpen, setPlaidRelinkingModalOpen] = useState(false);
  const [plaidPaymentSourceId, setPlaidPaymentSourceId] = useState<string>('');
  const [failedPaymentSourceId, setFailedPaymentSourceId] = useState<string | null>(null);
  const [addCreditToPaymentSource] = useAddCreditToPaymentSource();

  if (!data || loading)
    return (
      <PageContainer>
        <PageLoading />
      </PageContainer>
    );

  const { accountBalance } = data.company;
  // do not show Offline payment for add credit
  const accountPaymentSourceOptions: Option[] = data.company.paymentSources
    .filter((paymentSource) => paymentSource.paymentMethodType !== 'manual')
    .map(
      (paymentSource) =>
        ({
          value: paymentSource.id,
          title: formatPaymentSourceTitle(paymentSource),
        } as Option),
    );

  const initialPaymentSourceId = (() => {
    if (accountPaymentSourceOptions.length > 1) {
      return data.company.settings.defaultPaymentSourceId;
    }
    if (accountPaymentSourceOptions.length === 1) {
      return String(accountPaymentSourceOptions[0].value);
    }
    return '';
  })();

  const initialValues: CreditValues = {
    paymentSourceId: initialPaymentSourceId,
    addCreditAmount: '',
  };

  const performAddCredit = async (
    values: CreditValues,
    { setFieldValue }: FormikHelpers<CreditValues>,
  ) => {
    setCreditInProgress(true);
    try {
      await addCreditToPaymentSource({
        variables: {
          paymentSourceId: values.paymentSourceId,
          addCreditAmount: Number(values.addCreditAmount),
        },
        refetchQueries: [billingPageQuery],
        awaitRefetchQueries: true,
        context: { errorHandled: true },
      });
      const message = `Y'ee successfully added an amount of ${formatCurrency(
        Number(values.addCreditAmount),
      )} from ${
        accountPaymentSourceOptions.find((option) => option.value === values.paymentSourceId)?.title
      }.`;
      addFlashMessage(message, 'success');
      announce(message, 'polite');
      setCreditInProgress(false);
      setFieldValue('addCreditAmount', '', false);
    } catch (error) {
      if (error instanceof ApolloError) {
        error.graphQLErrors.forEach(({ extensions, message }) => {
          if (environment.isBridge() && extensions?.redirect) {
            window.location.reload();
            return;
          }

          if (extensions?.failureReason === 'PLAID_UNLINKED_ACCOUNT') {
            setPlaidPaymentSourceId(values.paymentSourceId);

            if (enablePlaidRelinkingHelpModal) {
              setPlaidRelinkingHelpModalOpen(true);
            } else {
              setPlaidRelinkingModalOpen(true);
            }

            return;
          }

          if (
            extensions?.failureReason === 'PAYMENT_SOURCE_DECLINED' ||
            extensions?.failureReason === 'PLAID_SERVER_UNREACHABLE'
          ) {
            dispatch(UiEvents.APirateIsSad);
            setFailedPaymentSourceId(values.paymentSourceId);
            setCreditInProgress(true);

            return;
          }

          setFlashMessage(message, 'danger');
          announce(message, 'assertive');
          setCreditInProgress(false);
        });
      }
    }
  };

  return (
    <PageContainer>
      <PageHeader title="Add Credit to your Pirate Ship account" />
      <Styled.ShiftedSubheading>
        Current balance: {formatCurrency(accountBalance)}
      </Styled.ShiftedSubheading>

      <Formik
        initialValues={initialValues}
        validationSchema={yup.object<CreditValues>({
          paymentSourceId: yup.string().required(),
          addCreditAmount: yup.number().min(1).max(99999).required(),
        })}
        onSubmit={performAddCredit}
      >
        {({ values, setFieldValue, submitForm }) => (
          <>
            {enablePlaidRelinkingHelpModal && (
              <PlaidRelinkingHelpModal
                open={plaidRelinkingHelpModalOpen}
                onSuccess={() => {
                  if (!plaidPaymentSourceId) {
                    logger.error('Plaid payment source id not found, abort relinking process');
                    return;
                  }
                  setPlaidRelinkingHelpModalOpen(false);
                  setPlaidRelinkingModalOpen(true);
                }}
                onClose={() => {
                  setPlaidRelinkingHelpModalOpen(false);
                  setCreditInProgress(false);
                }}
              />
            )}
            <PlaidRelinkModal
              open={plaidRelinkingModalOpen}
              origin="add_credit_page"
              paymentSourceId={plaidPaymentSourceId}
              onClose={() => {
                setPlaidRelinkingModalOpen(false);
                setCreditInProgress(false);
              }}
              onSuccess={() => submitForm()}
            />
            {failedPaymentSourceId !== null && (
              <ChangePaymentSourceModal
                open
                selectedPaymentSourceId={failedPaymentSourceId}
                paymentSources={data?.company.paymentSources as PaymentSource[]}
                submitTitle="Add Credit"
                submitButtonVariant="primary"
                onPaymentSourceChange={async (paymentSourceId: string) => {
                  setFailedPaymentSourceId(null);
                  await setFieldValue('paymentSourceId', paymentSourceId, false);
                  values.paymentSourceId = paymentSourceId; // Work around Formik setFieldValue update not being immediate
                  await submitForm();
                }}
                onPaymentSourceAddRequest={() => {
                  navigate('/settings/billing');
                }}
                onClose={() => {
                  setFailedPaymentSourceId(null);
                  setCreditInProgress(false);
                }}
              />
            )}
            <Form>
              <Row>
                <Col spaceBelow md={6} xs={12}>
                  <Label>Payment Source</Label>
                  <FormControl
                    name="paymentSourceId"
                    as={Select}
                    options={accountPaymentSourceOptions}
                  />
                </Col>
                <Col md={4} xs={12}>
                  <Label secondary="- minimum $1.00">Amount</Label>
                  <InputGroup prefixIcon="dollar-sign">
                    <Styled.CurrencyInput
                      name="addCreditAmount"
                      as={TextField}
                      type="number"
                      placeholder="1.00"
                    />
                  </InputGroup>
                </Col>
                <Styled.Col md={2} xs={12}>
                  <ProgressButton
                    variant="primary"
                    size="medium"
                    type="submit"
                    disabled={creditInProgress}
                    progress={creditInProgress}
                  >
                    Add Credit
                  </ProgressButton>
                </Styled.Col>
              </Row>
            </Form>
          </>
        )}
      </Formik>
    </PageContainer>
  );
}
