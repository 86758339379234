import { TooltipFormatterCallbackFunction } from 'highcharts';
import { useEffect } from 'react';
import { DatetimeRangeInput } from '../../../../gql/graphql';
import { useZoneChartLazyQuery } from '../../../../operations/queries/zoneChart';
import BarChart from '../../../charts/BarChart';
import { Col } from '../../../layout/Grid';
import { ReportsOverviewStyles } from '../ReportsOverviewPage';

type ReportsRecipientZoneChartProps = {
  dateFormatter: () => DatetimeRangeInput;
  percentFormatter: TooltipFormatterCallbackFunction;
};

export default function ReportsRecipientZoneChart({
  dateFormatter,
  percentFormatter,
}: ReportsRecipientZoneChartProps) {
  const [loadZoneChart, { data: zoneChartData, loading: zoneChartLoading }] = useZoneChartLazyQuery(
    {
      variables: {
        datetimeRange: dateFormatter(),
      },
    },
  );

  useEffect(() => {
    loadZoneChart({
      variables: {
        datetimeRange: dateFormatter(),
      },
    });
  }, [dateFormatter, loadZoneChart]);

  return (
    <Col md={8} sm={12}>
      <ReportsOverviewStyles.ShortChart
        title="Recipient Zones"
        fullHeight
        loading={zoneChartLoading}
        labelId="reports-recipient-zone-charts-headline"
      >
        <BarChart
          data={zoneChartData?.zoneChart.charts ?? []}
          labels={zoneChartData?.zoneChart.labels ?? []}
          customOptions={{
            chart: {
              className: 'screen-chart',
            },
            accessibility: {
              point: {
                valueDecimals: 1,
                valueSuffix: '%',
              },
            },
            xAxis: {
              accessibility: {
                description: `zones 1 to 9`,
              },
            },
            yAxis: {
              accessibility: {
                description: `percentage of shipments`,
                rangeDescription: '',
              },
            },
            tooltip: {
              formatter: percentFormatter,
            },
          }}
        />
        <BarChart
          data={zoneChartData?.zoneChart.charts ?? []}
          labels={zoneChartData?.zoneChart.labels ?? []}
          customOptions={{
            accessibility: {
              enabled: false,
            },
            chart: {
              className: 'print-chart',
              height: 220,
              width: 600,
              marginBottom: 45,
            },
            plotOptions: {
              series: {
                dataLabels: {
                  enabled: true,
                  format: '{y}%',
                  align: 'left',
                  x: 8,
                  y: -10,
                  crop: false,
                  overflow: 'allow',
                },
              },
            },
            tooltip: {
              formatter: percentFormatter,
            },
          }}
        />
      </ReportsOverviewStyles.ShortChart>
    </Col>
  );
}
