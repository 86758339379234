import styled from '@emotion/styled';
import { getIn, useFormikContext } from 'formik';
import { Hidden } from 'react-grid-system';
import Icon from '../../../components/Icon';
import IntercomArticleLink from '../../../components/IntercomArticleLink';
import { LinkButton } from '../../../components/Link';
import CountrySelect from '../../../components/form/CountrySelect';
import { MultiFieldErrorMessage } from '../../../components/form/ErrorMessage';
import FormControl from '../../../components/form/FormControl';
import InputGroup from '../../../components/form/InputGroup';
import InputSubText from '../../../components/form/InputSubText';
import Label from '../../../components/form/Label';
import TextField from '../../../components/form/TextField';
import { Col, Row } from '../../../components/layout/Grid';
import { NamespacedSubform } from '../../../components/subforms/types';
import { useCountriesQuery } from '../../../operations/queries/countries';
import { BORDER_WIDTH } from '../../../styles/borders';
import { COLOR, GREYSCALE } from '../../../styles/colors';
import { OPACITY } from '../../../styles/opacities';
import { CustomsFormSubformValues } from './CustomsFormSubform';

const ICON_SIZE = '50px';

const Styled = {
  Operator: styled.p`
    margin: 0;
    padding: 15px 0;
    line-height: 20px;
    text-align: center;
    color: ${GREYSCALE.grey50};
  `,
  IconButton: styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${ICON_SIZE};
    height: ${ICON_SIZE};
    margin: 10px auto 0;
    border: ${BORDER_WIDTH.none};
    background: none;
    opacity: ${OPACITY.disabled};
    cursor: pointer;

    :not(:disabled):hover {
      opacity: 1;
      color: ${COLOR.blue};
    }
  `,
};

export type CustomsLineItemSubformValues = {
  title: string;
  quantity: number | '';
  itemValue: number | '';
  weightPounds: number | '';
  weightOunces: number | '';
  combinedWeight?: never; // fake fields that do not contain a value but are used for multi-field validation
  hsTariffNumber?: string;
  countryCodeOfOrigin: string;
};

export type CustomsLineItemSubformProps<NS extends string> = NamespacedSubform<NS> & {
  position: number;
  onAdd: () => void;
  onRemove: () => void;
  removeEnabled: boolean;
};

export default function CustomsLineItemSubform<NS extends string>({
  namespace,
  position,
  onAdd,
  onRemove,
  removeEnabled,
}: CustomsLineItemSubformProps<NS>) {
  const { loading: countriesLoading, data: countriesData } = useCountriesQuery();
  const { errors, touched } = useFormikContext<{
    customsForm: CustomsFormSubformValues;
  }>();
  const packageErrors = getIn(errors, namespace) satisfies CustomsLineItemSubformValues;
  const isTouched = getIn(touched, namespace) satisfies CustomsLineItemSubformValues;

  // show weight error, if both weight fields have been touched
  const showCombinedWeightError =
    packageErrors?.combinedWeight && isTouched?.weightOunces && isTouched?.weightPounds;

  return (
    <Row>
      <Col md={12}>
        <Label secondary={<LinkButton onClick={onAdd}>Add Line Item</LinkButton>}>
          Customs Line Item #{position}
        </Label>
      </Col>
      <Col xs={12} sm={11}>
        <Row>
          <Col md={12} spaceBelow>
            <FormControl
              name={`${namespace}.title`}
              as={TextField}
              label="Describe what you're shipping"
            />
          </Col>
          <Col xs={12} sm={6} spaceBelow>
            <FormControl name={`${namespace}.quantity`} as={TextField} label="Quantity" center />
          </Col>
          <Col xs={12} sm={6} spaceBelow>
            <FormControl
              name={`${namespace}.itemValue`}
              as={TextField}
              label="Item(s) Total Value in USD $"
              center
            />
          </Col>
          <Col xs={12} sm={5.5} spaceBelow>
            <FormControl
              name={`${namespace}.weightPounds`}
              as={TextField}
              label="Item(s) Total Weight Pounds"
              center
              // We override error here to incorporate the form-level error
              error={
                (packageErrors?.weightPounds !== undefined && isTouched?.weightPounds) ||
                showCombinedWeightError
              }
            />
          </Col>
          <Hidden xs>
            <Col sm={1} spaceBelow>
              <Styled.Operator>+</Styled.Operator>
            </Col>
          </Hidden>
          <Col xs={12} sm={5.5} spaceBelow>
            <FormControl
              name={`${namespace}.weightOunces`}
              as={TextField}
              label="Item(s) Total Weight Ounces"
              center
              // We override error here to incorporate the form-level error
              error={
                (packageErrors?.weightOunces !== undefined && isTouched?.weightOunces) ||
                showCombinedWeightError
              }
            />
          </Col>
          {showCombinedWeightError && (
            <Col xs={12}>
              <MultiFieldErrorMessage className="combinedWeight">
                {packageErrors.combinedWeight}
              </MultiFieldErrorMessage>
            </Col>
          )}
          <Col sm={12} md={6} spaceBelow>
            <InputGroup
              suffixText="Search #'s"
              affixVariant="primary"
              onSuffixClick={() => window.open('https://uscensus.prod.3ceonline.com/', '_blank')}
            >
              <FormControl
                name={`${namespace}.hsTariffNumber`}
                as={TextField}
                label="Harmonization #"
                center
              />
            </InputGroup>
            <InputSubText>
              Required for Certain Countries –{' '}
              <IntercomArticleLink href="https://support.pirateship.com/en/articles/5255164-what-is-a-harmonization-number">
                Learn More
              </IntercomArticleLink>
            </InputSubText>
          </Col>
          <Col sm={12} md={6} spaceBelow>
            <FormControl
              name={`${namespace}.countryCodeOfOrigin`}
              as={CountrySelect}
              options={countriesData?.countries}
              loading={countriesLoading}
            />
            <InputSubText>Item(s) Origin</InputSubText>
          </Col>
        </Row>
      </Col>
      <Col xs={12} sm={1}>
        <Styled.IconButton onClick={onRemove} disabled={!removeEnabled}>
          <Icon icon="trash-alt" size="lg" />
        </Styled.IconButton>
      </Col>
    </Row>
  );
}
