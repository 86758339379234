import { useQuery } from '@apollo/client';
import { gql } from '../../gql';

export const warehousesQuery = gql(/* GraphQL */ `
  query WarehousesQuery {
    company {
      id
      settings {
        defaultWarehouseId
      }
      warehouses {
        ...WarehouseFragment
      }
    }
  }
`);

export const useWarehousesQuery = () => useQuery(warehousesQuery);
